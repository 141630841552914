import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'

const doctorsSlice = createSlice({
	name: 'categories',
	initialState: {
		hospitalList: [],
		recentlyVisited: [],
		doctorsList: [],
		selectedHospital: null,
	},
	reducers: {
		hospitalList: (state, action) => ({
			...state,
			hospitalList: action.payload,
		}),
		selectedHospital: (state, action) => ({
			...state,
			selectedHospital: action.payload,
		}),
		recentlyVisited: (state, action) => ({
			...state,
			recentlyVisited: action.payload,
		}),
		doctorsList: (state, action) => ({
			...state,
			doctorsList: action.payload,
			departments: uniqBy(action.payload?.list?.map(s => s?.specialities).flat(), 'id'),
		}),
	},
})

export const { recentlyVisited, doctorsList, hospitalList, selectedHospital } =
	doctorsSlice.actions

export default doctorsSlice.reducer
