import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import paymentGateway from '../../../assets/payments/payment-gateway.png'
import razorPay from '../../../assets/payments/razorpay.png'
import cod from '../../../assets/payments/cod.png'
import logoForPayment from '../../../assets/logoForPayment.png'
import { enableToast } from 'store/header'
import { Summary } from 'components'
import { createAppointment, paymentConfirmation,getCost, getPayments } from 'api/appointmentService'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import './Checkout.scss'
import { isNull } from 'lodash'
import { Helmet } from 'react-helmet'
import { enableLocation } from 'store/header'
import { Calendar } from 'primereact/calendar'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { classNames } from 'primereact/utils'
import 'yup-phone'
import { InputText } from 'primereact/inputtext'
import { selectedHospital as setSelectedHospital } from 'store/doctors'

const schema = yup.object({
	patient_name: yup.string().required('Enter Your Name'),
	patient_age: yup.string().required("Enter Your Age"),
	patient_gender : yup.string().required("Select Your Gender"),
	patient_contact	:  yup.string().optional(),
})

function Checkout() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const location = useLocation()
	
	const razor_key = process.env.RAZOR_KEY

	const [activeMethod, setActiveMethod] = useState(1)
	const [activeOption, setActiveOption] = useState(2)
	const { isRegisteredUser, userProfile, recentlyBooked,enableLocation:locationState } = useSelector(
		state => state?.header
	)

	
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)

	const progress = () => {
		confirmDialog({
			message: 'Please wait while we process your request',
			header: 'Confirmation',
			icon: 'pi pi-spin pi-spinner',
			className: 'checkout-progress',
		})
	}

	const banks = [{ option: '', image: razorPay }]
	const methods = [{ option: 'Online Payment', image: paymentGateway }]
	const [payments,setPayments] = useState([]);
	const [bookOthers,setBookOthers] = useState(false)

	const getPaymentList = async () => {
		const value = await dispatch(getPayments({hospital_id : selectedHospital,visitor_type_id:location?.state?.visitor_type_id,}))
		setPayments(value)
		setActiveMethod(value?.[0]?.id)
		if(location?.state?.package_id){
			setDoctorFee(location?.state?.doctor_fees)
		} else {
			getCosts(value) 
		}

	}

	const [doctorFee,setDoctorFee] = useState(0)

	const getCosts = async (payment) => {
		const value = await dispatch(getCost({appointment_date:location?.state?.appointment_from_time,speciality_id:location?.state?.speciality_id,hospital_id:selectedHospital,doctor_id: location?.state?.doctor_id,}))
		setDoctorFee(value.appointment_charges)
		// Doctor = 
		if(value.appointment_charges == '0'){
			let d = payment.find((v) => v.code != 'razorpay' )
			setActiveMethod(d?.id)
			setPayments([d])
			// setPayments((state) => state.filter(v =>v.code != 'razorpay'))
		} else {
			
		}
	}

	useEffect(() => {
		dispatch(enableLocation({status:false}))
		return () => {
			dispatch(enableLocation({status:true}))
		}
	},[locationState])

	useEffect(() => {
		
		if(selectedHospital){
			getPaymentList();
		}
		// getCost	//
	},[location,selectedHospital])


	
	useEffect(() => {		
		const script = document.createElement('script')
		script.src = 'https://checkout.razorpay.com/v1/checkout.js'
		script.async = true
		document.body.appendChild(script)
		
	}, [])

	useEffect(() => {
		// console.log(location?.state)
		dispatch(setSelectedHospital(location?.state?.hospital_id))
	},[selectedHospital,location?.state?.hospital_id])



	const paymentModal = async () => {
		if(!terms){
			return dispatch(enableToast({
				http_code: 400,
				message: 'Accept Terms & conditions',
				detail:'By click the checkbox to accept terms'
			}))
		}	
		let value = {}
		payments.map(v => {
			if(v.id == activeMethod){
				value = v
			}
		})
		value.code == "payonsite" && setSpinner(true);
		
		let d = {
			hospital_id: location?.state?.hospital_id,
			payment_mode: activeMethod,
			language_id: 1,
			doctor_id: location?.state?.doctor_id,
			speciality_id: location?.state?.speciality_id,
			visitor_type_id: location?.state?.visitor_type_id,
			authentication_device_id: location?.state?.authentication_device_id,
			mobile_number: userProfile?.phone,
			appointment_from_time: location?.state?.appointment_from_time,
			package_date : location?.state?.package_date,
			package_id : location?.state?.package_id,
			covid_info :location?.state?.covid_info,
			appointment_charges : doctorFee,
			bookforothers : bookOthers,
			...getValues(),
			patient_gender: getValues('patient_gender') && (getValues('patient_gender')).toLowerCase()

		}

		// console.log(d)
		await dispatch(
			createAppointment(d)
		).then(response => {
			if (response?.http_code == 200) {
				// let v = payments.find(v => v.id === activeMethod)
				if(value.code == "razorpay"){
					pay(response);
				} else {
					dispatch(enableToast(response))
					navigate('/Bookingstatus', {
						state: {
							response: response?.data,
							speciality_name: location?.state?.speciality_name,
							package_name : location?.state?.packageInfo?.name_en,
							paymentStatus: value.name_en,
						},
						replace: true,
					})
				}
			} else {
				setSpinner(false)
				dispatch(
					enableToast({
						http_code: 400,
						message: response?.message,
					})
				)
			}
		})
	}

	const pay = async (response) => {
		const options = {
			key: razor_key,
			amount: response?.data?.amount,
			name: 'BMJH',
			currency: 'INR',
			description: 'Bhagwan Mahaveer Jain Hospital',
			image: logoForPayment,
			order_id: response?.data?.payment_order_id,
			handler: async paymentResponse => {
				if (isRegisteredUser) {
					// progress()
					setSpinner(true)
					dispatch(
						paymentConfirmation({
							razorpay_order_id: paymentResponse?.razorpay_order_id,
							razorpay_payment_id: paymentResponse?.razorpay_payment_id,
							razorpay_signature: paymentResponse.razorpay_signature,
						})
					).then(() =>
						navigate('/BookingStatus', {
							state: {
								response: response?.data,
								speciality_name: location?.state?.speciality_name,
								package_name : location?.state?.packageInfo?.name_en,
								paymentStatus: "RazorPay Payment Successful",
							},
							replace: true,
						})
					)
				}
			},
			retry: false,
			prefill: {
				name: userProfile?.firstname + ' ' + userProfile?.lastname,
				contact: userProfile?.phone,
				email: userProfile?.email,
			},
			notes: {
				address: userProfile?.address,
			},
			theme: {
				color: '#e73728',
				hide_topbar: false,
			},
		}

		let rzp1 = new window.Razorpay(options)
		rzp1.open();
		rzp1.on('payment.failed', async function (v) {
			dispatch(
				enableToast({
					http_code: 400,
					message: 'Payment Failed',
				})
			)
			// progress()
			setSpinner(true)
			navigate('/BookingStatus', {
				state: {
					response: response?.data,
					speciality_name: location?.state?.speciality_name,
					paymentStatus: "RazorPay Payment Failed",
					package_name : location?.state?.packageInfo?.name_en,
					paymentFailed : true
				},
				replace: true,
			})
		})
	}

	const genderList = [
		{ name: 'Male', code: 'male',id:1 },
		{ name: 'Female', code: 'female',id:2 },
		{ name: 'Transgender', code: 'transgender',id:3 },
	]
	
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		getValues,
		setValue,
		watch,
		clearErrors,
		setError
	} = useForm({
		// mode: 'onBlur',
		// reValidateMode : 'onChange',
		resolver: yupResolver(schema),
	})

	const getFormErrorMessage = name => {
		return (
			errors?.[name] && (
				<small className='p-error'>{errors[name].message}</small>
			)
		)
	}
 
	const [terms,setTerms] = useState(false)
	const [spinner,setSpinner] = useState(false)

	function onSubmit(data) {
		if(!terms){
			return dispatch(enableToast({
				http_code: 400,
				message: 'Accept Terms & conditions',
				detail:'By click the checkbox to accept terms'
			}))
		}	

		paymentModal()

		// if(!bookOthers){
			
		// } else {

		// }
		// console.log(data)
    }

	// const order = () => {
	// 	if(!terms){
			
	// 	} else {
	// 		paymentModal()
	// 	}
	// }
	
	// console.log(errors)
	return (
		<form className='container checkout-ctr'  onSubmit={handleSubmit(onSubmit)}>
			<Helmet>
				<title>BMJH | Checkout</title>
			</Helmet>

			<ConfirmDialog visible={spinner} header='Confirmation' className='checkout-progress' icon='pi pi-spin pi-spinner' message='Please wait while we process your request' />

			<div className='patient-infos'>
				<div className='patient-header'>
					<h4>Patient Details</h4>
					<div className='book-for-other-buttons'>
						<div onClick={() => {setBookOthers(false),clearErrors()}} className={!bookOthers?'active-button':''}>Book For Me</div>
						<div onClick={() => {setBookOthers(true),setValue('patient_name',''),setValue('patient_age',null),setValue('patient_contact',''),setValue('patient_gender',''),setValue('patient_age','')}} className={bookOthers?'active-button':''}>Book For Others</div>
					</div>
				</div>
				{
					!bookOthers?
					<div className='d__flex gap-15'>
						<div className='input-item'>
							<div className='input-label'>Full Name</div>
							<input className='input-box' placeholder='Full Name' type='text' name='first-name' value={userProfile?.firstname + ' ' + userProfile?.lastname} disabled/>
						</div>

						<div className='input-item'>
							<div className='input-label'>Mobile No</div>
							<input className='input-box' placeholder='Mobile No' type='text' name='phone-number' value={userProfile?.phone} disabled />
						</div>

						<div className='input-item'>
							<div className='input-label'>Email Address</div>
							<input className='input-box' placeholder='Email' type='text' name='email' value={userProfile?.email} disabled/>
						</div>

						<div className='input-item'>
							<div className='input-label'>Date of Birth</div>
							<input className='input-box' placeholder='Date of Birth' type='text' name='dob' value={userProfile?.dob} disabled/>
						</div>
					</div>
					:
					<div className='d__flex gap-15' >
						<div className='input-item'>
							<div className='input-label'>Patient Name</div>
							<input className='input-box' placeholder='Patient Name' type='text' name='patient_name' {...register('patient_name')}/>
							{getFormErrorMessage('patient_name')}
						</div>

						<div className='input-item'>
							<div className='input-label'>Patient Age</div>
							<Calendar
								id='patient_age'
								dateFormat='yy-mm-dd'
								maxDate={new Date()}
								placeholder='Patient Age'
								mask='9999-99-99'
								value={getValues('patient_age')?new Date(getValues('patient_age')):new Date()}
								className={classNames({ 'p-invalid': errors['patient_age'] })}
								{...register('patient_age', {
									required: true,
									onChange: e =>
										{setValue(/*  */
											'patient_age',
											new Date(e.value)?.toISOString()?.substring(0, 10)
										)
										clearErrors('patient_age')
									}
								})}
							/>
							<div>{getFormErrorMessage('patient_age')}</div>
						</div>

						<div className='input-item'>
							<div className='input-label'>Patient Contact</div>
							{/* <input className='input-box' pattern='\d*' placeholder='Patient Contact' type='text'  name='patient_contact'  {...register('patient_contact')}/> */}
							<InputText
								id='patient_contact'
								type='number'
								min={0}
								// min={'10'}
								// max={'12'}
								inputMode='numeric'
								placeholder='Patient Contact'
								pattern='\d*'
								className={classNames({ 'p-invalid': errors['patient_contact'] })}
								{...register('patient_contact', {
									required: false,
									minLength: 10,
									maxLength: 12,
									value: '',
								})}
							/>
							{getFormErrorMessage('patient_contact')}
						</div>

						<div className='input-item'>
							<div className='input-label'>Patient Gender</div>
							<select className='input-box' placeholder='Gender'  {...register('patient_gender')}>
								{/* <option value={''}>Genders</option> */}
								{
									genderList.map((v,i) => 
										<option key={i+'GL'} value={v.name}>{v.name}</option>	
									)
								}
							</select>
							{getFormErrorMessage('patient_gender')}
						</div>
					</div>
				}
				{/* <div className='d__flex gap-5'>
					<input onChange={() => {setBookOthers(!bookOthers)}} type='checkbox' name='bookforothers' />
					<div className='input-label m-0 mb-0'>Book For Others</div>
				</div> */}
			</div>


			<div className='payment-infos d__flex align-start'>
				<div className='payment-methods'>
					<h3>Choose Payment Method</h3>
					<div className='d__flex align-start payments'>
						<div className='payment-types'>
							{payments?.map((res, index) => (
								<div
									className={`type ${activeMethod == res?.id ? 'active' : null}`}
									key={index + 'pay'}
									onClick={() => setActiveMethod(res?.id)}>
									<div className='payment-image'>
										<img src={res?.image} alt='' />
									</div>
									{res?.name_en}
								</div>
							))}
						</div>
						<div className='payment-options'>
							<div>
								<h3>Payment Methods</h3>
								<div>
									{banks?.map((res, index) => (
										<div
											key={index + 'net'}
											className={`d__flex gap-15  m-d-flex bank ${
												activeOption == index  || true ? 'active' : null
											}`}
											onClick={() => setActiveOption(index)}>
											<span></span>
											<div className='bank-logo'>
												{payments.length ? <img className='w-100' src={activeMethod == 1 ? razorPay : cod} /> : <></>}
											</div>
											<div>{res.option}</div>
										</div>
									))}
								</div>
								<div className='other-options'>
									<div className=''>
										<input type='checkbox' name='' onChange={() => {setTerms(!terms)}} />
										By Clicking this Checkbox, you agree to BMJH{' '}
										<Link to={'/terms-conditions'}><span>Terms and Conditions.</span></Link>
									</div>
									<div>
										{/* <Link to={'/confirm'}>
										</Link> */}
										{
											bookOthers? 
												<button type='submit' >
													{doctorFee == '0' ? 'FREE' : 'Pay ₹'+doctorFee }
												</button>
											:
												<button type='button' onClick={() => paymentModal()} >
													{doctorFee == '0' ? 'FREE' : 'Pay ₹'+doctorFee }
												</button>
										}
									</div>
									<div>
										<p>
											1.Free cancellation and rescheduling are available till 2
											hours before the appointment time.
										</p>
										<p>
											2.After the stipulated time, rescheduling will not be
											available and a fee of Rs 50 will be applicable for
											cancellation or no-show.
										</p>
									</div>
									<div>
										Have more questions? Read our <Link to={'/faq/payment-faq'}><span>Disclaimer</span></Link>.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='appointment-infos'>
					<Summary info={location?.state} fees={doctorFee} />
				</div>
				{/* <ConfirmDialog /> */}
			</div>
		</form>
	)
}

export default Checkout
