import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { enableAppointment } from 'store/header'
import Icons from '../Icons'
import './DoctorBox'
import './DoctorBox.scss'

const DoctorBox = ({ doctor }) => {
	const dispatch = useDispatch()

	return (
		<div className='doctor-box'>
			<div className='image-ctr position-relative'>
				<Link to={`/doctors/${doctor?.doctor_id}`}>
					<img src={doctor?.profile_image ?? require('../../../assets/default/home-doctor.png') } alt='' onError={((e) => {(e.target.src = require('../../../assets/default/home-doctor.png') )})} />
				</Link>
				{doctor?.book_appointment == 1 &&
					<Link to={`/doctors/${doctor?.doctor_id}`}>
					{/* onClick={() => dispatch(enableAppointment(doctor))} */}
					 <button >
						Book Appointment
					</button>
					</Link>
				}
			</div>
			<div className='doctor-info'>
				<div className='min-100'>
				<h5 className='mb-0'>{doctor?.firstname + ' ' + doctor?.lastname}</h5>
				<div className='fnt-14 m-0 short-info speciality-names'>{doctor?.title}</div>
				<div className='fnt-14 m-0 short-info speciality-names'>{doctor?.sub_title}</div>
				<p className='fnt-14 m-0 short-info speciality-names' title={doctor?.specialities_names}>{doctor?.specialities_names}</p>
				</div>
				{/* <p className='fnt-14 m-0 short-info'>  {doctor?.experience?doctor?.experience:0} Years Experience</p> */}
				<div className='d__flex d-infos gap-15 m-d-flex'>
					{/* <div className='d__flex gap-10 m-d-flex'>
						<div>
							<Icons
								name='doctor'
								iconType='star'
								strokeWidth='0'
								fillColor='#F89D3D'
								viewBox='0 0 11 11'
							/>
						</div>
						{doctor?.avg_rating}
					</div> */}
					{/* <div className='d__flex gap-10 m-d-flex'>
						<div>
							<Icons
								name='doctor'
								iconType='clock'
								strokeWidth='0'
								fillColor='#30D058'
								viewBox='0 0 11 11'
							/>
						</div>
						Available Now
					</div> */}
					<div className='goto flex-1'>
						<Link to={`/doctors/${doctor?.doctor_id}`}>
							<button>
								<Icons
									name='common'
									iconType='arrow-next-white'
									strokeWidth='0'
									strokeColor='#ffff'
									viewBox='0 0 30 29'
								/>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DoctorBox
export { DoctorBox }
