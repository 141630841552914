import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { getCmsDetails } from '../../../api/cmsService';
import { CustomizeHeading } from '../../components';

function Academics() {

  const dispatch = useDispatch();
  const [info,setInfo] =  useState({});

  const getDetailData = async () => {
  const response = await dispatch(getCmsDetails({url:'academics'}))
      setInfo(response)
  } 

  useEffect(() => {  
   getDetailData();
    return () => {}
  }, [])

return (
  <div className='mar-b-135 container'>
      	<Helmet>
          <title>BMJH | {`${ info?.meta_title }`}</title>
          <meta property="og:url" content="http://bmjh.og/" />
          <meta property='og:keywords' content={`${ info.meta_keywords }`} />
          <meta property="og:title" content={`${ info?.meta_title }`} />
          <meta property="og:description" content={`${ info.meta_description }`}/>

          <meta name="twitter:title" content={`${ info?.meta_title }`} />
          <meta name="twitter:description" content={`${ info.meta_description }`}/>
        </Helmet>
        <div className='pharmacy-information'>
         <CustomizeHeading title={info?.title }/>
          {info && <div dangerouslySetInnerHTML={{__html: info?.content}} />}
        </div>
  </div>
)
}

export default Academics