import React from 'react'

import { CommonIcons } from './CommonIcons'
import { DoctorIcons } from './DoctorIcons'
import FooterICons from './FooterIcons'
import { HeaderIcons } from './HeaderIcons'
import HomeIcons from './HomeIcons'
import { MyAccountIcons } from './MyAccountIcons'
import { SocialIcons } from './SocialIcons'
import { SpecialtyIcons } from './SpecialtyIcons'

function Icons(props) {
	const getIconComponent = iconFamily => {
		switch (iconFamily) {
			case 'header':
				return HeaderIcons
			case 'CRUD':
				return CRUDIcons
			case 'common':
				return CommonIcons
			case 'doctor':
				return DoctorIcons
			case 'specialty':
				return SpecialtyIcons
			case 'my-account':
				return MyAccountIcons
			case 'social':
				return SocialIcons
			case 'home':
				return HomeIcons
			case 'footer':
				return FooterICons
			default:
				return undefined
		}
	}

	const { name, ...remainingProps } = props

	const Component = getIconComponent(name)
	return Component ? <Component {...remainingProps} /> : ''
}

export { Icons }
export default Icons
