import { createSlice } from '@reduxjs/toolkit'

const headerSlice = createSlice({
	name: 'header',
	initialState: {
		enableToast: {},
		loader: false,
		enableLoginModal: false,
		enableRecordModal: false,
		enableTimingPopup: false,
		enableLocation: true,
		enableAppointment: [],
		userProfile: {},
		isRegisteredUser: false,
		recentlyBooked: {},
		staticInfos: {},
	},
	reducers: {
		enableToast: (state, action) => ({
			...state,
			enableToast: action?.payload,
		}),
		userProfile: (state, action) => ({
			...state,
			userProfile: action?.payload,
		}),
		isRegisteredUser: (state, action) => ({
			...state,
			isRegisteredUser: action?.payload,
		}),
		enableLoginModal: state => ({
			...state,
			enableLoginModal: !state.enableLoginModal,
		}),
		enableRecordModal: state => ({
			...state,
			enableRecordModal: !state.enableRecordModal,
		}),
		enableTimingPopup: (state, action) => ({
			...state,
			enableTimingPopup: action?.payload,
		}),
		enableAppointment: (state, action) => ({
			...state,
			enableAppointment: action?.payload,
		}),
		recentlyBooked: (state, action) => ({
			...state,
			recentlyBooked: action?.payload,
		}),
		staticInfos: (state, action) => ({
			...state,
			staticInfos: action?.payload,
		}),
		enableLocation: (state, action) => ({
			...state,
			enableLocation: action?.payload?.status ?? !state.enableLocation,
		}),
		enableLoader: (state, action) => ({
			...state,
			loader: action?.payload ?? !state.enableLocation,
		}),
		enableModalVideo: (state, action) => ({
			...state,
			enableModalVideo: action?.payload,
		}),
	},
})

export const {
	enableToast,
	disableToast,
	userProfile,
	enableLoginModal,
	enableTimingPopup,
	enableAppointment,
	isRegisteredUser,
	recentlyBooked,
	enableRecordModal,
	staticInfos,
	enableLocation,
	enableModalVideo,
	enableLoader
} = headerSlice.actions

export default headerSlice.reducer
