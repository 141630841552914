import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAppointmentList } from 'api/appointmentService'
import './AllAppointments.scss'
import { Paginator } from 'primereact/paginator';



function AllAppointments() {
	let order_filters = [
		{ id: 0, name: 'All Appointments' },
		{ id: 1, name: 'Requested' },
		{ id: 2, name: 'Confirmed' },
		{ id: 3, name: 'Completed' },
		{ id: 4, name: 'Customer Cancelled' },
		{ id: 5, name: 'Doctor Cancelled' },
		{ id: 6, name: 'Payment Failure' },
		// { id: 7, name: 'Refunds' },
// 		1		Request
// 2		Confirmed
// 3		Completed
// 4		Customer Canceled
// 5		Doctor Canceled
// 6		Transaction Failed
// 7		Refunds
	]

	const dispatch = useDispatch()
	const [appointmentStatus,setAppointmentStatus] = useState(0);
	const [basicFirst, setBasicFirst] = useState(0);
	const [basicRows, setBasicRows] = useState(10);

	const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setBasicRows(event.rows);
		getAppointments(event.first == 0 ? 1 : event.first / 10 + 1)
		window.scrollTo(0,0);
    }

	const [appointmentList, setAppointmentList] = useState({})
	const [activeTab, setActiveTab] = useState(0)
	const [filteredList, setFilteredList] = useState([])
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
	//const { isRegisteredUser } = useSelector(state => state?.header)

	const getAppointments = async (page_no) => {
		// if (isEmpty(appointmentList)) {
			let response = await dispatch(
				getAppointmentList({ hospital_id: selectedHospital, page: page_no,status: activeTab })
			)
			setAppointmentList(response)
			setFilteredList(response?.appoinment_list)
			
		// }
	}

	useEffect(() => {
		selectedHospital && activeTab == 0 && getAppointments(1)
	}, [selectedHospital,activeTab])
	// 

	useEffect(() => {
		activeTab != 0 && getAppointments(1)
	},[activeTab])


	// useEffect(() => {
	// 	filtered()
	// },[appointmentList,activeTab])

	const filtered = () => {
		let getFilteredData = appointmentList?.appoinment_list?.filter(f =>
			activeTab == 0
				? true
				: activeTab == 4
				? f?.status_id == 4 || f?.status_id == 5
				: f?.status_id == activeTab
		)
		setFilteredList(getFilteredData)
	}

	return (
		<div className='appointment-info-ctr'>
			{/* { */}
				{/* //  appointmentList?.appoinment_list && appointmentList?.appoinment_list?.length != 0 && */}
				<div className='appointment-filters d__flex gap-40'>
				{order_filters?.map(res => (
					<div
						key={res?.id + 'w1'}
						onClick={() => setActiveTab(res?.id)}
						className={`filter ${activeTab == res?.id ? 'active' : null}`}>
						{res?.name}
					</div>
				))}
				</div>
			{/* } */}
			{ appointmentList?.appoinment_list && filteredList?.length != 0 &&  
				<>

			<div className='booked-appointments-list'>
				{filteredList?.map((res, index) => (
					<div
						className='booked-appointment d__flex gap-20'
						key={'APT' + index}>
						<div className='date-box'>
							<span>{new Date(res?.appointment_date).getDate()}</span>
							<span>
								{new Date(res?.appointment_date).toLocaleString('default', {
									month: 'long',
								})}
							</span>
						</div>
						<div className='appointment-doctor-info flex-1'>
							<p className='mb-5'><b>Appointment ID :</b> {res?.formattedOrderId} &ensp;&ensp; <b>Hospital : </b>  {res?.hospital_name}</p>
							{res?.appoinment_type != 'package' && <h4>{res?.doctor_name}</h4>}
							<p className='mb-5'>{ res?.appoinment_type != 'package' ? res?.speciality_name : res?.package_name}</p>
							<p className='mb-5'>
								{res?.visitor_type_name}, {res?.vendor_address}
							</p>
							<div className='appointment-status d__flex gap-20'>
								{/* <p>Wed, 13th April, 10.30 am</p> */}
								<p>
									{res?.appointment_date} {res?.appoinment_type != 'package' ? res?.appointment_time : ''}
								</p>
								<span className={`fnt-12 ${'status-'+res?.status_id}`}>{res?.status}</span>
							</div>
						</div>
						<div className='appointment-buttons d__flex m-d-flex gap-20 p-a-1 flex-col'>
							{/* <button>Book Again</button> */}
							<Link
								to={'/profile/my-appointments/' + res?.orderId}
								className='view-details'>
								{' '}
								View Detail{' '}
							</Link>
						</div>
					</div>
				))}
			</div>			
			</>
			}

			{appointmentList && appointmentList?.appoinment_list && appointmentList?.appoinment_list?.length != 0 && filteredList?.length != 0 && <div>
				<Paginator first={basicFirst} rows={basicRows} totalRecords={appointmentList?.appoinment_total_count } rowsPerPageOptions={[10, 20, 30]} onPageChange={onBasicPageChange}></Paginator>
			</div>}

			{
				appointmentList?.appoinment_list && filteredList?.length == 0 &&
				<div className='go-center'>
					<img src={require(`../../../assets/empty_item.svg`)}  height={'200px'} alt='' />
					<h2>No Appointments Found !!</h2>
				</div>
			}				
		

		</div>
	)
}

export default AllAppointments
