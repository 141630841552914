import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SearchInput } from 'components'
import { getFacilitiesList } from 'api/catalogService'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import './Facilities.scss'
import { Helmet } from 'react-helmet'
import { CustomizeHeading } from '../../components'

function Facilities() {
	const dispatch = useDispatch()
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
	const [facilityData, setFacilityData] = useState([])

	const { list } = useSelector(state => state?.categories?.facilityList)

	useEffect(() => {
		// if (isEmpty(list)) {
		selectedHospital &&	getFacilities(),setFacilityData(list)
		// }
	}, [selectedHospital])


	const getFacilities = async () => {
		let response = await dispatch(getFacilitiesList({ hospital_id: selectedHospital ,return_type:true}))
		setFacilityData(response?.list)
	}

	const getSearchTerm = async searchTerm => {
		if (searchTerm == '') {
			setFacilityData(list)
		} else {
			let v = await dispatch(getFacilitiesList({ hospital_id: selectedHospital,return_type:true,q : searchTerm}))	
			// let getFilterData = list?.filter(f =>	f?.name?.toLowerCase()?.includes(searchTerm))
			setFacilityData(v?.list)
		}
	}

	return (
		<div className='facilities main-width mt-50 container'>
			<Helmet>
				<title>BMJH | Facilities</title>
			</Helmet>

			<CustomizeHeading title='Our Facilities'/>

			<SearchInput onClick={getSearchTerm} label='Facilities' />

			<div className='mt-15 d__flex flex-w gap-15'>
				{!isEmpty(facilityData)
					? facilityData?.map((res, key) => (
							<Link
								key={key}
								className='facility-card'
								to={`/facilities/${res?.id}`}>
								<div>
									<img src={res?.image1} alt={res?.name} onError={((e) => { (e.target.src = require('../../../assets/default/Specialties-list.png') )})}/>
									<h4>{res?.name}</h4>
								</div>
							</Link>
					  ))
					: 'No Results found' }
			</div>
		</div>
	)
}

export default Facilities

