import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MedicalPackage, SearchInput, Testimonials } from 'components'
import { isEmpty, isNil } from 'lodash'
import { getMedicalPackageList } from 'api/doctorService'
import './MedicalPackages.scss'
import { Helmet } from 'react-helmet'

function MedicalPackages() {
	const dispatch = useDispatch()
	const [packageList, setPackageList] = useState([])
	const [getPackageList, setPackageListResponse] = useState([])

	const { selectedHospital } = useSelector(state => state?.doctorsInfo)

	const getMedicalPackage = async () => {
		let response = await dispatch(
			getMedicalPackageList({ hospital_id: selectedHospital })
		)
		setPackageListResponse(response)
		setPackageList(response)
	}

	useEffect(() => {
		// if (isEmpty(packageList) && !isNil(selectedHospital)) {
			selectedHospital && getMedicalPackage()
		// }
	}, [selectedHospital])

	const getSearchTerm = async searchTerm => {
		if (searchTerm == '') {
			getMedicalPackage()
			// setPackageList(getPackageList)
		} else {
			let response = await dispatch(
				getMedicalPackageList({ hospital_id: selectedHospital,search_package : searchTerm  })
			)
			setPackageList(response)
			

			// let getFilterData = getPackageList?.filter(f =>
			// 	f?.name_en?.toLowerCase()?.includes(searchTerm)
			// )
			// setPackageList(getFilterData)
		}
	}

	return (
		<div className='medical-packages main-width container mar-b-135'>
			<Helmet>
				<title>BMJH | Home</title>
			</Helmet>
			<div className='padd-0-6'>
				<h1 className='txt-center custom-heading'>
				Health check <span>packages</span>
				</h1>
				<SearchInput onClick={getSearchTerm} label='Packages' />

				<div className='d__flex flex-w medical-packages-list'>
					{packageList &&
						packageList.map((res, index) => (
							<div className='package-item' key={index + 'PACK'}>
								<MedicalPackage packageInfo={res} />
							</div>
						))}
				</div>

				{/* <Testimonials title={'Patient Testimonials'} /> */}
			</div>
		</div>
	)
}

export default MedicalPackages
