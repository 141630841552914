import React from 'react'
import { BlogArticles, BlogBox, TitleCard } from 'components'
import { isEmpty } from 'lodash'
import './Blog.scss'
import Carousel from '../../Carousel/Carousel'
import { SwiperSlide } from 'swiper/react'

function Blog(props) {
	const getBlogList = !isEmpty(props?.data?.placeholders)
		? props?.data?.placeholders[0]?.blogs_vlogs
		: []

		let blockBoxData = []
		let blockBoxDataVertical = []

		getBlogList.map(res => {
			 (res.video_link && res.video_link != '') ? blockBoxDataVertical.push(res) : blockBoxData.push(res)
		})

	const breakpoints = {
		"1720": {
		  slidesPerView: 2,
		},
		"1366": {
		  slidesPerView: 2,
		},
		"1025": {
		  slidesPerView: 2,
		},
		"768": {
		  slidesPerView: 2,
		},
		"0": {
		  slidesPerView: 1,
		},
	  };

	return (
		<div className='blogs container'>
			<div className='wid-92 mar-auto'>
				<TitleCard title={props?.data?.title} description={props?.data?.description} />
				<div className='d__flex  align-start mar-b-40'>
					<div className='blog-cards'>
						<Carousel loop={true} breakpoints={breakpoints} autoplay={{delay: 5000,}}>
							{blockBoxData?.map((res, index) => (
								<SwiperSlide key={index + 'blog'}>
									<BlogBox  blog={res} />
								</SwiperSlide>
							))}
						</Carousel>
					</div>
					<div className='blog-list'>
						<BlogArticles blog={blockBoxDataVertical} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Blog
export { Blog }
