import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import 'yup-phone'
import { classNames } from 'primereact/utils'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { updateProfile, updateProfileImage } from 'api/authService'
import { isEmpty } from 'lodash'
import moment from 'moment'
import './Profile.scss'

const schema = yup.object({
	firstname: yup.string().required('Enter your first name'),
	lastname: yup.string().required('Enter your last name'),
	email: yup.string().email().required('Enter your email address'),
	//phone: yup.string().phone('IN', true, 'Invalid mobile number'),
	dob: yup.string().required('Enter your DOB'),
	marital_status: yup.string().required('Select your martial status').optional(),
	gender: yup.string().optional(), //.required('Select your gender')
	blood_group: yup.string(),//.required('Select your Blood group').optional()
	// height: yup
		// .number()
		// .positive()
		// .integer()
		// .min(10, 'Enter your valid height')
		// .max(400, 'Enter your valid height')
		// .typeError('Enter your valid height')
		// .optional(),//.required('Enter your height')
	// weight: yup
		// .number()
		// .positive()
		// .integer()
		// .min(10, 'Enter your valid weight')
		// .max(400, 'Enter your valid weight')
		// .typeError('Enter your valid weight')
		// .optional(), //.required('Enter your weight')
	address: yup.string()
})

function Profile() {
	const dispatch = useDispatch()
	const { userProfile } = useSelector(state => state.header)
	const [selectedDob, setSelectedDob] = useState()

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		getValues,
		setValue,
		watch,
	} = useForm({
		mode: 'onBlur',
		defaultValues: {
			height: '',
		},
		resolver: yupResolver(schema),
	})

	const watchGenderFields = watch('gender', getValues('gender'))
	const watchMartialFields = watch(
		'marital_status',
		getValues('marital_status')
	)
	const watchBloodGroupFields = watch('blood_group', getValues('blood_group'))

	useEffect(() => {
		if (userProfile) {
			setValue('profile_image', userProfile?.profile_image || '')
			setValue('firstname', userProfile?.firstname || '')
			setValue('lastname', userProfile?.lastname || '')
			setValue('email', userProfile?.email || '')
			setValue('phone', userProfile?.phone || '')
			setValue('dob', userProfile?.dob || '')
			setValue('marital_status', userProfile?.marital_status || '')
			setValue('gender', userProfile?.gender || '')
			setValue('blood_group', userProfile?.blood_group || '')
			setValue('height', userProfile?.height || '')
			setValue('weight', userProfile?.weight || '')
			setValue('address', userProfile?.address || '')
		}
		//reset(userProfile)
		setSelectedDob(getValues('dob'))
	}, [userProfile])

	const getFormErrorMessage = name => {
		return (
			errors?.[name] && (
				<small className='p-error'>{errors[name].message}</small>
			)
		)
	}
	const onSubmit = async data => {
		data.email_id = data.email
		data.email = undefined
		dispatch(updateProfile(data))
	}

	const updateProfileImg = e => {
		dispatch(updateProfileImage({ userInfo: userProfile, profile_image: e.target?.files }))
	}

	const genderList = [
		{ name: 'male', code: 'male' },
		{ name: 'female', code: 'female' },
		// { name: 'transgender', code: 'transgender' },
	]
	const martialList = [
		{ name: 'single', code: 'single' },
		{ name: 'married', code: 'married' },
		{ name: 'divorced', code: 'divorced' },
		{ name: 'widowed', code: 'widowed' },
	]
	const bloodGroup = [
		{ name: 'A+', code: 'a+' },
		{ name: 'O+', code: 'o+' },
		{ name: 'B+', code: 'b+' },
		{ name: 'AB+', code: 'ab+' },
		{ name: 'A-', code: 'a-' },
		{ name: 'O-', code: 'o-' },
		{ name: 'B-', code: 'b-' },
		{ name: 'AB-', code: 'ab-' },
	]

	return (
		<div className='profile-wrapper'>
			<div className='txt-center profile-image-wrapper d__flex'>
				<div className='profile-image'>
					<img className='w-100' src={getValues('profile_image')} alt={userProfile?.firstname} />
				</div>
				<label htmlFor='profile-image'>
					<i className='pi pi-camera'></i>
					<input
						id='profile-image'
						className='d__none'
						type='file'
						accept='image/png, image/jpeg'
						name='profile-pic'
						onChange={updateProfileImg}
					/>
				</label>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className='p-fluid'>
				<div className='input-item'>
					<div className='input-label'>First Name*</div>
					<input
						placeholder='First name *'
						type='text'
						className={classNames({ 'p-invalid': errors['firstname'] })}
						{...register('firstname', {
							required: true,
							maxLength: 30,
						})}
					/>
					{getFormErrorMessage('firstname')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Last Name*</div>
					<input
						placeholder='Last name *'
						type='text'
						className={classNames({ 'p-invalid': errors['lastname'] })}
						{...register('lastname', {
							required: true,
							maxLength: 30,
						})}
					/>
					{getFormErrorMessage('lastname')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Email ID*</div>
					<input
						placeholder='Email *'
						type='email'
						className={classNames({ 'p-invalid': errors['email'] })}
						{...register('email', {
							required: true,
							maxLength: 30,
						})}
					/>
					{getFormErrorMessage('email')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Phone Number*</div>
					<input
						placeholder='Phone *'
						type='phone'
						disabled={true}
						value={getValues('phone')}
						className={classNames({ 'p-invalid': errors['phone'] })}
						{...register('phone', {
							required: true,
						})}
					/>
					{getFormErrorMessage('phone')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Martial Status</div>
					<Dropdown
						options={martialList}
						optionLabel='name'
						optionValue='code'
						value={watchMartialFields}
						className={classNames({ 'p-invalid': errors['marital_status'] })}
						placeholder='Select your Martial Status'
						{...register('marital_status', {
							required: false,
						})}
					/>
					{getFormErrorMessage('marital_status')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Date of birth *</div>
					<Calendar
						id='dob'
						dateFormat='yy-mm-dd'
						maxDate={new Date()}
						mask='9999-99-99'
						value={getValues('dob')?new Date(getValues('dob')):new Date()}
						className={classNames({ 'p-invalid': errors['dob'] })}
						{...register('dob', {
							required: true,
							onChange: e =>
								setValue(
									'dob',
									new Date(e.value)?.toISOString()?.substring(0, 10)
								),
						})}
					/>
					{getFormErrorMessage('dob')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Gender </div>
					<Dropdown
						options={genderList}
						optionLabel='name'
						optionValue='code'
						value={watchGenderFields}
						className={classNames({ 'p-invalid': errors['gender'] })}
						placeholder='Select your Gender'
						{...register('gender', {
							required: false,
						})}
					/>
					{getFormErrorMessage('gender')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Blood Group </div>
					<Dropdown
						options={bloodGroup}
						optionLabel='name'
						optionValue='code'
						value={watchBloodGroupFields?.toLowerCase()}
						className={classNames({ 'p-invalid': errors['blood_group'] })}
						placeholder='Select your Blood Group'
						{...register('blood_group', {
							required: false,
						})}
					/>
					{getFormErrorMessage('blood_group')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Height-cm </div>
					<input
						placeholder='Height-cm'
						type='number'
						name='height'
						min={10}
						max={300}
						className={classNames({ 'p-invalid': errors['height'] })}
						{...register('height', {
							required: false,
						})}
					/>
					{getFormErrorMessage('height')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Weight-kg </div>
					<input
						placeholder='Weight-kg'
						type='number'
						name='weight'
						min={10}
						max={500}
						className={classNames({ 'p-invalid': errors['weight'] })}
						{...register('weight', {
							required: false,
						})}
					/>
					{getFormErrorMessage('weight')}
				</div>

				<div className='input-item'>
					<div className='input-label'>GST Invoice name</div>
					<input
						placeholder='GST Invoice name'
						type='text'
						name='gst_invoice_name'
						className={classNames({ 'p-invalid': errors['gst_invoice_name'] })}
						{...register('gst_invoice_name', {
							required: false,
						})}
					/>
				</div>

				<div className='input-item'>
					<div className='input-label'>GST Invoice number</div>
					<input
						placeholder='GST Invoice number'
						type='text'
						name='gst_number'
						className={classNames({ 'p-invalid': errors['gst_number'] })}
						{...register('gst_number', {
							required: false,
							onChange: e => setValue('gst_number', parseInt(gst_number)),
						})}
					/>
				</div>

				<div className='input-item'>
					<div className='input-label'>Address </div>
					<input
						className={
							('input-box',
							classNames({ 'p-invalid': errors['gst_invoice_name'] }))
						}
						placeholder='Address 1'
						type='text'
						{...register('address', {
							required: false,
						})}
					/>
					{getFormErrorMessage('address')}
				</div>

				<div className='txt-right flex-1'>
					<button>Update</button>
				</div>
			</form>
		</div>
	)
}

export default Profile
