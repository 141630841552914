import React, { useState, useEffect } from 'react'
import { Link, Navigate, Router, useNavigate, useRoutes } from 'react-router-dom'
import { getCategoriesList } from 'api/catalogService'
import { findDoctors } from 'api/doctorService'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { SearchInput } from 'components'
import './Specialties.scss'

import specialties1 from '../../../assets/specialties/specialties1.png'
import { Helmet } from 'react-helmet'
import { CustomizeHeading } from '../../components'
import { useHash } from 'react-use'

function Specialties() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const getCatList = useSelector(state => state.categories.categoriesList)
	const [specialtiesList, setSpecialtiesList] = useState([])
	const [isSearchView, setSearchView] = useState(false)
	const [activeTab, setActiveTab] = useState()

	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
 
	const hash = useHash();


	useEffect(() => {
		if (isEmpty(getCatList)) {
			selectedHospital && dispatch(getCategoriesList())
		}
		hash[0] != '' ? setActiveTab(hash[0].slice(1)) : setActiveTab(getCatList[0]?.id)
		// setActiveTab(getCatList[0]?.id)
	}, [getCatList,selectedHospital])

	const getSearchTerm = async searchTerm => {
		setSearchView(!isEmpty(searchTerm))
		const response = await dispatch(
			findDoctors({
				hospital_id: selectedHospital,
				key: searchTerm,
			})
		)
		setSpecialtiesList(response?.specialties_list)
	}

	return (
		<div className='specialties-ctr main-width container mar-b-135'>
			<Helmet>
				<title>BMJH | Home</title>
			</Helmet>
			<div className='padd-0-6'>

				<CustomizeHeading title='Our Specialities'/>

				<SearchInput onClick={getSearchTerm} label={'Specialities'} />
				
				<div className='d__flex align-start flex-w mt-50'>
					{!isSearchView ? (
						<>
							<ul className='sidebar'>
								{getCatList?.map((res, index) => (
									<li
										key={res?.id}
										className={`${res?.id == activeTab ? 'active' : ''}`}
										onClick={() => {navigate('/specialties#'+res?.id),setActiveTab(res?.id)}}>
										{res?.name}
									</li>
								))}
							</ul>
							<div className='flex-1 d__flex flex-w specialties gap-20'>
								{getCatList?.map(
									list =>
										list?.id == activeTab &&
										list?.subcategory?.map((item, i) => (
											<div className='specialty' key={i}>
											<Link to={item?.name_slug != 'preventive-healthcare-checkup-services' && item?.name_slug !='preventive-health-checkup-services'?`/specialties/${item?.id}`:'/medical-packages'} >
												<img
													src={item?.image}
													// onError={({ currentTarget }) => {
													// 	currentTarget.onerror = null
													// 	currentTarget.src = specialties1
													// }}
													onError={((e) => { (e.target.src = require('../../../assets/default/Specialties-list.png') )})}
													alt=''
												/>
												{/* <Link to={`/specialties/${item?.id}`}> */}
													<h4 className='fnt-18'>{item?.name}</h4>
												{/* </Link> */}
											</Link>
											</div>											
										))
								)}
							</div>
						</>
					) : !isEmpty(specialtiesList) ? (
						<>
							<div className='flex-1 d__flex flex-w specialties gap-20'>
								{specialtiesList?.map((item, i) => (
									<div className='specialty' key={i}>
										<Link to={`/specialties/${item?.id}`} >
											<img
												src={item?.image}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null
													currentTarget.src = specialties1
												}}
												alt=''
											/>
											{/* <Link to={`/specialties/${item?.id}`}> */}
												<h4 className='fnt-18'>{item?.name}</h4>
											{/* </Link> */}
										</Link>
									</div>
								))}
							</div>
						</>
					) : (
						<p>No Results Found</p>
					)}
				</div>
			</div>
		</div>
	)
}

export default Specialties

