import React from 'react'
import {Swiper} from 'swiper/react';
import SwiperCore, { Zoom, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay])
function Carousel({loop,breakpoints,autoplay,children,direction='horizontal'}) {
  return (
    <Swiper direction={direction} loop={loop} breakpoints={breakpoints} autoplay={autoplay}>
        {children}
    </Swiper>
  )
}

export default Carousel