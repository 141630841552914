import React from 'react'
import { Icons } from 'components'
import './BlogArticles.scss'
import "react-modal-video/css/modal-video.css"
import { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { enableModalVideo } from '../../../store/header'

function BlogArticles({ blog }) {
	// const [videoId,setVideoId] = useState(undefined);
	const dispatch = useDispatch();
	return (
		<>
			<div className='inside-blog-list'>
				{blog && blog.map((res, index) => (
					<div key={index + 'blog-arc123'} className='d__flex m-d-flex gap-20 blog-item align-start'>
						<div className='position-relative'>
							{
							res?.video_link  ? 
								<img className='blog-image' src={'https://i.ytimg.com/vi/'+res?.video_link+'/0.jpg'} alt='' />
							:
							<Link to={ `/blog-detail/${res?.slug}`}>
								<img className='blog-image' src={res?.video_link?  `'https://i.ytimg.com/vi/'+res?.video_link+'/0.jpg'`:res?.image} alt='' />
							</Link>
							}
							{res?.video_link && <div className='play-icon cursor-pointer' onClick={() => dispatch(enableModalVideo(res?.video_link))}>
								<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
							</div>}
						</div>
						<div>
							<Link to={ `/blog-detail/${res?.slug}`}><p>{res?.title} </p></Link>
							<div className='d__flex just-s m-d-flex mb-5'>
								{/* <div className='fnt-12 department'>{blog?.type_name}</div> */}
								<p className='date-time'>{moment(res?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
							</div>
							<div className='d__flex gap-15 blog-avatar m-d-flex'>
								<img src={res?.author_image} alt='' />
								<h5>{res?.author_name}</h5>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default BlogArticles
export { BlogArticles }
