
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCmsDetails } from '../../../api/cmsService';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { CustomizeHeading } from '../../components';
import './About.scss'
import Slider from 'react-slick';
import { enableLocation } from '../../../store/header';

function About() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [info,setInfo] =  useState({});

  const getDetailData = async () => {
		const response = await dispatch(getCmsDetails({url: id == 'info' ? 'pharmacy-information' : id }))
    setInfo(response)
	} 


  useEffect(() => {
    dispatch(enableLocation())  
    id && getDetailData();
    return () => {
			dispatch(enableLocation())
		}
  }, [id])
  

  const sliderSettings =  {
		centerPadding: "20px",
		slidesToShow: 1,
		swipeToSlide : true,
		speed: 500,
		autoplay: true,
		dots :true,
		focusOnSelect: false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 1,
				infinite: false,
				dots: false,
				arrows : false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				// initialSlide: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : false
			  }
			}
		]
	};


  return (
    <div className='mar-b-135 container '>
      	<Helmet>
          <title>BMJH | {`${ info?.meta_title }`}</title>
          <meta property="og:url" content="http://bmjh.og/" />
          <meta property='og:keywords' content={`${ info.meta_keywords }`} />
          <meta property="og:title" content={`${ info?.meta_title }`} />
          <meta property="og:description" content={`${ info.meta_description }`}/>

          <meta name="twitter:title" content={`${ info.title }`} />
          <meta name="twitter:description" content={`${ info.meta_description }`}/>
        </Helmet>

        {
         (info?.image || info?.image1 || info?.image2 || info?.image3 )  && id == 'insurance' && 
          <div className='about-carousel'>
          <Slider {...sliderSettings}>
            {info?.image && <div className='slider-img' >
              <img src={info?.image} alt='' />
            </div>}
            {info?.image1 && <div className='slider-img' >
              <img src={info?.image1} alt='' />
            </div>}
            {info?.image2 && <div className='slider-img' >
              <img src={info?.image2} alt='' />
            </div>}
            {info?.image3 && <div className='slider-img' >
              <img src={info?.image3} alt='' />
            </div>}
          </Slider>
        </div>}

        <div className='pharmacy-information'>
          <CustomizeHeading title={info?.title} />
          <div className=''>
            

              {info?.image && info?.slug == "message-from-director" &&  <div className='pharmacy-image'>
                  <img src={info?.image} alt='' className='w-60' />
              </div>}
              {info && <div dangerouslySetInnerHTML={{__html: info?.content}} />}
          </div>
        </div>

        
    </div>
  )
}

export default About