import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRoomBeds } from '../../../api/cmsService';
import './RoomBeds.scss'
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { isEmpty } from 'lodash';

function RoomBeds() {
    const dispatch = useDispatch();
    const [info,setInfo] =  useState([]);
    const [roomListInfo,setRoomListInfo] = useState({})
    const { selectedHospital } = useSelector(state => state?.doctorsInfo)
    const [page,setPage] = useState(0)

    const getDetailData = async (p) => {
        const response = await dispatch(getRoomBeds({q : '',page : p ? 0 : page,per_page:10,hospital_id:selectedHospital}))
        // setInfo(response)
        if(response){
          const { list, ...res} = response
          p  ? setInfo(list) : (  setInfo([...info,...list]))
          let v = res
          setRoomListInfo(v)
        }
    } 

    useEffect(() => {
      if(selectedHospital){
        setPage(0)
       getDetailData(true)
      }  
      
    },[selectedHospital])

    useEffect(() => {
      if(page != 0){
        selectedHospital && getDetailData()
      }
    }, [page])

    const [enablePopup,setEnablePopup] = useState(null);

  return (
    <div className='mar-b-135 container pharmacy-information pha'>
      <h1 className='txt-center custom-heading'> Room &  <span>  Bed Facility</span></h1>

        {
          !isEmpty(info) ? 
          <table>
          <tr>
            <th>S.No</th>
            {/* <th>No of Beds</th> */}
            <th>Name</th>
            <th>Description</th>
            <th>Bed Charges</th>
            <th>Nursing Charges</th>
            <th>Total Charges</th>
            <th>Images</th>
          </tr>
          {
            info?.map((v,i) => 
              <tr>
                <td>{v?.srno}</td>
                {/* <td>{v?.noofbeds}</td> */}
                <td>{v?.name}</td>
                <td>{v?.description}</td>
                <td>{v?.bed_charges}</td>
                <td>{v?.nursing_charges}</td>
                <td>{v?.total_charges}</td>
                <td >
                  <div className='room-beds-images'>
                  {v?.image1 && v?.image1 != '' && <Image src={v?.image1} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image2 && v?.image2 != '' && <Image src={v?.image2} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image3 && v?.image3 != '' && <Image src={v?.image3} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image4 && v?.image4 != '' && <Image src={v?.image4} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image5 && v?.image5 != '' && <Image src={v?.image5} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image6 && v?.image6 != '' && <Image src={v?.image6} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image7 && v?.image7 != '' && <Image src={v?.image7} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image8 && v?.image8 != '' && <Image src={v?.image8} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image9 && v?.image9 != '' && <Image src={v?.image9} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}
                  {v?.image10 && v?.image10 != '' && <Image src={v?.image10} preview={true} downloadable={true} alt="" width={'50'} height={'50'}/>}

                  {((!v?.image1 || v?.image1 == '') && (!v?.image2 || v?.image2 == '') && (!v?.image3 || v?.image3 == '') && (!v?.image4 || v?.image4 == '') && (!v?.image5 || v?.image5 == '') &&
                  (!v?.image6 || v?.image6 == '') && (!v?.image7 || v?.image7 == '') && (!v?.image8 || v?.image8 == '') && (!v?.image9 || v?.image9 == '') && (!v?.image10 || v?.image10 == '') ) &&

                  <>No Images Found</>
                  }
                  {/* <img src={v?.image1} alt='' height={50} width={50} />
                  <img src={v?.image2} alt='' height={50} width={50}/>
                  <img src={v?.image3} alt='' height={50} width={50}/>
                  <img src={v?.image4} alt='' height={50} width={50}/>
                  <img src={v?.image5} alt='' height={50} width={50}/>
                  <img src={v?.image6} alt='' height={50} width={50}/>
                  <img src={v?.image7} alt='' height={50} width={50}/>
                  <img src={v?.image8} alt='' height={50} width={50}/>
                  <img src={v?.image9} alt='' height={50} width={50}/>
                  <img src={v?.image10} alt='' height={50} width={50} /> */}
                  </div>
                </td>
              </tr>
              )
           
          }
          
          
        </table>
        :
        <>
          {roomListInfo?.total_count == 0 && <>No Results Found</>}
        </>
        }

      { !isEmpty(info) && roomListInfo?.total_count && info?.length != roomListInfo?.total_count && <div className='txt-center'>
				<button className='doctor-box-scroll-more' onClick={() => setPage(page+1)}>Load More</button>
			</div> }

        {/* <Dialog className='room-beds-popup'
            visible={enablePopup}
            onHide={() => dispatch(setEnablePopup(null))} dismissableMask={true}>
                
        </Dialog> */}

    </div>
  )
}

export default RoomBeds