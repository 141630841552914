import React from 'react'
import { Helmet } from 'react-helmet'

function BookAppointment(props) {
	return (
		<>
			<Helmet>
				<title>BMJH | BookAppointment</title>
			</Helmet>
			<h3>Book Appointment</h3>
		</>
	)
}

export default BookAppointment
