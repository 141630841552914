import axios from 'axios'
import {
	enableToast,
	isRegisteredUser,
	enableLoginModal,
} from 'store/header'
import { userProfile as profileUpdate } from 'store/header'
import { isEmpty } from 'lodash'
import { enableLoginModal as setLoginModal } from 'store/header'
import { useSelector } from 'react-redux'

axios.defaults.baseURL = process.env.API_DOMAIN
// axios.defaults.headers['token'] = process.env.API_ACCESS_TOKEN
const vendor_id = process.env.API_VENDOR_ID
const customer_id = sessionStorage.getItem('customerID')
const token = sessionStorage.getItem('token')

export const getUserProfile = () => async (dispatch, selector) => {
	try {
		if (
			sessionStorage.getItem('customerID') &&
			sessionStorage.getItem('token')
		) {
			let response = await axios.post('/customer/crm/v-1-0-0/customer-detail', {
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
			})
			if (response?.data?.http_code == 200) {
				dispatch(profileUpdate(response?.data?.data))
				dispatch(isRegisteredUser(true))
				return response
			}
		} else if (isEmpty(token)) {
			sessionStorage.setItem('token', process.env.API_ACCESS_TOKEN)
			//window.location.reload()
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const updateProfile = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/update-profile', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		if (response?.data?.http_code == 200) {
			dispatch(profileUpdate(response.data?.data))
			dispatch(
				enableToast({
					http_code: response?.data?.http_code,
					message: 'Updated Successfully',
				})
			)
			return response.data
		} else if (response?.data?.http_code == 400) {
			return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const updateProfileImage = payload => async dispatch => {
	try {
		const file = new FormData()
		file.append('profile_image', payload?.profile_image[0])
		file.append('vendor_id', vendor_id)
		file.append('token', sessionStorage.getItem('token'))
		file.append('customer_id', sessionStorage.getItem('customerID'))

		let response = await axios.post(
			'customer/crm/v-1-0-0/update-profile-image',
			file
		)
		if (response?.data?.http_code == 200) {
			let v = { ...payload.userInfo, ...{ profile_image: response?.data?.data?.profile_image } }
			dispatch(profileUpdate(v))
			dispatch(
				enableToast({
					http_code: response?.data?.http_code,
					message: 'Profile picture updated successfully',
				})
			)
			return response.data
		} else if (response?.data?.http_code == 400) {
			return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const signUp = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/signup', {
			vendor_id,
			login_type: 'normal',
			country_code: 91,
			device_type: 'web',
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return response.data
		} else if (response?.data?.http_code == 400) {
			dispatch(setLoginModal())
			return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const verifyOtp = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/verify-otp', {
			vendor_id,
			type: 'signup',
			...payload,
		})
		if (response?.data?.http_code == 200) {
			dispatch(profileUpdate(response.data?.data))
			dispatch(isRegisteredUser(true))
			dispatch(enableLoginModal())
			sessionStorage.setItem('customerID', response?.data?.data?.CustomerId)
			sessionStorage.setItem('token', response?.data?.data?.token)
			dispatch(
				enableToast({
					http_code: response?.data?.http_code,
					message: 'Welcome Back!',
					detail: 'Logged In Successfully',
				})
			)
		} else if (response?.data?.http_code == 400) {
			return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const resendOtp = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/resend-otp', {
			vendor_id,
			...payload,
		})
		return dispatch(enableToast(response?.data))
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const subscribeNewsLetter = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/subscription/add', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			device_type: 'web',
			...payload,
		})
		return dispatch(
			enableToast(response?.data)
		)
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const contactUsForm = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/crm/v-1-0-0/subscription/contact-us',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				...payload,
			}
		)
		return dispatch(
			enableToast({
				http_code: response?.data?.http_code,
				message: 'Thank you for contacting us!',
				detail: ' We will get back to you shortly',
			})
		)
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const signOut = () => async dispatch => {
	sessionStorage.clear()
	await dispatch(profileUpdate({}))
	await dispatch(isRegisteredUser(false))
	await dispatch(
		enableToast({ http_code: 200, message: 'Logged Out Successfully' })
	)
	window.location.reload()
}

