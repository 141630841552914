import React from 'react'
import { Icons, TitleCard } from 'components'
import { isEmpty } from 'lodash'
import './Events.scss'

function Events(props) {
	const getEventsList = !isEmpty(props?.data?.placeholders)
		? props?.data?.placeholders[0]
		: []
	return (
		<>
		{getEventsList?.length != 0 && <section className='events'>
			<div className='news-events container'>
				<div className='wid-92 mar-auto'>
					<TitleCard
						title={getEventsList?.title}
						description={getEventsList?.description}
					/>
					<div className='d__flex news-event '>
						{getEventsList?.news_events?.map(e => (
							<div className='event-item1'>
								<div className='image-ctr'>
									<img src={e?.image} alt='' />
								</div>
								<div className='event-info'>
									<h4>{e?.title}</h4>
									<p>{e?.content}</p>
								</div>
								<button>
									<Icons
										name='common'
										iconType='arrow-next-white'
										width='25px'
										strokeWidth='0'
										strokeColor='#ffff'
										viewBox='0 0 30 29'
									/>{' '}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>}
	</>
	)
}

export default Events
export { Events }
