import './PrevNext.scss';

import React from 'react';

import { Link } from 'react-router-dom';

import { Icons } from 'components';

function PrevNext() {
  return (
    <div className='prev-next'>
      {/* <p className='txt-center prev-p'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
      <div className='d__flex just-c gap-20 m-d-flex al-fl'>
       <Link to={'/facilities'} >
       <button className='d__flex gap-20 m-d-flex'>
          <div >
          <Icons
            name='common'
            iconType='arrow-prev'
            strokeWidth='0'
            strokeColor='#ffff'
            viewBox="0 0 30 28"
          /> 
          </div>

          Other facilities
        </button>
       </Link>
       <Link to={'/specialties'}>
        <button className='d__flex gap-20 m-d-flex'>
          <div>
          <Icons
            name='common'
            iconType='arrow-next'
            strokeWidth='0'
            strokeColor='#ffff'
            viewBox="0 0 30 29"
          /> 
          </div>
          Other Specialties
        </button>
       </Link>
      </div>
    </div>
  )
}

export default PrevNext
export { PrevNext };