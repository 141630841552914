import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Dialog } from 'primereact/dialog'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { findDoctors } from 'api/doctorService'
import { DoctorBox, Icons,CustomizeHeading } from 'components'
import { Dropdown } from 'primereact/dropdown'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { Appointments } from 'pages'
import specialties1 from '../../../assets/specialties/specialties1.png'
import { isEmpty } from 'lodash'
import { enableAppointment as setEnableAppointment } from 'store/header'
import './FindDoctor.scss'
import { findDoctorsWithNode } from '../../../api/doctorService'
import { enableLoader } from '../../../store/header'
import { Loader } from '../../components'

function FindDoctor(props) {
	const dispatch = useDispatch()
	let [searchParams, setSearchParams] = useSearchParams()

	const [doctorsList, setDoctorsList] = useState([])
	const [specialtiesList, setSpecialtiesList] = useState([])
	const [searchValue, setSearchValue] = useState(searchParams.get('search'))
	const [location, setLocation] = useState()

	const { enableAppointment,loader } = useSelector(state => state?.header)
	const { hospitalList, selectedHospital } = useSelector(
		state => state?.doctorsInfo
	)
	const optionsList = hospitalList?.map(l => ({
		name: l?.name,
		id: l?.id,
	}))

	useEffect(() => {
		selectedHospital && searchParams && getSearchList()
		setLocation(selectedHospital)
	}, [selectedHospital, searchParams])

	const getSearchList = async (isFromSearchPage) => {
		if(searchValue == ''){
			setDoctorsList([]),setSpecialtiesList([])
			setSearchValue(''), setSearchParams({ search: '' })
		} else {
			dispatch(enableLoader(true))
			isFromSearchPage && setSearchParams({ search: searchValue })
			let getSearchValue = ''
			!isFromSearchPage ? getSearchValue = searchParams.get('search') : getSearchValue = searchValue
			// setSearchValue(getSearchValue)
			const response = await dispatch(
				findDoctors({
					hospital_id: selectedHospital,
					key: searchValue,
				})
			)

			const r = await dispatch(
				findDoctorsWithNode({
				hospital_id: selectedHospital,
				key: searchValue,
			}))

			setDoctorsList(r?.data)
			setSpecialtiesList(response?.specialties_list)
		}
	}

	const keyPress = (e) => {
		(e.key == 'Enter' && getSearchList(true))
	}

	return (
		<>
			<Helmet>
				<title>BMJH | FindDoctor</title>
			</Helmet>

			

			<div className='mar-b-135 doctors container find-doctor-ctr'>
				{/* <h1 className='txt-center custom-heading'>
					Find <span>Specialties or Doctors</span>
				</h1> */}
				<CustomizeHeading title={'Find Specialties or Doctors'}/>


				{/* <SearchInput onClick={getSearchTerm} label='Packages' /> */}

				<div className='search-input d__flex m-d-flex'>
					{/* <Icons
						className='location-icon web-location-icon'
						name='home'
						iconType='location-filled'
						width='18'
						height='26'
						viewBox='0 0 18 26'
					/> */}
					{/* <Dropdown
						optionLabel='name'
						value={location}
						optionValue='id'
						options={optionsList}
						onChange={e => dispatch(setSelectedHospital(e?.value))}
						placeholder='Select a Hospital'
						className='location-web'
					/> */}

					<input
						type={'text'}
						placeholder='Search for Specialties or Doctors'
						value={searchValue}
						onChange={e => setSearchValue(e?.target?.value)}
						onKeyDown={(e) => keyPress(e) }
						// onChange={e =>
						// 	e?.target?.value == ''
						// 		? (setDoctorsList([]),setSpecialtiesList([]),setSearchValue(''), setSearchParams({ search: '' }))
						// 		: (setSearchValue(e?.target?.value),setSearchParams({ search: e?.target?.value }))
						// }
					/>
					<i
						onClick={() => (
							setSearchValue(''), setSearchParams({ search: '' }) ,setDoctorsList([]),setSpecialtiesList([])
						)}
						className={`reset-search-inp pi pi-times ${
							searchValue?.length > 0 ? '' : 'p-hidden'
						}`}
					/>
						<button
							onClick={() => getSearchList(true)} 
							className='search-icon'>
							Search
						</button>
					{/* <buttonc lassName='search-icon' onClick={() => getSearchList(true)} onKeyDown={(e) => keyPress(e) }> 
						<Icons
							name='home'
							iconType='search'
							width='26'
							height='26'
							viewBox='0 0 26 26'
						/>
					</buttonc> */}
				</div>

				<div>
				{!isEmpty(specialtiesList) && (
					<>
						<h3>Specialties</h3>
						<div className='flex-1 d__flex flex-w specialties gap-20'>
							{specialtiesList?.map((item, i) => (
								<div className='specialty item' key={i}>
									<Link to={item?.name_slug != 'preventive-healthcare-checkup-services' && item?.name_slug !='preventive-health-checkup-services'?`/specialties/${item?.id}`:'/medical-packages'}>
									<img
										src={ item?.image ?? specialties1}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null
											currentTarget.src = specialties1
										}}
										alt=''
										height={'100%'}
										width={'100%'}
										className='rounded-8'
									/>
									</Link>
									<Link to={item?.name_slug != 'preventive-healthcare-checkup-services' && item?.name_slug !='preventive-health-checkup-services'?`/specialties/${item?.id}`:'/medical-packages'}>
										<h5 className='speciality-title'>{item?.name}</h5>
									</Link>
								</div>
							))}
						</div>
					</>
				)}
				</div>

				{loader ?
				<>
					<Loader />
				</>
				:
				<>
					{!isEmpty(doctorsList) && (
					<>
							<h3>Doctors</h3>
							
							<div className='d__flex  m-d-flex gap-2 fla-1 doctor-box-non-scroll' >
									{doctorsList?.map((res, index) => (
										<div className='item' key={index+'FD'}>
											<DoctorBox  doctor={res} />
										</div>
									))}
								</div>
							
						</>
					)}

					{isEmpty(specialtiesList) && isEmpty(doctorsList) && (
						<p>No Results found</p>
					)}
				</>	

				}
				<Dialog
					visible={!isEmpty(enableAppointment)}
					style={{
						width: '80%',
						height: '1000px',
						boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.15)',
						borderRadius: '0px 40px 40px 40px',
						overflow: 'hidden',
					}}
					onHide={() => dispatch(setEnableAppointment([]))}
					dismissableMask={true}>
					<Appointments isFromDoctor={true} />
				</Dialog>
			</div>

			
		</>
	)
}

export default FindDoctor
