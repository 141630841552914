import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Icons } from 'components'
import { classNames } from 'primereact/utils'
import { contactUsForm } from 'api/authService'
import './ContactUs.scss'
import { Helmet } from 'react-helmet'
import { concat } from 'lodash'

function ContactUs() {
	const dispatch = useDispatch()
	const schema = yup.object({
		customer_name: yup.string().required('Enter your full name'),
		email: yup.string().email().required('Enter your email address'),
		subject: yup.string().required('Enter your subject'),
		message: yup.string().required('Enter your message'),

	})


	const { selectedHospital } = useSelector(state => state?.doctorsInfo)

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		reset
	} = useForm({
		resolver: yupResolver(schema),
	})

	const getFormErrorMessage = name => {
		return (
			errors?.[name] && (
				<small className='p-error'>{errors[name].message}</small>
			)
		)
	}

	const { staticInfos } = useSelector(state => state.header)

	const { subCategoriesList } = useSelector(state => state?.categories)

	const renderTabs = concat({ id: '00', name: 'All Department', book_appointment: 1 }, subCategoriesList)

	const onSubmit = data => {
		let department_name = renderTabs?.find(v => v.id == data?.department_id)
		dispatch(contactUsForm({ ...data, department_name: department_name?.name, hospital_id: selectedHospital }))
		reset()
	}

	useEffect(() => {

		selectedHospital && reset()
		return () => {

		}
	}, [selectedHospital])


	return (
		<section className='contact-us-ctr'>
			<Helmet>
				<title>BMJH | Contact Us</title>
			</Helmet>
			<div className='container'>
				<h1 className='txt-center custom-heading'>
					Contact <span>Us</span>
				</h1>
				<div className='contact-us'>
					<h3 className='title'>Contact Us</h3>
					<div className='d__flex align-start gap-50'>
						<div className='address'>
							<ul>
								<h3>Bhagwan Mahaveer Jain Hospital - Vasanthnagar</h3>
								<li>
									<span>Address: </span> #17, Millers Road, Vasanthnagar, Bengaluru – 560 052
								</li>
								<li>
									<span>For Appointment Call: </span> <a href='tel:080 4087 5555'>080 4087 5555</a> / 4110 0550 / 52
								</li>
								<li>
									<span>For Emergency Call:</span>  <a href="tel:080 4087 5500">080 4087 5500 </a>  / <a href="tel:080 2253 5353">080 2253 5353 </a>
								</li>
								<li>
									<span>Email: </span> <a href="mailto:bmjh.mdoffice@gmail.com">bmjh.mdoffice@gmail.com</a>
								</li>
							</ul>
							<ul>
								<h3>Bhagwan Mahaveer Jain Hospital, Girinagar</h3>
								<li>
									<span>Address: </span>6th Cross, Girinagar, Banashankari 3rd Stage, Avalahalli Extn, Bengaluru 560085
								</li>
								<li>
									<span>For Appointment Call: </span> <a href="tel:080 6176 5555">080 6176 5555</a>
								</li>
								<li>
									<span>For Emergency Call:</span> <a href="tel:080 6176 5566">080 6176 5566</a>
								</li>
								<li>
									<span>Email: </span> <a href="mailto:mailto:care@bmjh.org">care@bmjh.org</a>
								</li>
							</ul>

							<div className='follow-us'>
								<h5>Follow Us on Social Media</h5>
								<ul className='d__flex m-d-flex gap-0'>
									<a href={staticInfos?.['linkedin-link']?.value} target='_blank'>
										<li>
											{' '}
											<Icons
												name='social'
												iconType='linkedin'
												width="60" height="77" viewBox="0 0 60 77"
											/>{' '}
										</li>
									</a>
									<a href={staticInfos?.['youtube_link']?.value} target='_blank'>
									<li>
										{' '}
										<Icons
											name='social'
											iconType='youtube'
											width="60" height="77" viewBox="0 0 60 77"
										/>{' '}
									</li>
									</a>
									<a href={staticInfos?.['fb-link']?.value} target='_blank'>
										<li>

											<Icons
												name='social'
												iconType='facebook'
												width="60" height="77" viewBox="0 0 60 77"
											/>
										</li>
									</a>
									<a href={staticInfos?.['insta-link']?.value} target='_blank'>
										<li>
											{' '}
											<Icons
												name='social'
												iconType='instagram'
												width="60" height="77" viewBox="0 0 60 77"
											/>{' '}
										</li>
									</a>
									{/* <a href={staticInfos?.['twitter-link']?.value} target='_blank'>
								<li>
									{' '}
									<Icons
										name='social'
										iconType='twitter'
										width='15'
										height='12'
										viewBox='0 0 15 12'
									/>{' '}
								</li>
								</a> */}
								</ul>
							</div>
						</div>
						<div className='contact-form'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className='input-group d__flex gap-20'>
									<div className='input-item flex-1'>
										<label>Your Name *</label>
										<input
											placeholder='Enter Your Name'
											type='text'
											className={classNames({
												'p-invalid': errors['customer_name'],
											})}
											{...register('customer_name', {
												required: true,
												maxLength: 30,
											})}
										/>
										{getFormErrorMessage('customer_name')}
									</div>
									<div className='input-item flex-1'>
										<label>Your Email *</label>
										<input
											placeholder='Enter Your Email'
											type='text'
											className={classNames({
												'p-invalid': errors['email'],
											})}
											{...register('email', {
												required: true,
												maxLength: 30,
											})}
										/>
										{getFormErrorMessage('email')}
									</div>
								</div>
								<div>
									<label>Department</label>
									<select {...register('department_id')}>
										{renderTabs?.map((v, i) =>
											<option key={i + 'RT'} value={v?.id} >{v?.name}</option>
										)}
									</select>
								</div>
								<div className='input-item'>
									<label>Subject *</label>
									<input
										placeholder='Enter Your Subject'
										type='text'
										className={classNames({
											'p-invalid': errors['subject'],
										})}
										{...register('subject', {
											required: true,
											maxLength: 30,
										})}
									/>
									{getFormErrorMessage('subject')}
								</div>
								<div className='input-item'>
									<label>Your Message *</label>
									<textarea
										placeholder='Enter Your Message'
										type='text'
										className={classNames({
											'p-invalid': errors['message'],
										})}
										{...register('message', {
											required: true,
											maxLength: 30,
										})}
									/>
									{getFormErrorMessage('message')}
								</div>
								<div className='txt-right'>
									<button>Send Message</button>
								</div>
							</form>
						</div>
					</div>
				</div>

			</div>
			<div className='map'>
				{/* <iframe
					src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.768060682874!2d77.595438!3d12.99154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x730cce157da6d9cb!2sBhagwan%20Mahaveer%20Jain%20Hospital!5e0!3m2!1sen!2sin!4v1648608333859!5m2!1sen!2sin'
					allowFullScreen=''
					loading='lazy'
					referrerPolicy='no-referrer-when-downgrade'></iframe> */}
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6934185966265!2d77.5952739!3d12.99145035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1642c63e2c23%3A0x7237d126c4e290eb!2sBHAGWAN%20MAHAVEER%20JAIN%20HOSPITAL-VASANTH%20NAGAR%2C%20Kaverappa%20Layout%2C%20Vasanth%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560051!5e0!3m2!1sen!2sin!4v1666079386825!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.4293444978766!2d77.53500621421942!3d12.944355790873749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f51a7924851%3A0x3f0d4203f42e930d!2sBhagwan%20Mahaveer%20Jain%20Hospital%2C%20Girinagar!5e0!3m2!1sen!2sin!4v1666079481777!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</section>
	)
}

export default ContactUs
