import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './PrescriptionList.scss'
import { getPrescriptions } from '../../../api/appointmentService'
import { Image } from 'primereact/image';

function PrescriptionList({appointmentId,setPrescriptionLists,hospitalID}) {
	const dispatch = useDispatch()
	const [getPrescriptionsList, setPrescriptionsList] = useState([])
	const { selectedHospital } = useSelector(state => state.doctorsInfo)

	useEffect(() => {
		prescriptionList()
	}, [selectedHospital,hospitalID])

	const prescriptionList = async () => {
		let response = await dispatch(
			getPrescriptions({
				hospital_id: hospitalID ?? selectedHospital,
				appoinment_id: appointmentId,
			})
		)
		setPrescriptionLists(response?response?.customer_appointments_prescriptions_list:[])
		setPrescriptionsList(response?response?.customer_appointments_prescriptions_list:[])
	}


	return (
		<div className='prescriptions-list'>

			{ getPrescriptionsList?.length != 0 && <div className='prescription-head'>
				<h4>Prescriptions</h4>
			</div>}
			{
				getPrescriptionsList?.map((res,index) =>
				<div className='record d__flex align-start gap-20'key={'RL'+index}>
					{!appointmentId && <div className='date-box'>
						<span>{new Date(res?.prescriptions_created_at).getDate()}</span>
						<span>{new Date(res?.prescriptions_created_at).toLocaleString('default', {
									month: 'long',
								})}</span>
					</div>}
					<div className='flex-1 record-info'>
						<h5 className='m-0'>{res?.prescriptions_name}</h5>
						{/* <p className='m-0'>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's standard dummy text
							ever since the 1500s.
						</p> */}
						<div className='record-images d__flex m-d-flex gap-20'>
							{
								res?.prescriptions_files?.map((value,index)=>
								<div key={index+'RF'}>
									 <Image src={value?.prescriptions_file_url} preview={true} downloadable={true} alt="Image" width="250" height='auto'/>
									{/* <Image src={value?.prescriptions_file_url} template="Preview Content"  height={'40px'} width={'40px'} alt="Image Text" /> */}
									{/* <img  height="40px" width={'40px'} alt=''/> */}
								</div>
								)
							}
							
							{/* <div>+14</div> */}
						</div>
					</div>
					{/* <Link to={'/profile/records/1'}>
						<button>View Detail</button>
					</Link> */}
				</div>
				)
			}
		</div>
	)
}

export default PrescriptionList
