import './MyProfile.scss';

import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

// import { Outlet } from 'react-router-dom';
import { Icons, ProfileMenu } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { enableLoginModal as setLoginModal } from 'store/header'
import { Helmet } from 'react-helmet';


function MyProfile() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { userProfile } = useSelector(state => state.header)
    const { selectedHospital  } = useSelector(state => state.doctorsInfo)
    

    return (
        <>
            <div className='container myprofile-ctr mar-b-135'>
                <Helmet>
                    <title>BMJH | Home</title>
                </Helmet>
                {
                userProfile?.phone ?
                <div className='d__flex align-start profile-ctr'>
                    <div className={`menu-options ${location.pathname != '/profile'?'hide-profile-menu':''}`}>
                        <ProfileMenu />
                    </div>
                    <div className='flex-1 side-2-info'>
                        <Outlet />
                    </div>
                </div>
                :
                <>
                {
                selectedHospital &&
                <div className='go-center '>
                    <div>
                      <h2>No User Info Found !!</h2>
                    </div>
                    <button className='d__flex gap-5 login-btn' onClick={() => dispatch(setLoginModal())}>
					<Icons name='header' iconType='user' width='30' height='30' viewBox='0 0 30 30' />
                         Please Login 
				    </button>
                </div>
                }
                </>
                }
            </div>
        </>
    )
}



export default MyProfile
