import React from 'react'

import get from 'get-value'

function SocialIcons(props) {
	const matchIcon = (iconType, iconColor, customIcon) => {
		switch (iconType) {
			case 'twitter':
				return (
					<>
						<path
							d='M14.2857 1.34934C13.75 1.61921 13.2143 1.70917 12.5893 1.79913C13.2143 1.4393 13.6607 0.899563 13.8393 0.179913C13.3036 0.539738 12.6786 0.719651 11.9643 0.899563C11.4286 0.359825 10.625 0 9.82143 0C8.30357 0 6.96429 1.34935 6.96429 2.96856C6.96429 3.23843 6.96429 3.41834 7.05357 3.59825C4.64286 3.5083 2.41071 2.33886 0.982143 0.539738C0.714286 0.98952 0.625 1.4393 0.625 2.069C0.625 3.05852 1.16071 3.95808 1.96429 4.49782C1.51786 4.49782 1.07143 4.3179 0.625 4.13799C0.625 5.57729 1.60714 6.74673 2.94643 7.01659C2.67857 7.10655 2.41071 7.10655 2.14286 7.10655C1.96429 7.10655 1.78571 7.10655 1.60714 7.01659C1.96429 8.18603 3.03571 9.08559 4.375 9.08559C3.39286 9.8952 2.14286 10.345 0.714286 10.345C0.446429 10.345 0.267857 10.345 0 10.345C1.33929 11.1546 2.85714 11.6943 4.46429 11.6943C9.82143 11.6943 12.7679 7.19651 12.7679 3.32838C12.7679 3.23843 12.7679 3.05852 12.7679 2.96856C13.3929 2.51878 13.9286 1.97904 14.2857 1.34934Z'
							fill={fill}
						/>
					</>
				)
			case 'instagram':
				return (
					<svg width="70" height="84" viewBox="0 0 70 84" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g filter="url(#filter0_dd_12530_19)">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M35 67C48.8071 67 60 55.8071 60 42C60 28.1929 48.8071 17 35 17C21.1929 17 10 28.1929 10 42C10 55.8071 21.1929 67 35 67Z" fill="white" />
							<path d="M58.5 42C58.5 54.9787 47.9787 65.5 35 65.5C22.0213 65.5 11.5 54.9787 11.5 42C11.5 29.0213 22.0213 18.5 35 18.5C47.9787 18.5 58.5 29.0213 58.5 42Z" stroke="white" stroke-width="3" />
						</g>
						<path d="M38.8678 39.9423C39.2986 39.9423 39.6478 39.593 39.6478 39.1623C39.6478 38.7315 39.2986 38.3823 38.8678 38.3823C38.437 38.3823 38.0878 38.7315 38.0878 39.1623C38.0878 39.593 38.437 39.9423 38.8678 39.9423Z" fill="#0A142F" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M32.25 42.5C32.25 44.2924 33.7076 45.75 35.5 45.75C37.2924 45.75 38.75 44.2924 38.75 42.5C38.75 40.7076 37.2924 39.25 35.5 39.25C33.7076 39.25 32.25 40.7076 32.25 42.5ZM33.875 42.5C33.875 41.6038 34.6038 40.875 35.5 40.875C36.3962 40.875 37.125 41.6038 37.125 42.5C37.125 43.3962 36.3962 44.125 35.5 44.125C34.6038 44.125 33.875 43.3962 33.875 42.5Z" fill="#0A142F" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M32.25 49H38.75C40.4205 49 42 47.4205 42 45.75V39.25C42 37.5795 40.4205 36 38.75 36H32.25C30.5795 36 29 37.5795 29 39.25V45.75C29 47.4205 30.5795 49 32.25 49ZM30.625 39.25C30.625 38.4903 31.4903 37.625 32.25 37.625H38.75C39.5097 37.625 40.375 38.4903 40.375 39.25V45.75C40.375 46.5097 39.5097 47.375 38.75 47.375H32.25C31.4757 47.375 30.625 46.5243 30.625 45.75V39.25Z" fill="#0A142F" />
						<defs>
							<filter id="filter0_dd_12530_19" x="0" y="0" width="70" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.1 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12530_19" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="-7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.01 0" />
								<feBlend mode="normal" in2="effect1_dropShadow_12530_19" result="effect2_dropShadow_12530_19" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12530_19" result="shape" />
							</filter>
						</defs>
					</svg>
				)
			case 'facebook':
				return (

					<svg width="70" height="84" viewBox="0 0 70 84" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g filter="url(#filter0_dd_12530_12)">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M35 67C48.8071 67 60 55.8071 60 42C60 28.1929 48.8071 17 35 17C21.1929 17 10 28.1929 10 42C10 55.8071 21.1929 67 35 67Z" fill="white" />
							<path d="M58.5 42C58.5 54.9787 47.9787 65.5 35 65.5C22.0213 65.5 11.5 54.9787 11.5 42C11.5 29.0213 22.0213 18.5 35 18.5C47.9787 18.5 58.5 29.0213 58.5 42Z" stroke="white" stroke-width="3" />
						</g>
						<path d="M33.699 49.393L33.6786 43.0961H31V40.3974H33.6786V38.5983C33.6786 36.1702 35.171 35 37.3209 35C38.3507 35 39.2358 35.0772 39.4937 35.1118V37.6493L38.0026 37.65C36.8334 37.65 36.607 38.2097 36.607 39.0312V40.3974H39.9286L39.0357 43.0961H36.607V49.393H33.699Z" fill="#0A142F" />
						<defs>
							<filter id="filter0_dd_12530_12" x="0" y="0" width="70" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.1 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12530_12" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="-7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.01 0" />
								<feBlend mode="normal" in2="effect1_dropShadow_12530_12" result="effect2_dropShadow_12530_12" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12530_12" result="shape" />
							</filter>
						</defs>
					</svg>

				)
			case 'youtube':
				return (
					<svg width="70" height="84" viewBox="0 0 70 84" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g filter="url(#filter0_dd_12530_37)">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M35 67C48.8071 67 60 55.8071 60 42C60 28.1929 48.8071 17 35 17C21.1929 17 10 28.1929 10 42C10 55.8071 21.1929 67 35 67Z" fill="white" />
							<path d="M58.5 42C58.5 54.9787 47.9787 65.5 35 65.5C22.0213 65.5 11.5 54.9787 11.5 42C11.5 29.0213 22.0213 18.5 35 18.5C47.9787 18.5 58.5 29.0213 58.5 42Z" stroke="white" stroke-width="3" />
						</g>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M43.1045 35.9698C43.6439 36.5306 43.82 37.8042 43.82 37.8042C43.82 37.8042 44 39.3002 44 40.7956V42.198C44 43.694 43.82 45.1894 43.82 45.1894C43.82 45.1894 43.6439 46.463 43.1045 47.0239C42.4878 47.6873 41.8039 47.7559 41.4174 47.7946C41.3749 47.7989 41.336 47.8028 41.3011 47.8071C38.7822 47.9942 35 48 35 48C35 48 30.32 47.9561 28.88 47.814C28.8118 47.8009 28.7291 47.7907 28.6354 47.7792C28.1793 47.723 27.4633 47.6348 26.8949 47.0239C26.3555 46.463 26.18 45.1894 26.18 45.1894C26.18 45.1894 26 43.694 26 42.198V40.7956C26 39.3002 26.18 37.8042 26.18 37.8042C26.18 37.8042 26.3555 36.5306 26.8949 35.9698C27.513 35.3054 28.1975 35.2376 28.5841 35.1993C28.626 35.1951 28.6644 35.1913 28.6989 35.1871C31.2177 35 34.9961 35 34.9961 35H35.0039C35.0039 35 38.7822 35 41.3011 35.1871C41.3356 35.1913 41.374 35.1951 41.4159 35.1993C41.8022 35.2376 42.487 35.3055 43.1045 35.9698ZM33.1406 38.7031L33.1412 43.8963L38.004 41.3087L33.1406 38.7031Z" fill="#0A142F" />
						<defs>
							<filter id="filter0_dd_12530_37" x="0" y="0" width="70" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.1 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12530_37" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="-7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.01 0" />
								<feBlend mode="normal" in2="effect1_dropShadow_12530_37" result="effect2_dropShadow_12530_37" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12530_37" result="shape" />
							</filter>
						</defs>
					</svg>
				)
			case 'linkedin':
				return (
					<svg width="70" height="84" viewBox="0 0 70 84" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g filter="url(#filter0_dd_12530_36)">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M35 67C48.8071 67 60 55.8071 60 42C60 28.1929 48.8071 17 35 17C21.1929 17 10 28.1929 10 42C10 55.8071 21.1929 67 35 67Z" fill="white" />
							<path d="M58.5 42C58.5 54.9787 47.9787 65.5 35 65.5C22.0213 65.5 11.5 54.9787 11.5 42C11.5 29.0213 22.0213 18.5 35 18.5C47.9787 18.5 58.5 29.0213 58.5 42Z" stroke="white" stroke-width="3" />
						</g>
						<path d="M31.7198 38.5625H29.2473C29.1108 38.5625 29 38.6733 29 38.8098V46.2275C29 46.364 29.1108 46.4748 29.2473 46.4748H31.7198C31.8563 46.4748 31.9671 46.364 31.9671 46.2275V38.8098C31.9671 38.6733 31.8563 38.5625 31.7198 38.5625Z" fill="#0A142F" />
						<path d="M39.0876 38.1538C38.0308 37.7918 36.7089 38.1097 35.9162 38.6799C35.889 38.5736 35.7921 38.4945 35.6769 38.4945H33.2043C33.0678 38.4945 32.957 38.6052 32.957 38.7417V46.1595C32.957 46.296 33.0678 46.4067 33.2043 46.4067H35.6769C35.8134 46.4067 35.9241 46.296 35.9241 46.1595V40.8286C36.3237 40.4844 36.8385 40.3746 37.2598 40.5536C37.6683 40.7262 37.9022 41.1476 37.9022 41.7088V46.1595C37.9022 46.296 38.013 46.4067 38.1495 46.4067H40.622C40.7585 46.4067 40.8693 46.296 40.8693 46.1595V41.2109C40.8411 39.1789 39.8852 38.4267 39.0876 38.1538Z" fill="#0A142F" />
						<defs>
							<filter id="filter0_dd_12530_36" x="0" y="0" width="70" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.1 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12530_36" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dy="-7" />
								<feGaussianBlur stdDeviation="5" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0 0.294118 0 0 0 0.01 0" />
								<feBlend mode="normal" in2="effect1_dropShadow_12530_36" result="effect2_dropShadow_12530_36" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12530_36" result="shape" />
							</filter>
						</defs>
					</svg>
				)
			default:
				undefined
		}
	}

	const className = get(props, 'className', '')
	const iconType = get(props, 'iconType', '')
	const iconColor = get(props, 'iconColor', '#000')
	const customIcon = get(props, 'customIcon', '')
	const width = get(props, 'width', '20px')
	const height = get(props, 'height', '')
	const viewBox = get(props, 'viewBox', '0 0 24 24')
	const onClick = get(props, 'onClick', {})
	const strokeColor = get(props, 'strokeColor', '')
	const strokeWidth = get(props, 'strokeWidth', '')
	const fill = get(props, 'fillColor', '#0A142F')
	return (
		<svg
			className={className}
			viewBox={viewBox}
			stroke={strokeColor}
			strokeWidth={strokeWidth}
			fill={fill}
			onClick={onClick}
			style={{ width: width, height: height }}>
			{matchIcon(iconType, iconColor, customIcon)}
		</svg>
	)
}

export { SocialIcons }
export default SocialIcons
