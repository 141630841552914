import React from 'react'
import { Carousel } from 'primereact/carousel'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Icons from '../../Icons'
import './BannerCard.scss'
import Slider from "react-slick";

function BannerCard(props) {
	const getBanners = !isEmpty(props?.data?.placeholders)
		? props?.data?.placeholders[0].packages
		: []

	const sliderSettings = {
		className: "center-slide",
		centerMode: true,
		infinite: true,
		centerPadding: "0px",
		slidesToShow: 3,
		speed: 500,
		autoplay: true,
		dots :true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				dots: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : false
			  }
			}
		]
	};

	return (
		<div className='banner-card'>
			<div className='container'>
				<div className='main-width d__flex'>
					<div className='banner-left'>
						<h1>{props?.data?.name}</h1>
						<p>{props?.data?.description}</p>
						<Link to={'/medical-packages'}>
							<button className='d__flex view-button gap-15  m-d-flex'>
								<span>
									<Icons
										name='home'
										iconType='view-profile'
										width='54'
										height='54'
										viewBox='0 0 54 54'
									/>
								</span>
								View More
							</button>
						</Link>
					</div>
					<div className='banner-right flex-1'>
					<Slider {...sliderSettings}>
						{
							getBanners.map((item,index) =>
							<div key={index+'PKG'} className="h-500" style={{height:'500px !important'}}>
								<div className='health-cards' >
								<h4>{item?.name_en}</h4>
								<div className='image-ctr'>
									<img src={item?.image1} alt='' />
								</div>
								<ul>
									{item?.parameters?.slice(0,7)?.map((t,index) => (
										<li key={index+'BC'} className=''>{t?.name}</li>
									))}
								</ul>
								<div className='txt-center'>
									<Link to={'/medical-packages/'+item?.id}>
										<button className='read-more'>Read More</button>
									</Link>
								</div>
							</div>
							</div>
							)
						}
					</Slider>

						{/* <Carousel
							value={getBanners}
							numVisible={3}
							responsiveOptions={responsiveOptions}
							numScroll={3}
							itemTemplate={sliderPackage}
						/> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default BannerCard
export { BannerCard }
