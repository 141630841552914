import { isEmpty } from 'lodash';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { getHospitalsList } from 'api/doctorService'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import './Careers.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCmsDetails } from 'api/cmsService';
import { careerList } from '../../../api/cmsService';
import { Dialog } from 'primereact/dialog'
import { CareerAdd, SearchInput } from '../../components';

function Careers() {
    const dispatch = useDispatch();
    const { hospitalList, selectedHospital } = useSelector(
		state => state.doctorsInfo
	)

    // useEffect(() => {
    //     if (isEmpty(hospitalList)) {
	// 		dispatch(getHospitalsList())
	// 	}
    // })


    const [info,setInfo] =  useState({});
    const [page,setPage] =  useState(1);
    const [searchKey,setSearchKey] =  useState('');
    const [careers,setCareers] = useState({});
    const [enablePopup,setEnablePopup] = useState(null);

    const getDetailData = async () => {
		const response = await dispatch(getCmsDetails({url:'careers'}))
        setInfo(response)
	} 

    const getCareerList = async (q,p) => {
        setPage(p)
		const response = await dispatch(careerList({q:q,page:p - 1,per_page:5,hospital_id:selectedHospital}))
        if(p == 1 || q != ''){
            setCareers(response)
        } else {
            let r = response
            r.careers_list = [...careers.careers_list,...response.careers_list]
            setCareers(r)
        }
	} 

    useEffect(() => {  
        getDetailData();
        return () => {
        }
    }, [])

  

  useEffect(() => {  
    selectedHospital && setPage(0)
    selectedHospital && getCareerList('',1);
    setSearchKey('')
    
    return () => {
    }
  }, [selectedHospital])


  const getSearchTerm = async searchTerm => {
    if (searchTerm == '') {
        setSearchKey('')
        getCareerList('',page)
    } else {
        setSearchKey(searchTerm)
        getCareerList(searchTerm,page)
    }
   }


  return (
    <div className='container careers mar-b-135'>
        <Helmet>
          <title>BMJH | Careers</title>
        </Helmet>
        <h1 className='txt-center custom-heading'>Careers <span></span></h1>
        <div className='career-description'>
            {info && <div className='career-image'>
                <img src={info?.image} alt='' />
            </div>}
            <div >
                {info && <div dangerouslySetInnerHTML={{__html: info?.content}} />}
                { careers && careers?.careers_list &&
                <div>
                    <div className='d__flex career-heading'>
                        <h2 className='search-head-text'>Find Your Match</h2>
                       <div className='search-input-bx'>
                         <SearchInput  onClick={getSearchTerm} label='Careers List' />
                       </div>
                    </div>

                    
                        
                        <div className='d__flex align-start payments'>
                            <div className='payment-types'>
                                {hospitalList?.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => dispatch(setSelectedHospital(item?.id))}
                                            className={`type ${
                                                selectedHospital == item?.id ? 'active' : null
                                            }`}>
                                        
                                            {item?.name}
                                        </div>
                                    ))}
                            </div>
                            <div className='payment-options'>
                                <div>
                                    {/* <h3>Payment Methods</h3> */}
                                    <div className='careers'>
                                        {
                                        !isEmpty(careers?.careers_list) ?
                                        careers?.careers_list?.map((res, index) => (
                                            <div className='career-box' key={index + 'net'}>
                                                <div>
                                                    {res.title && <h2>{res?.title}</h2>}
                                                    {res.qualification && <h2>Qualification : <span>{res?.qualification}</span></h2>}
                                                    {res.experience && <h2>Experience : <span> {res?.experience}</span></h2>}
                                                    {res.description && <p>{res?.description}</p>}
                                                </div>
                                                <button onClick={() => setEnablePopup(res?.id)} >Apply Now</button>
                                            </div>
                                        ))
                                        :
                                        careers?.careers_list && 'No Career List Found!'
                                        }
                                    </div>
                        
                                    {careers?.careers_list && careers?.careers_total_count != careers?.careers_list?.length  &&
                                    <button className='load-more-career' onClick={() => (getCareerList(searchKey,page+1))} >Load More..</button>
                                    }
                                </div>
                            </div>
                        </div>
                    
                </div>
                }
            </div>
        </div>

        <Dialog className='wid-res'
			visible={enablePopup} style={{ width: '80%', height: '550px',borderRadius:'8px',overflow:"hidden" }}
			onHide={() => dispatch(setEnablePopup(null))} dismissableMask={true} hide={() => dispatch(setEnablePopup(null))}>
            <CareerAdd careerInfo={careers?.careers_list?.find(v => v?.id == enablePopup ) } careerId={enablePopup} hidePopup={() => dispatch(setEnablePopup(null))}/>
        </Dialog>
    </div>
  )
}

export default Careers