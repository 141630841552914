import React, { useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import logo from '../../../assets/logo-mini.png'
import qr from '../../../assets/qr.png'
import './DigitalCard.scss'

function DigitalCard() {
	const { userProfile } = useSelector(state => state.header)
	const [loading, setLoading] = useState(false)
	const componentRef = useRef(null)

	const handleOnBeforeGetContent = useCallback(() => {
		setLoading(true)
		return new Promise(resolve => {
			onBeforeGetContentResolve.current = resolve

			setTimeout(() => {
				setLoading(false)
				resolve()
			}, 2000)
		})
	}, [setLoading])

	const handleAfterPrint = useCallback(() => {
		// /console.log('`onAfterPrint` called') // tslint:disable-line no-console
	}, [])

	const handleBeforePrint = useCallback(() => {
		// console.log('`onBeforePrint` called') // tslint:disable-line no-console
	}, [])

	const reactToPrintContent = useCallback(() => {
		return componentRef.current
	}, [componentRef.current])

	// const handlePrint = useReactToPrint({
		
	// });
	

	const handlePrint = useReactToPrint({
			content: () => componentRef.current,
			documentTitle: 'BMJH_CustomerProfile',
			// onBeforeGetContent: handleOnBeforeGetContent,
			// onBeforePrint: handleBeforePrint,
			// onAfterPrint: handleAfterPrint,
			removeAfterPrint: true,
			//pageStyle: pageStyle,
		})	
	

	return (
		<>
			{/* {loading && <p>Please wait</p>} */}
			<div className='digital-cards' >
				<>
					<div className='digital-card' ref={componentRef}>
						<div className='d__flex card-logos'>
							<img src={logo} alt='' />
							{/* <img src={qr} alt='' /> */}
						</div>
						<div className='d__flex gap-20 mt-20 card-image'>
							<img
								src={userProfile?.profile_image}
								alt={userProfile?.firstname}
							/>
							<div>
								<h1>
									{userProfile?.firstname} <span>{userProfile?.lastname}</span>
								</h1>
								<h3 className='m-0'>{userProfile?.phone}</h3>
							</div>
						</div>
						<div className='d__flex flex-w card-infos' >
							<div>
								<h6 >Full Name</h6>
								<p>
									{userProfile?.firstname + ' ' + userProfile?.lastname ||
										'Not Specified'}
								</p>
							</div>
							<div>
								<h6>Email ID</h6>
								<p>{userProfile?.email || 'Not Specified'}</p>
							</div>
							<div>
								<h6>Phone No.</h6>
								<p>{userProfile?.phone || 'Not Specified'}</p>
							</div>
							<div>
								<h6>Martial Status</h6>
								<p>{userProfile?.marital_status || 'Not Specified'}</p>
							</div>
							<div>
								<h6>Date of birth</h6>
								<p>{userProfile?.dob || 'Not Specified'}</p>
							</div>
							<div>
								<h6>Gender</h6>
								<p>{userProfile?.gender || 'Not Specified'}</p>
							</div>
							<div>
								<h6>Height</h6>
								<p>{userProfile?.height || 'Not Specified'}</p>
							</div>
							<div>
								<h6>Weight</h6>
								<p>{userProfile?.weight || 'Not Specified'}</p>
							</div>
							<div className='flex-1'>
								<h6>Address </h6>
								<p>{userProfile?.address || 'Not Specified'}</p>
							</div>
						</div>
					</div>
					<div className='print-card txt-center'>
						<button onClick={handlePrint}>Print</button>
					</div>
				</>
			</div>
		</>
	)
}

export default DigitalCard
