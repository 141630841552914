import axios from 'axios'
import {
	homePageData,
	categoriesList,
	subCategoriesList,
	facilityList,
} from 'store/categories'
import { enableToast } from 'store/header'

axios.defaults.baseURL = process.env.API_DOMAIN
// axios.defaults.headers['token'] = process.env.API_ACCESS_TOKEN
const vendor_id = process.env.API_VENDOR_ID
const customer_id = sessionStorage.getItem('customerID')
const token = sessionStorage.getItem('token')

export const getHomePageData = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/pims/v-1-0-1/ad-block/web-ad-blocks',
			{
				vendor_id,
				page: 'home',
				device_type: 'web',
				token: sessionStorage.getItem('token'),
				...payload,
			}
		)
		dispatch(homePageData(response?.data?.data))
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}
export const getCategoriesList = (payload) => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/pims/v-1-0-0/category/category-list',
			{ vendor_id, ...payload }
		)
		dispatch(categoriesList(response?.data?.data))
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}
export const getCategoryDetail = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/pims/v-1-0-0/category/category-details',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		return response?.data?.data
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}
export const getSubCategoriesList = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/pims/v-1-0-0/category/all-subcategory-list',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload
			}
		)
		dispatch(subCategoriesList(response?.data?.data))
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}

export const getFacilitiesList = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/facility/list', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			q: '',
			per_page: '100',
			page: '0',
			...payload,
		})
		if (payload?.return_type) {
			return response?.data?.data
		} else {
			dispatch(facilityList(response?.data?.data))
		}
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}
export const getFacilitiesDetail = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/facility/view', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		return response?.data?.data
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}
