import React from 'react';

import get from 'get-value';

function FooterICons(props) {
	const matchIcon = (iconType, iconColor, customIcon) => {
		switch (iconType) {
			case 'subscribe':
				return (
					<>
						<path d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="white"/>
						<path d="M34.3911 17L13 25.5543L20.9804 29.1631L32.2525 19.1386L22.2279 30.4106L25.8368 38.3857L34.3911 17Z" fill="url(#paint0_linear_788_5910)"/>
						<defs>
						<linearGradient id="paint0_linear_788_5910" x1="13.2369" y1="27.6929" x2="34.3911" y2="27.6929" gradientUnits="userSpaceOnUse">
						<stop stopColor="#F06426"/>
						<stop offset="1" stopColor="#E21E29"/>
						</linearGradient>
						</defs>
					</>
				)
			case 'news-letter':
				return (
					<>
                        <path d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="url(#paint0_linear_788_5892)"/>
                        <path d="M38.0846 22.25H31.7513V12.75H22.2513V22.25H15.918L27.0013 33.3333L38.0846 22.25ZM15.918 36.5V39.6667H38.0846V36.5H15.918Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_788_5892" x1="0.598101" y1="27" x2="54" y2="27" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F06426"/>
                        <stop offset="1" stopColor="#E21E29"/>
                        </linearGradient>
                        </defs>
					</>
				)
			case 'contact':
				return (
					<>
                        <path d="M1.50316 6.94746C1.15436 7.04712 0.837636 7.23613 0.584315 7.49578C0.330995 7.75543 0.149862 8.07673 0.0588389 8.42787C-0.0321839 8.77902 -0.0299413 9.14785 0.0653448 9.49787C0.160631 9.84788 0.345658 10.167 0.602117 10.4235L4.15628 13.9735V20.8339H11.024L14.6021 24.4068C14.794 24.6007 15.0224 24.7547 15.2742 24.8598C15.5259 24.9649 15.796 25.0191 16.0688 25.0193C16.2481 25.019 16.4266 24.9959 16.6 24.9506C16.951 24.8622 17.2726 24.6829 17.5322 24.4307C17.7919 24.1785 17.9805 23.8624 18.0792 23.5141L24.9896 0.0224609L1.50316 6.94746ZM2.08233 8.95059L19.8198 3.72142L6.2417 17.2777V13.111L2.08233 8.95059ZM16.0823 22.9339L11.8875 18.7506H7.72087L21.2969 5.186L16.0823 22.9339Z" fill={fill}/>
					</>
				)
            case 'call':
                return (
                    <>
                        <path d="M21.8751 10.9423V9.45899C21.8751 6.97258 20.8873 4.58801 19.1292 2.82986C17.371 1.0717 14.9865 0.0839844 12.5001 0.0839844C10.0137 0.0839844 7.62909 1.0717 5.87093 2.82986C4.11278 4.58801 3.12506 6.97258 3.12506 9.45899V10.9423C2.03252 11.4234 1.13839 12.2652 0.592375 13.3268C0.0463549 14.3883 -0.118418 15.6053 0.125641 16.7738C0.3697 17.9424 1.00778 18.9916 1.93305 19.7459C2.85832 20.5002 4.01463 20.9137 5.20839 20.9173H7.29173V10.5007H5.20839V9.45899C5.20839 7.52511 5.97662 5.67045 7.34407 4.303C8.71153 2.93554 10.5662 2.16732 12.5001 2.16732C14.4339 2.16732 16.2886 2.93554 17.656 4.303C19.0235 5.67045 19.7917 7.52511 19.7917 9.45899V10.5007H17.7084V18.834H13.5417V20.9173H19.7917C20.9855 20.9137 22.1418 20.5002 23.0671 19.7459C23.9923 18.9916 24.6304 17.9424 24.8745 16.7738C25.1185 15.6053 24.9538 14.3883 24.4077 13.3268C23.8617 12.2652 22.9676 11.4234 21.8751 10.9423ZM5.20839 18.834C4.37959 18.834 3.58474 18.5047 2.99869 17.9187C2.41263 17.3326 2.08339 16.5378 2.08339 15.709C2.08339 14.8802 2.41263 14.0853 2.99869 13.4993C3.58474 12.9132 4.37959 12.584 5.20839 12.584V18.834ZM19.7917 18.834V12.584C20.6205 12.584 21.4154 12.9132 22.0014 13.4993C22.5875 14.0853 22.9167 14.8802 22.9167 15.709C22.9167 16.5378 22.5875 17.3326 22.0014 17.9187C21.4154 18.5047 20.6205 18.834 19.7917 18.834Z" fill={fill}/>
                    </>
                )
            case 'location':
                return (
                    <>
                        <path d="M11.1677 25.0084L10.4385 24.3834C9.43333 23.5417 0.65625 15.9615 0.65625 10.4167C0.65625 7.62892 1.7637 4.95529 3.73499 2.98401C5.70627 1.01273 8.3799 -0.0947266 11.1677 -0.0947266C13.9555 -0.0947266 16.6292 1.01273 18.6004 2.98401C20.5717 4.95529 21.6792 7.62892 21.6792 10.4167C21.6792 15.9615 12.9021 23.5417 11.901 24.3876L11.1677 25.0084ZM11.1677 2.17819C8.98347 2.18067 6.88941 3.04946 5.34492 4.59394C3.80043 6.13843 2.93165 8.2325 2.92917 10.4167C2.92917 13.9001 8.32917 19.4646 11.1677 22.0115C14.0063 19.4636 19.4063 13.8959 19.4063 10.4167C19.4038 8.2325 18.535 6.13843 16.9905 4.59394C15.446 3.04946 13.3519 2.18067 11.1677 2.17819Z" fill={fill}/>
                        <path d="M11.1667 14.5833C10.3426 14.5833 9.537 14.339 8.85179 13.8811C8.16659 13.4233 7.63254 12.7725 7.31717 12.0112C7.00181 11.2498 6.91929 10.412 7.08006 9.60379C7.24083 8.79554 7.63767 8.05311 8.22039 7.47039C8.80311 6.88767 9.54554 6.49083 10.3538 6.33006C11.162 6.16929 11.9998 6.25181 12.7612 6.56717C13.5225 6.88254 14.1733 7.41659 14.6311 8.10179C15.089 8.787 15.3333 9.59258 15.3333 10.4167C15.3333 11.5217 14.8943 12.5815 14.1129 13.3629C13.3315 14.1443 12.2717 14.5833 11.1667 14.5833ZM11.1667 8.33334C10.7546 8.33334 10.3518 8.45552 10.0092 8.68444C9.66663 8.91336 9.3996 9.23873 9.24192 9.61941C9.08424 10.0001 9.04298 10.419 9.12337 10.8231C9.20375 11.2272 9.40217 11.5984 9.69353 11.8898C9.98489 12.1812 10.3561 12.3796 10.7602 12.46C11.1644 12.5404 11.5832 12.4991 11.9639 12.3414C12.3446 12.1837 12.67 11.9167 12.8989 11.5741C13.1278 11.2315 13.25 10.8287 13.25 10.4167C13.25 9.86413 13.0305 9.33423 12.6398 8.94353C12.2491 8.55283 11.7192 8.33334 11.1667 8.33334Z" fill={fill}/>
                    </>
                )
            case 'make-donation':
                return (
                    <>
	                    <path d="M15.1908 3.1665L25.9773 7.27328C26.4523 7.43161 26.9372 7.9363 27.4122 8.4113L31.0241 13.3097C31.6575 14.2597 31.8455 15.5165 31.3705 16.6248C30.5788 18.3665 28.3523 22.4634 28.3523 22.4634C28.3523 22.4634 29.4309 20.4446 24.8392 17.9113L25.6309 16.13L22.6622 13.4582H17.4174L20.584 18.2082L25.0372 20.5832C26.7788 21.3748 27.2142 23.2649 26.4226 24.6899C25.7892 25.9566 24.3741 26.4613 23.1075 25.828L12.3705 20.2863C11.7372 19.9696 11.2523 19.4748 10.9356 18.9998C9.82726 16.9415 7.28403 12.0431 5.54236 7.76807L15.1908 3.1665ZM28.7976 24.6899C29.5892 25.7983 30.084 27.0748 30.084 28.4998C30.084 31.9832 27.234 34.8332 23.7507 34.8332C20.2674 34.8332 17.4174 31.9832 17.4174 28.4998C17.4174 27.3915 17.734 26.4217 18.209 25.63L23.1075 28.005C24.5325 28.6384 26.1158 28.3316 27.0658 27.0649L28.7976 24.6899Z" fill="white"/>
                    </>
                )
			default:
				undefined
		}
	}

	const className = get(props, 'className', '')
	const iconType = get(props, 'iconType', '')
	const iconColor = get(props, 'iconColor', '#000')
	const customIcon = get(props, 'customIcon', '')
	const width = get(props, 'width', '20px')
	const height = get(props, 'height', '')
	const viewBox = get(props, 'viewBox', '0 0 24 24')
	const onClick = get(props, 'onClick', {})
	const strokeColor = get(props, 'strokeColor', '')
	const strokeWidth = get(props, 'strokeWidth', '')
	const fill = get(props, 'fillColor', 'none')
	return (
		<svg
			className={className}
			viewBox={viewBox}
			stroke={strokeColor}
			strokeWidth={strokeWidth}
			fill={fill}
			onClick={onClick}
			style={{ width: width, height: height }}>
			{matchIcon(iconType, iconColor, customIcon)}
		</svg>
	)
}

export { FooterICons };
export default FooterICons
