import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DateTime, Icons } from 'components'
import { FormProvider, useForm } from 'react-hook-form'

import { enableTimingPopup } from 'store/header'
import { getSubCategoriesList } from 'api/catalogService'
import { getDoctorsList,getRecentlyVisitedDoctors } from 'api/doctorService'
import { isEmpty, method } from 'lodash'
import './Appointments.scss'
import { getLanguages, getVisitors } from '../../../api/appointmentService'
import { Helmet } from 'react-helmet'
import { enableLocation } from '../../../store/header'
// import { disableLocation } from '../../../store/header'
import { getDoctorsListPaginated } from 'api/doctorService'


function Appointments(props) {
	const dispatch = useDispatch()

	let { type } = useParams()
	const searchInputRef = useRef(null)

	const methods = useForm()
	const [currentForm, setCurrentForm] = useState(1)
	const [visitors,setVisitors] = useState([])
	const [languages,setLanguages] = useState([])
	const [searchText, setSearchText] = useState([])
	const [specialitiesList, setSpecialitiesList] = useState([])
	const [getDoctorList, setDoctorList] = useState([])
	const location = useLocation()
	const navigate = useNavigate()
	
	const [values, setValues] = useState({
		prefer: 'Physical Hospital Visit',
		vaccinated: 'yes',
		specialty: '',
		doctor: '',
		doctorInfo: {},
	})

	const { enableAppointment,enableLocation:locationState } = useSelector(state => state?.header)
	const { subCategoriesList } = useSelector(state => state?.categories)
	const { doctorsList } = useSelector(state => state?.doctorsInfo)

	const { hospitalList, selectedHospital } = useSelector(
		state => state?.doctorsInfo
	)

	useEffect(() => {
		dispatch(getRecentlyVisitedDoctors({ hospital_id: selectedHospital }))
		selectedHospital && dispatch(getDoctorsList({ hospital_id: selectedHospital }))
	}, [selectedHospital])

	
	const watchPrefer = methods?.watch(['prefer'])
	const watchSpeciality = methods?.watch(['speciality'])
	const watchVaccination = methods?.watch(['vaccination'])


	const collectVisitors = async () => {
		const response = await dispatch(getVisitors({}))
		if(props?.isFromDoctor && enableAppointment?.visitor_type_id) {
				let t = []
				response?.map(r => {
					if(enableAppointment?.visitor_type_id?.includes(r?.id.toString())){
						t.push(r)
					}
				})
			setVisitors(t)
			
			setValues({...values,...{ specialty: enableAppointment?.specialities?.[0]?.id },}),
			methods.setValue('speciality', enableAppointment?.specialities?.[0]?.id)

		} else {
			setVisitors(response)
		}
	}

	const collectLanguages = async () => {
		const response = await dispatch(getLanguages({}))
		setLanguages(response)
	}

	useEffect(()=> {
		collectVisitors();
		collectLanguages();
		return () =>{
			dispatch(enableLocation({status : true}))
		}
	}, [])

	// const prefers = [
	// 	{ option: 'Physical Hospital Visit', image: physical, id: 1 },
	// 	{ option: 'Video Consultation', image: video, id: 2 },
	// ]

	const vaccinated = [
		{ option: 'yes', id: 'yes' },
		{ option: 'no', id: 'no' },
		{ option: 'partially', id: 'partially' },
	]

	const genderList = [
		{ name: 'Male', code: 'male',id:1 },
		{ name: 'Female', code: 'female',id:2 },
		{ name: 'Transgender', code: 'transgender',id:3 },
	]

	// const languageList = [
	// 	{ name: 'English', code: 'english' },
	// 	{ name: 'Kannada', code: 'kannada' },
	// 	{ name: 'Hindi', code: 'hindi' },
	// 	{ name: 'Tamil', code: 'tamil' },
	// 	{ name: 'Telugu', code: 'telugu' },
	// 	{ name: 'Malayalam', code: 'malayalam' },
	// ]
	
	const availabilityList = [
		{ name: 'Available', code: '1' },
		{ name: 'Not Available', code: '0' },
	]

	const reduxValues = useSelector(state => {
		return state
	})


	useEffect(() => {
		if (isEmpty(subCategoriesList)) {
			selectedHospital && dispatch(getSubCategoriesList({hospital_id:selectedHospital}))
		} else {
			selectedHospital && setSpecialitiesList(subCategoriesList)
		}

		// if (isEmpty(doctorsList)) {
			// selectedHospital &&	dispatch(getDoctorsList({ hospital_id: selectedHospital }))
		// } else {
		// 	setDoctorList(doctorsList?.list)
		// }

		let specialityId = location?.state?.specialityId

		if(specialityId){
			setValues({...values,...{ specialty: specialityId },}),
			methods.setValue('speciality', specialityId)
			// methods.setValue('filterSpeciality', specialityId)
			handleDoctorFilter()
		}
		
		// return () => {
		// 	dispatch(disableLocation());
		// }
	}, [subCategoriesList, doctorsList, selectedHospital])

	// useEffect(() => {
	// 	let specialities = methods.getValues('filterSpeciality')?.toString()
	// 	let availability = methods.getValues('filterAvailability')
	// 	let language = methods.getValues('filterLanguage')?.toLowerCase()
	// 	let gender = methods.getValues('filterGender')?.toLowerCase()
	// },[selectedHospital])
	

	const handleSpecFilter = () => {
		setSearchText(searchInputRef?.current?.value)
		if (searchInputRef?.current?.value !== '') {
			let getFilterList = subCategoriesList?.filter(f =>
				f?.name
					?.toLowerCase()
					?.includes(searchInputRef?.current?.value?.toLowerCase())
			)
			console.log("filtered list",getFilterList)
			setSpecialitiesList(getFilterList)
		} else {
			setSpecialitiesList(subCategoriesList)
		}
	}

	// useEffect(() =>{
	// 	let specialities = methods.getValues('speciality')?.toString()
	// 	if (specialities) {
	// 		let filterData = doctorsList?.list
	// 		filterData = filterData?.filter(f =>
	// 			f?.specialities_id?.includes(specialities)
	// 		)
	// 		setDoctorList(filterData)
	// 	}
	// },[subCategoriesList,doctorsList])


	const handleDoctorFilter = async e => {

		let specialities = methods.getValues('speciality')?.toString()
		let availability = methods.getValues('filterAvailability')
		let language = methods.getValues('filterLanguage')
		let visitor_type = parseInt(watchPrefer?.join())
		let gender = methods.getValues('filterGender')?.toLowerCase()

		// let filterData = doctorsList?.list
		let filterData = []
		if(selectedHospital && specialities && visitor_type ){
			const response = await dispatch(getDoctorsListPaginated({ hospital_id: selectedHospital,specialitie_id:specialities,visitor_type_id:visitor_type.toString(),language_id:language!=''?language:undefined,gender:gender}))
			filterData = response?.list
		}
		//check language 
		//availablity
		//gender
		


		// hospital_id:1
		// page:0
		// //specialitie_id:2
		// //visitor_type_id:2
		// //language_id:2
		// availablity:2022-07-01
		// //covid_status:
		// //gender:male

		

		// if (specialities) {
		// 	filterData = filterData?.filter(f =>
		// 		f?.specialities_id?.includes(specialities)
		// 	)
		// }

		// if (availability) {
			
		// }

		if (availability) {
			filterData = filterData?.filter(
				f => f?.status?.toLowerCase() == availability
			)
		}

		// if (language) {
		// 	filterData = filterData?.filter(f => f.language_names?.includes(language))
		// 	// filterData = filterData?.filter(f => f?.language_name?.toLowerCase() == language)
		// }

		// if (gender) {
		// 	filterData = filterData?.filter(f => f?.gender?.toLowerCase() == gender)
		// }

		// if(visitor_type){
		// 	filterData = filterData?.filter(f => f.visitor_type_id?.includes(visitor_type.toString()))
		// }

		// if(watchPrefer){
		// 	filterData = filterData?.filter(f => f.visitor_type_id?.includes(watchPrefer.toString()))
		// }

		filterData = filterData?.filter(f => f?.book_appointment == 1)


		setDoctorList(filterData)

	}

	const dropChange = (e) => {
		if(e.target.value || e.target.value  == ''){
			handleDoctorFilter(e)
		}
	}

	const [temp,setTemp] = useState(undefined);

	useEffect(() => {
		if(watchPrefer[0] && parseInt(temp?.join()) != parseInt(watchPrefer?.join())){
			setTemp(watchPrefer)
			handleDoctorFilter()

		}
	},[watchPrefer])


	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSpecFilter()
			// if(e?.target?.value == ''){
			// 	handleSpecFilter()
			// 	setSearchText(e?.target?.value)
			// } else {

			// }
		}
	}

	return (
		<div className='container'>
			<Helmet>
				<title>BMJH | Book Appointment</title>
			</Helmet>
			<div className='appointments'>
				<h2>Book Appointment</h2>
				<FormProvider {...methods}>
					<div className='appointment-ctr'>
						<div className={`appointment-1 ${ currentForm == 1 ? 'show-form' : 'hide-form' }`}>
							{props?.isFromDoctor && !props?.isFromDoctorDetail && (
								<div className='doctors-list d__flex flex-w gap-15'>
									<div className={`doctors-box d__flex align-start flex-unset active-doctors-box`}>
										<div className='doctor-image'>
											<img src={enableAppointment?.profile_image} alt='' />
										</div>
										<div className='doctor-info'>
											<h5 className='m-0 doctor-name'>{`${enableAppointment?.firstname} ${enableAppointment?.lastname}`}</h5>
											<p className='m-0 doctor-dept'>
												<span>{enableAppointment?.specialities_names}</span> |{' '}
												{/* <span>{enableAppointment?.experience} Years Exp.</span> */}
											</p>
											<p className='m-0 doctor-address'>
												{ hospitalList?.find(f => f?.id == selectedHospital) ?.name }
											</p>
											<p className='m-0 doctor-languages'>
												<span> {enableAppointment?.language_names?.map((r,i)=> <span key={'LNGS'+i}>{r} {enableAppointment?.language_names?.length - 1 > i && ', '} </span>)}</span>
											</p>
											<p className='m-0 doctor-fee'>
												Doctor Fee:{' '}
												<span>INR {enableAppointment?.doctor_fees}</span>
											</p>
										</div>
									</div>
								</div>
							)}

							<div className={`${ type == 'specialty' || props?.isFromDoctor ? 'show-form' : 'hide-form'}`}>
								{props?.isFromDoctor && <h5>Speciality</h5>}
								<div className='d__flex flex-w specialties'>
									{subCategoriesList?.map((res,index) => {
										if(res.book_appointment == 1 && enableAppointment?.specialities_id?.includes(res?.id?.toString())){
											return (
												<label key={'APP'+index} >
											<div
												className={`radio-item ${
													watchSpeciality == res?.id ? 'active-radio' : ''
												}`}>
												<input
													className='p-hidden'
													{...methods?.register('speciality')}
													type='radio'
													value={res?.id}
												/>
												<img src={res?.image} height='20px' width={'auto'} onError={((e) => { (e.target.src = require('../../../assets/default/Specialties-list.png') )})} alt=''/>
												{/* <Icons
													name='specialty'
													iconType={res?.name}
													width={props?.isFromDoctor ? '50' : '90'}
													height={props?.isFromDoctor ? '50' : '90'}
													viewBox='0 0 90 90'
													fillColor='#4C4372'
												/> */}
												<p>{res?.name}</p>
											</div>
										</label>
											)
										}
									})}
								</div>
							</div>

							<div className='option-item'>
								<h5>What would you prefer?</h5>
								<div className='d__flex gap-15'>
									{visitors?.map((res,index) => (
										<label key={index+'PFF'}>
											<div
												className={`radio-item ${
													watchPrefer == res?.id ? 'active-radio' : ''
												}`}>
												<input
													className='p-hidden'
													{...methods?.register('prefer', { value: res?.id })}
													type='radio'
													value={res?.id}
												/>
												<img src={watchPrefer == res?.id ? res?.selected_image : res?.unselected_image} alt={res?.option} />
												<p>{res?.name}</p>
											</div>
										</label>
									))}
								</div>
							</div>

							<div className='option-item'>
								<h5>Has the patient taken the Covid-19 vaccination?</h5>
								<div className='d__flex gap-15'>
									{vaccinated?.map((res,index) => (
										<label key={index+"COV"}>
											<div
												className={`radio-item ${
													watchVaccination == res?.id ? 'active-radio' : ''
												}`}>
												<span className='checkmark'></span>
												<input
													className='p-hidden'
													{...methods?.register('vaccination', { value: 'yes' })}
													type='radio'
													value={res?.id}
												/>
												<p>{res?.option}</p>
											</div>
										</label>
									))}
								</div>
							</div>

							<div
								className={`option-item ${
									type != 'specialty' && !props?.isFromDoctor
										? 'show-form'
										: 'hide-form'
								}`}>
								<h5>Select Specialty</h5>
								<div className='d__flex just-c'>
									<div className='search-inputt'>
										<input
											ref={searchInputRef}
											placeholder='Search Speciality'
											value={searchText}
											onChange={e => {
												e?.target?.value == '' && handleSpecFilter(),
													setSearchText(e?.target?.value)
											}}
											onKeyPress={handleKeyDown}
										/>
										<i
											onClick={() => {
												setSearchText(''),
													(searchInputRef.current.value = ''),
													handleSpecFilter()
											}}
											className={`reset-search-inp pi pi-times ${
												searchText?.length > 0 ? '' : 'p-hidden'
											}`}
										/>
										<button onClick={handleSpecFilter}>Search</button>
									</div>
								</div>
								<div className='d__flex flex-w specialties'>
									{!isEmpty(specialitiesList) ? (
										specialitiesList?.map((res,index) => (
											<div key={index+"SPC"}
												className={`specialty ${
													res.name == values.specialty
														? 'active-specialty'
														: ''
												}`}
												onClick={() => (
													res?.name_slug == 'preventive-healthcare-checkup-services' || res?.name_slug =='preventive-health-checkup-services' ? navigate('/medical-packages') : null,
													setValues({
														...values,
														...{ specialty: res?.id },
													}),
													setCurrentForm(2),
													methods.setValue('speciality', res?.id),
													handleDoctorFilter(),
													dispatch(enableLocation({status : false})),
													(window.scrollTo(0,200))
												)}>
													<img src={res?.image} height='100px' width={'auto'} onError={((e) => { (e.target.src = require('../../../assets/default/Specialties-list.png') )})} alt=''/>
												{/* <Icons
													name='specialty'
													iconType={res.icon}
													width='90'
													height='90'
													viewBox='0 0 90 90'
													fillColor='#4C4372'
												/> */}
												<p>{res.name}</p>
											</div>
										))
									) : (
										<p>No results found</p>
									)}
								</div>
							</div>
						</div>

						<div className={`appointment-2 ${ (currentForm == 2 || type == 'specialty') && !props?.isFromDoctor ? 'show-form' : 'hide-form'} `}>
							<div className='doctor-filters'>
								<h5>Filter</h5>
					
								<div className='d__flex'>
									{(currentForm == 2 || type == 'specialty') && <select value={methods.getValues('speciality')}  {...methods?.register('speciality', { onChange: e => dropChange(e)})}>
										{
											subCategoriesList.map((v,i) => 
												<option key={i+'SCL'} value={v.id}>{v.name}</option>	
											)
										}
									</select>}

									{/* 
									<Dropdown
										value={methods.getValues('filterSpeciality')}
										options={subCategoriesList}
										optionLabel='name'
										optionValue='id'
										placeholder='Specialties'
										className='mr-30'
										{...methods?.register('filterSpeciality', { onChange: e => handleDoctorFilter(e)})}
									/> */}

									<select value={methods.getValues('filterAvailability')}  {...methods?.register('filterAvailability', { onChange: e => dropChange(e)})}>
										<option value={''}>Availability</option>
										{
											availabilityList.map((v,i) => 
												<option key={i+'FAL'} value={v.code}>{v.name}</option>	
											)
										}
									</select>

									{/* <Dropdown
										value={methods.getValues('filterAvailability')}
										options={availabilityList}
										optionLabel='name'
										optionValue='code'
										placeholder='Availability'
										className='mr-30'
										{...methods?.register('filterAvailability', {
											onChange: e => handleDoctorFilter(e),
										})}
									/> */}

									<select value={methods.getValues('filterLanguage')}  {...methods?.register('filterLanguage', { onChange: e => dropChange(e)})}>
										<option value={''}>Languages</option>
										{
											languages?.map((v,i) => 
												<option key={i+'FLE'} value={v.id}>{v.name}</option>	
											)
										}
									</select>

									{/* <Dropdown
										value={methods.getValues('filterLanguage')}
										options={languageList}
										optionLabel='name'
										optionValue='code'
										placeholder='Language'
										className='mr-30'
										{...methods?.register('filterLanguage', {
											onChange: e => handleDoctorFilter(e),
										})}
									/> */}

									<select value={methods.getValues('filterGender')}  {...methods?.register('filterGender', { onChange: e => dropChange(e)})}>
										<option value={''}>Genders</option>
										{
											genderList.map((v,i) => 
												<option key={i+'GL'} value={v.code}>{v.name}</option>	
											)
										}
									</select>

									{/* <Dropdown
										value={methods.getValues('filterGender')}
										options={genderList}
										optionLabel='name'
										optionValue='code'
										placeholder='Gender'
										className='mr-30'
										{...methods?.register('filterGender', {
											onChange: e => handleDoctorFilter(e),
										})}
									/> */}
								</div>
							</div>

							<div>
								<h5>Select Doctors</h5>
								<div className='doctors-list d__flex flex-w gap-15'>
									{!isEmpty(getDoctorList) ? (
										getDoctorList?.map((res,index) => (
											<div key={"DOc"+index}
												className={`doctors-box d__flex align-start ${
													res?.doctor_id == values.doctor
														? 'active-doctors-box'
														: null
												}`}
												onClick={() => (
													setValues({
														...values,
														...{ doctor: res?.doctor_id, doctorInfo: res },
													}),
													dispatch(enableTimingPopup(true))
												)}>
												<div className='doctor-image'>
													<img src={res?.profile_image} alt='' />
												</div>
												<div className='doctor-info'>
													<h5 className='m-0 doctor-name'>
														{res?.firstname + ' ' + res?.lastname}
													</h5>
													<p className='m-0 doctor-dept'>
														<span>{res?.specialities_names}</span>
														{/* | */}
														{/* <span>{res?.experience} Years Exp.</span> */}
													</p>
													<p className='m-0 doctor-languages'>
														{/* <span>{res?.gender}</span> | */}
														<span> {res?.language_names.map((r,i)=> <span index={'LNGS'}>{r} {res?.language_names?.length - 1 > i && ', '} </span>)}</span>
													</p>
													{/* <p className='m-0 doctor-fee'>
														Doctor Fee: <span>INR {res?.doctor_fees}</span>
													</p> */}
												</div>
											</div>
										))
									) : (
										<p>No results found</p>
									)}
								</div>
							</div>
						</div>

						<div
							className={`change-buttons d__flex  ${
								currentForm == 1 ? 'just-end' : 'just-start'
							}`}>
							<button
								className={` ${currentForm == 2 ? 'show-form' : 'hide-form'}`}
								onClick={() => (setCurrentForm(1),dispatch(enableLocation({status : true})),(window.scrollTo(0,200)) )}>
								Previous
							</button>
							<></>
							{/* <button
								className={` ${
									currentForm == 1 &&
									!props?.isFromDoctor &&
									type != 'specialty'
										? 'show-form'
										: 'hide-form'
								}`}
								onClick={() => (setCurrentForm(2),dispatch(enableLocation({status:false})), (window.scrollTo(0,200)))}>
								Continue
							</button> */}
						</div>
					</div>

					{props?.isFromDoctor && (
						<DateTime 
							doctorId={enableAppointment?.doctor_id}
							visitor_type_id={parseInt(watchPrefer?.join())}
							speciality_id={parseInt(watchSpeciality?.join())}
							doctorInfo={enableAppointment}
							covid_info={methods.getValues('vaccination')}
						/>
					)}
				</FormProvider>
				<Dialog
					className='wid-res appointment-datetime'
					visible={reduxValues.header.enableTimingPopup}
					// style={{ width: '80%', height: '1000px' }}
					onHide={() => dispatch(enableTimingPopup(false))}
					dismissableMask={true}>
					<DateTime
						doctorId={values?.doctor}
						visitor_type_id={parseInt(watchPrefer?.join())}
						speciality_id={methods.getValues('speciality')}
						covid_info={methods.getValues('vaccination')}
						doctorInfo={values?.doctorInfo
							// !isEmpty(enableAppointment)
							// 	? enableAppointment
							// 	: values?.doctorInfo
						}
					/>
				</Dialog>
			</div>
		</div>
	)
}

export default Appointments
