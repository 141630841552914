
import React, { useRef, useState } from 'react';
import { isEmpty, clone, flatten, uniqBy } from 'lodash'
import {
  Icons,
  TitleCard,
} from 'components';
import './Testimonials.scss';

import { SwiperSlide }  from "swiper/react";

import TestimonialCard from '../TestimonialCard/TestimonialCard';
import Carousel from '../../Carousel/Carousel';
import { Link } from 'react-router-dom';

function Testimonials({title,data,isFromDoctor}) {
   var testimonial;

    if(!isFromDoctor) {
      testimonial = !isEmpty(data?.placeholders) ? data?.placeholders[0]?.customer_reviews : []
    } else {
      testimonial = data
    }

    let testimonialVideo = []
		let testimonialNonVideo = []

    testimonial.map(res => {
      (res.youtube_link && res.youtube_link != '') ? testimonialVideo.push(res) : testimonialNonVideo.push(res)
   })


    const breakpoints = {
      "1720": {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      "1366": {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      "1025": {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      "768": {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      "0": {
        slidesPerView: 1,
      },
    };

  
  return (
    <section className='bg-map'>
      <div className='testimonials container'>
          <div className='d__flex just-s'>
            <span></span>
            <TitleCard  title={title}/>
            <div className='text-right'>
            <Link to={'/testimonials'}>
              <button className='testimonial-view-more'>View All</button>
            </Link>
            </div>
          </div>
          <div className='d__flex gap-15'>
            <div className='images-ctr'>
              <div>
                <Carousel loop={true} breakpoints={breakpoints} autoplay={{delay: 5000,}}>
                  {
                    testimonialVideo?.map((res,index) => {
                      return (
                        <SwiperSlide className='bg-sha' key={index+"TM"}>
                          <TestimonialCard value={res}/>
                        </SwiperSlide>
                      )
                    })
                  }
                </Carousel>
              </div>
            </div>
            <div className='images-ctr'>
              <div>
                <Carousel loop={true} breakpoints={breakpoints} autoplay={{delay: 5000,}}>
                  {
                    testimonialNonVideo?.map((res,index) => {
                      return (
                      <SwiperSlide className='bg-sha' key={index+"TM"}>
                        <TestimonialCard value={res}/>
                      </SwiperSlide>
                      )
                    })
                  }
                </Carousel>
              </div>
            </div>
          </div>
      </div>
    </section>
  )
}

export default Testimonials
export { Testimonials };
