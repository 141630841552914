import axios from 'axios'
import { recentlyVisited, doctorsList, hospitalList } from 'store/doctors'
import { enableToast } from 'store/header'
import { enableLoader } from '../store/header'

axios.defaults.baseURL = process.env.API_DOMAIN
const vendor_id = process.env.API_VENDOR_ID
const customer_id = sessionStorage.getItem('customerID')
const token = sessionStorage.getItem('token')

export const getHospitalsList = () => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/hospital/list', {
			vendor_id,
		})
		dispatch(hospitalList(response?.data?.data))
	} catch (e) {
		return dispatch(enableToast(response?.data?.message))
	}
}

export const getRecentlyVisitedDoctors = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/crm/v-1-0-0/doctor/recently-visited-doctors',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return dispatch(recentlyVisited(response?.data?.data))
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getDoctorsListPaginated = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/doctor/list', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getDoctorsList = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/doctor/list', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return dispatch(doctorsList(response?.data?.data))
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getDoctorDetail = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/doctor/details', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const findDoctors = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/crm/v-1-0-0/doctor/search', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const findDoctorsWithNode = payload => async dispatch => {
	try {

		const axiosInstance = axios.create({
			baseURL: process.env.NODE_API
		});

		let response = await axiosInstance.post('/doctor/search', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})

		// console.log("response calling", response?.data?.data)
		if (response?.status == 200) {
			dispatch(enableLoader(false))
			return response?.data?.data
		} else if (response?.status == 400) {
			dispatch(enableLoader(false))
			//return dispatch(enableToast(response?.data))
		} else {
			dispatch(enableLoader(false))
			return dispatch(
				enableToast({ http_code: response?.status, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		dispatch(enableLoader(false))
		console.log(e)
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getMedicalPackageList = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/pims/v-1-0-0/package/get-packages',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				device_type: 'web',
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data?.packages
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const getMedicalPackageDetail = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/pims/v-1-0-0/package/get-packages-details',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				device_type: 'web',
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
