import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'primereact/carousel'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { isEmpty } from 'lodash'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import doctor from '../../../../assets/Home/doctor.png'
import heart from '../../../../assets/Home/heart.png'
import slider1 from '../../../../assets/sliders/slider1.png'
import slider2 from '../../../../assets/sliders/slider2.png'
import bgdot from '../../../../assets/Home/bg12.png'
import { Icons } from '../../../components'
import './HomeCarousel.scss'

function HomeCarousel(props) {
	const searchInputRef = useRef(null)
	const navigate = useNavigate()
	const products = [
		{ image: slider1 },
		{ image: slider2 },
		{ image: slider1 },
		{ image: slider2 },
	]

	const responsiveOptions = [
		{
			breakpoint: '1024px',
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: '600px',
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: '480px',
			numVisible: 1,
			numScroll: 1,
		},
	]
	const goToSearch = () =>
		navigate({
			pathname: '/findDoctor',
			search: `?search=${searchInputRef?.current?.value}`,
		})


		const handleKeyDown = (event) => {
			if (event.key === 'Enter') {
				navigate({
					pathname: '/findDoctor',
					search: `?search=${searchInputRef?.current?.value}`,
				})
			}
		}


	const dispatch = useDispatch()
	const [carouselData, setCarouselData] = useState([])
	const [location, setLocation] = useState()
	const { hospitalList, selectedHospital } = useSelector(
		state => state.doctorsInfo
	)
	const optionsList = hospitalList?.map(l => ({
		name: l?.name,
		id: l?.id,
	}))

	useEffect(() => {
		setLocation(selectedHospital)
		const getBanners = !isEmpty(props?.data?.placeholders)
			? props?.data?.placeholders[0].banners
			: []
		setCarouselData(Object.values(getBanners))
	}, [selectedHospital, props])

	const { staticInfos  } = useSelector( state => state.header)

	const sliderImages = item => {
		// console.log(item)
		return (
			<Link to={item?.link != '0' ? item?.link  : "#" } className='d__flex align-start'>
				<div className='image-wrapper test'>
					<img
						src={item?.ban}
						alt=''
						onError={({ currentTarget }) => {
							currentTarget.onerror = null
							currentTarget.src = slider1
						}}
					/>
				</div>
			</Link>
		)
	}

	// const content = [Bengaluru's/n"High-Tech - No Frills"/nSuper Speciality Hospital]

	return (
		<div className='carousel d__flex container'>
			<div className='d__flex slider align-start flex-1'>
				<div className='slider-left-overlay'>
					<div className='content-wrapper'>
						<h1>
							{
								carouselData[0]?.head?.split('/n')?.map((val,index) =>
								<span key={index+"HD"}>{val}</span> 
								)
							}
						 </h1>
						{/* <p className='header-sli-p'>{item?.sub}</p> */}
					</div>
					<div className='search-input d__flex m-d-flex'>
						{/* <Icons
							className='location-icon  web-location-icon'
							name='home'
							iconType='location-filled'
							width='18'
							height='26'
							viewBox='0 0 18 26'
						/>
						<Dropdown
							optionLabel='name'
							value={location}
							optionValue='id'
							options={optionsList}
							onChange={e => dispatch(setSelectedHospital(e?.value))}
							placeholder='Select a Hospital'
							className='location-web'
						/> */}

						<input
							ref={searchInputRef}
							type={'text'}
							placeholder='Search for Specialties or Doctors'
							onKeyPress={handleKeyDown}
						/>
						<button className='search-icon' onClick={goToSearch}>
							<Icons
								name='home'
								iconType='search'
								width='26'
								height='26'
								viewBox='0 0 26 26'
							/>
						</button>
					</div>
					<div className='d__flex buttons m-d-flex'>
						{ selectedHospital == 1 && <a href={`${staticInfos?.['360_view']?.['value']}`} target='_blsnk'>
						
						<button className='d__flex gap-20  m-d-flex'>
							<div>
								<Icons
									name='home'
									iconType='virtual-tour'
									width='29'
									height='21'
									viewBox='0 0 29 21'
								/>
							</div>
							Virtual Tour
						</button>
						</a>}
						<a href={'https://meet-my-doctor.firebaseapp.com/#/app?eid=37642'} target='_blank'>
							<button className='d__flex gap-20  m-d-flex'>
								<div>
									<img src={require('../../../../assets/video-call.png')} alt='' />
								</div>
								Virtual Consultation
							</button>
						</a>
					</div>

					<div className='infos'>
						<div className='position-relative'>
							<div className='info-item'>
								<Link to={'/doctors'}>
									<img className='info-icon' src={doctor} alt='' />
									<h5>Find Doctors</h5>
									{/* <p>
										Globally standards for complaints in channels scalable
										benefits.
									</p> */}
								</Link>
							</div>

							<div className='info-item2'>
								<div className='info-item'>
									<Link to={'/appointment/normal'}>
										<Icons
											className='info-icon wid-50'
											name='home'
											iconType='appointment-calendar'
											width='50'
											height='50'
											viewBox='0 0 50 50'
										/>
										<h5>Book Appointment</h5>
										{/* <p>
											Globally standards for compliants in channels scalable
											benefits.
										</p> */}
									</Link>
								</div>

								<div className='info-item info-item2'>
									<Link to={'/specialties '}>
										<img className='info-icon' src={heart} alt='' />
										<h5>Specialties</h5>
										{/* <p>
											Globally standards for compliants in channels scalable
											benefits.
										</p> */}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Carousel
					value={carouselData}
					autoplayInterval={7000}
					responsiveOptions={responsiveOptions}
					circular={true}
					numVisible={1}
					numScroll={1}
					itemTemplate={sliderImages}
				/>
			</div>
			<img className='info-icon bg-dot' src={bgdot} alt='' />
		</div>
	)
}

export default HomeCarousel
export { HomeCarousel }
