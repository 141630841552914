import React, { useEffect, useRef, useState } from 'react'
import { getQuestions, setFeedbackUser, setQuestions } from '../../../api/cmsService';
import { useDispatch, useSelector } from 'react-redux';
import './Questionary.scss'
import { Icons } from 'components';
import { enableLoginModal as setLoginModal } from 'store/header'
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import 'yup-phone'
import { yupResolver } from '@hookform/resolvers/yup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { enableToast } from '../../../store/header';


function Questionary() {
    const dispatch = useDispatch();
    const [info,setInfo] =  useState([]);
    const { selectedHospital } = useSelector(state => state?.doctorsInfo)
    const getDetailData = async (custom) => {
        const response = await dispatch(getQuestions({hospital_id:selectedHospital}))
        setInfo(response)
        // console.log(response)
    } 

    useEffect(() => {
        selectedHospital && getDetailData()
        selectedHospital &&  (setInfo([]),setSelected([]),reset(),setId(undefined))
    },[selectedHospital])

    const [selected,setSelected] = useState([])

    const [id,setId] = useState(undefined)



    // master_feedback_id

    // "hospital_id":"1","customer_id":"69", "user_name":"jayanth", "user_contact":"9999999999", "user_email":"test@123.com", "user_type":"guest"

    // useEffect(() => {
    //     if(selected?.selected_q1 && selected?.selected_q2){
    //         storeData(selected)
    //     }
    // },[selected])

    const schema = yup.object({
        user_name: yup.string().required('Enter Your Name'),
        user_email : yup.string().email("Not a Valid Email").required("Enter your Email"),
        user_contact	:  yup.string().phone('IN', true, 'Invalid mobile number'),
    })

    const { userProfile } = useSelector(state => state.header)
    const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		getValues,
		setValue,
		watch,
		clearErrors,
		setError
	} = useForm({
		// mode: 'onBlur',
		// reValidateMode : 'onChange',
		resolver: yupResolver(schema),
	})

    const [comment,setComments] = useState('')
    
    const onSubmit = async (data) => {
        const response = await dispatch(setFeedbackUser({hospital_id:selectedHospital,...data,"user_type":"guest"}))
        // console.log(response)
        setId(response.id)
    } 
    let textRef = useRef(null)

    const [spinner,setSpinner] = useState(false)

    const storeData = async () => {
        // console.log(selected)

        if(id){
            if(selected?.length  == info?.count){
                setSpinner(true)
                selected.map( async v => {
                    const response = await dispatch(setQuestions({hospital_id:selectedHospital,...v,feedbackq_id:v?.id,master_feedback_id:id,user_feedback_message: comment}))
                    // console.log(response)
                })
                reset()
                setId(undefined)
                setSelected([])
                setComments('')
                dispatch(enableToast({http_code:200,message:"Feedback form submitted successfully!"}))
                setSpinner(false)
                
            } else {
                dispatch(enableToast({http_code:400,message:"Please Choose All Inputs"}))
            }

            
        } else {
            dispatch(enableToast({http_code:400,message:"Please submit the contact details info"}))
        }
     
    } 


    const changed = (i,questionInfo,answer) => {  

        
        // console.log("check changed calling",i,selected?.[i]?.id  ,questionInfo?.id)

        // if(selected?.[i]?.id && selected?.[i]?.id == questionInfo?.id){
        //     selected[i] = {...selected[i],...questionInfo,...answer}
            
        //     console.log("already added", selected)

        //     const y = selected?.filter(element => {
        //         return element?.id !== questionInfo?.id
        //     });

        //     let v = [...y,selected[i]]
        //     setSelected(v)
        // } else {

        let d = selected.find(v => v?.id == questionInfo.id)
        // console.log(d)
        if(!d){
            let v = [...selected,{...questionInfo,...answer}]
            setSelected(v)
        } else {
            // console.log("Selected",selected)
            let v = {...d,...questionInfo,...answer}
            // console.log(v)
            const x = selected.filter(element => {
                return element?.id != questionInfo.id
              });
            // console.log("remains",x)

            setSelected([...x,v])
        }

            
        // }
    }

   
    useEffect(() => {
        // console.log(selected)
    },[selected])


    const getActive  = (id) => {
        let d = selected.find(v => v?.id == id)
        return d
    }

  return (
    <div className='feedbacks mar-b-135 container '>
    <ConfirmDialog visible={spinner} header='Submitting' className='checkout-progress' icon='pi pi-spin pi-spinner' message='Please wait while we process your request' />
     {/* {userProfile?.phone ?  */}
     <div>

            {/* <pre>
                {JSON.parse(selected)}
            </pre> */}


        <div>
         <h3>Respected madam/ Sir,</h3>
           <p>
             We would love to hear what you think about us! Please take a few minutes to complete this form and tell us what we did well and what we need to do better. It will help us to continually improve our service to everyone we care for in the community.
            If you would like more information or have questions on how to complete the questionnaire, please contact our Quality cell Co-ordinator on (080) <a href='tel:45885555'>45885555</a> EXT: 4565 or email to <a href='mailto:qualitycelljain@gmail.com'>qualitycelljain@gmail.com</a>
           </p>
        </div>

        <div>
            If you would like our Hospital Representative to contact you about your below feedback, Kindly indicate your Contact Details below:
            <form className='text-boxes' onSubmit={handleSubmit(onSubmit)}>
                <div className='input-item'>
                    <label>Name</label>
                    <input type='text' {...register('user_name')} disabled={id}  />
                </div>
                <div className='input-item'>
                    <label>Contact Number</label>
                    <input type='text' pattern='\d*' {...register('user_contact')}  disabled={id}/>
                </div>
                <div className='input-item'>
                    <label>Email ID</label>
                    <input type='email' {...register('user_email')}  disabled={id}/>
                </div>
                <button disabled={id} className='conti'>continue</button>
            </form>
        </div>

        {info?.list?.map((q,i) => 
            <div key={i+'qUs'} className='questions'>
                <h2>{q?.title}</h2>
                <div >
                <p>{q?.q1}</p>
                    <div className='options'> 
                        {q?.q1o1 && q?.q1o1 != '' && <div className={`option ${getActive(q?.id)?.selected_q1 == q?.q1o1?'active':''}`} onClick={() => changed(i,q,{selected_q1 : q?.q1o1,q1_sel_option_id:'q1o1'})}>
                            <input id={q?.q1o1+i+'1'} type='checkbox' checked={getActive(q?.id)?.selected_q1 == q?.q1o1}  value={q?.q1o1} />
                            <label htmlFor={q?.q1o1+i+'1'}>{q?.q1o1}</label>
                        </div>}
                        {q?.q1o2 && q?.q1o2 != '' && <div  className={`option ${getActive(q?.id)?.selected_q1 == q?.q1o2?'active':''}`} onClick={() => changed(i,q,{selected_q1 : q?.q1o2,q1_sel_option_id:'q1o2'})} >
                            <input id={q?.q1o2+i+'2'} type='checkbox' checked={getActive(q?.id)?.selected_q1 == q?.q1o2} value={q?.q1o2} />
                            <label htmlFor={q?.q1o2+i+'2'}>{q?.q1o2}</label>
                        </div>}
                        {q?.q1o3 && q?.q1o3 != '' && <div  className={`option ${getActive(q?.id)?.selected_q1 == q?.q1o3?'active':''}`} onClick={() => changed(i,q,{selected_q1 : q?.q1o3,q1_sel_option_id:'q1o3'})} >
                            <input id={q?.q1o3+i+'3'} type='checkbox' checked={getActive(q?.id)?.selected_q1 == q?.q1o3} value={q?.q1o3} />
                            <label htmlFor={q?.q1o3+i+'3'}>{q?.q1o3}</label>
                        </div>}
                        {q?.q1o4 && q?.q1o4 != '' && <div  className={`option ${getActive(q?.id)?.selected_q1 == q?.q1o4?'active':''}`} onClick={() => changed(i,q,{selected_q1 : q?.q1o4,q1_sel_option_id:'q1o4'})} >
                            <input id={q?.q1o4+i+'4'}  type='checkbox' checked={getActive(q?.id)?.selected_q1 == q?.q1o4}  value={q?.q1o4}/>
                            <label htmlFor={q?.q1o4+i+'4'}>{q?.q1o4}</label>
                        </div>}
                    </div>
                </div>
                {q?.q2 && q?.q2 != '' && <div>
                <p>{q?.q2}</p>
                    <div className='options'>
                        {q?.q2o1 && q?.q2o1 != '' && <div className={`option ${getActive(q?.id)?.selected_q2 == q?.q2o1?'active':''}`} onClick={() => changed(i,q,{selected_q2 : q?.q2o1,q2_sel_option_id:'q2o1'})}>
                            <input id={q?.q2o1+i+'11'} type='checkbox' checked={getActive(q?.id)?.selected_q2 == q?.q2o1}  value={q?.q2o1} />
                            <label htmlFor={q?.q2o1+i+'11'}>{q?.q2o1}</label>
                        </div>}
                        {q?.q2o2 && q?.q2o2 != '' && <div className={`option ${getActive(q?.id)?.selected_q2 == q?.q2o2?'active':''}`}  onClick={() => changed(i,q,{selected_q2 : q?.q2o2,q2_sel_option_id:'q2o2'})} >
                            <input id={q?.q2o2+i+'22'}  type='checkbox' checked={getActive(q?.id)?.selected_q2 == q?.q2o2} value={q?.q2o2} />
                            <label htmlFor={q?.q2o2+i+'22'} >{q?.q2o2}</label>
                        </div>}
                        {q?.q2o3 && q?.q2o3 != '' && <div className={`option ${getActive(q?.id)?.selected_q2 == q?.q2o3?'active':''}`} onClick={() => changed(i,q,{selected_q2 : q?.q2o3,q2_sel_option_id:'q2o3'})} >
                            <input id={q?.q2o3+i+'33'} type='checkbox' checked={getActive(q?.id)?.selected_q2 == q?.q2o3} value={q?.q2o3} />
                            <label htmlFor={q?.q2o3+i+'33'}>{q?.q2o3}</label>
                        </div>}
                        {q?.q2o4 && q?.q2o4 != '' &&<div className={`option ${getActive(q?.id)?.selected_q2 == q?.q2o4?'active':''}`} onClick={() => changed(i,q,{selected_q2 : q?.q2o4,q2_sel_option_id:'q2o4'})}>
                            <input id={q?.q2o4+i+'44'} type='checkbox' checked={getActive(q?.id)?.selected_q2 == q?.q2o4}  value={q?.q2o4} />
                            <label htmlFor={q?.q2o4+i+'44'}>{q?.q2o4}</label>
                        </div>}
                    </div>
                </div>}
                {q?.q3 && q?.q3 != '' && <div>
                    <p>{q?.q3}</p>
                    <div  className='options'>
                         {q?.q3o1 && q?.q3o1 != '' &&<div  className={`option ${getActive(q?.id)?.selected_q3 == q?.q3o1?'active':''}`} onClick={() => changed(i,q,{selected_q3 : q?.q3o1,q3_sel_option_id:'q3o1'})}>
                            <input id={q?.q3o1+i+'111'} type='checkbox' checked={getActive(q?.id)?.selected_q3 == q?.q3o1}  value={q?.q3o1} />
                            <label htmlFor={q?.q3o1+i+'111'}>{q?.q3o1}</label>
                        </div>}
                        {q?.q3o2 && q?.q3o2 != '' && <div  className={`option ${getActive(q?.id)?.selected_q3 == q?.q3o2?'active':''}`}  onClick={() => changed(i,q,{selected_q3 : q?.q3o2,q3_sel_option_id:'q3o2'})}>
                            <input id={q?.q3o2+i+'222'} type='checkbox' checked={getActive(q?.id)?.selected_q3 == q?.q3o2} value={q?.q3o2} />
                            <label htmlFor={q?.q3o2+i+'222'}>{q?.q3o2}</label>
                        </div>}
                        {q?.q3o3 && q?.q3o3 != '' &&<div  className={`option ${getActive(q?.id)?.selected_q3 == q?.q3o3?'active':''}`} onClick={() => changed(i,q,{selected_q3 : q?.q3o3,q3_sel_option_id:'q3o3'})} >
                            <input id={q?.q3o3+i+'333'} type='checkbox' checked={getActive(q?.id)?.selected_q3 == q?.q3o3} value={q?.q3o3} />
                            <label htmlFor={q?.q3o3+i+'333'}>{q?.q3o3}</label>
                        </div>}
                        {q?.q3o4 && q?.q3o4 != '' && <div  className={`option ${getActive(q?.id)?.selected_q3 == q?.q3o4?'active':''}`} onClick={() => changed(i,q,{selected_q3 : q?.q3o4,q3_sel_option_id:'q3o4'})} >
                            <input id={q?.q3o4+i+'444'}  type='checkbox' checked={getActive(q?.id)?.selected_q3 == q?.q3o4} value={q?.q3o4} />
                            <label htmlFor={q?.q3o4+i+'444'}>{q?.q3o4}</label>
                        </div>}
                    </div>
                </div>}
            </div>    
        )}

            <div >
                <p>Please add any other Comments or Suggestions that you would like to make below:</p>
                <textarea rows={6} onChange={(e) => setComments(e.currentTarget.value)} value={comment}></textarea>
            </div>
            <p>                Please drop this Feedback Form in the Feedback Drop Boxes provided at the closest reception Counter.
                We thank you for your valuable response and time spent, to serve you better.</p>

            <div className='submit-button txt-center'>
                <button onClick={() => storeData()}>Submit</button>
            </div>
        </div> 
        {/* :
        <>
        {
        selectedHospital &&
            <div className='go-center '>
                <div>
                  <h2>No User Info Found !!</h2>
                </div>
                <button className='d__flex gap-5 login-btn' onClick={() => dispatch(setLoginModal())}>
                <Icons name='header' iconType='user' width='30' height='30' viewBox='0 0 30 30' />
                    Please Login 
                </button>
            </div>
        }
        </>
        } */}
       
    </div>
  )
}

export default Questionary