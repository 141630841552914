import React, { useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { useState } from 'react'
import { NewsletterBox, SearchInput } from '../../components';
import { newsletterList } from '../../../api/cmsService';
import { useDispatch, useSelector } from 'react-redux';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import './Newsletters.scss'
import { isEmpty } from 'lodash';

function Newsletters() {
  const dispatch = useDispatch();
  const { selectedHospital } = useSelector  (state => state?.doctorsInfo)
  const [enablePopup,setEnablePopup] = useState(null);
  const [newsletters,setNewsLetters] = useState({})
  const [images,setImages] = useState([])
  const [current,setCurrent] = useState({})

  const getNewsLetter = async () => {
      let r = await dispatch(newsletterList({page:1,per_page:10,q:'',hospital_id: selectedHospital,}))
      setNewsLetters(r);
  }

    useEffect(() => {
      selectedHospital && getNewsLetter()
    },[null,selectedHospital])

    


    const setImageValues = (r) => {
      setCurrent(r)
      setImages([ r?.image1,r?.image2,r?.image3,r?.image4,r?.image5,r?.image6 ])
      setEnablePopup(r)
    }

    const getSearchTerm = async searchTerm => {
      if (searchTerm == '') {
        getNewsLetter()
      } else {
        let r = await dispatch(newsletterList({page:1,per_page:10,q:searchTerm,hospital_id: selectedHospital,}))
        setNewsLetters(r)
      }
    }

    const itemTemplate = (item) => {
      return <Image  src={`${item}`} className='main-image'  preview={true} downloadable={true} alt="Image" />
      // return <img src={`${item}`} className='main-image' onError={(e) => e.target.src = require('../../../assets/default/home-blog.png') } alt={item?.alt} style={{ width: '100%' }} />
    }

    const thumbnailTemplate = (item) => {
        return <img src={`${item}`} className='thumbnail-image' onError={(e) => e.target.src = require('../../../assets/default/home-blog.png') } alt={item?.alt} />
    }


  return (
    <div className='newsletters mar-b-135 container'>
      <h1 className='txt-center custom-heading'>News <span>Letter</span></h1>

      <SearchInput onClick={getSearchTerm} label='News Letters' />

      <div className='d__flex flex-w'>
        {
          newsletters?.list?.map((r,i) => 
            <div className='newsletter-item'>
              <NewsletterBox newsletter={r} openPopup={() => (setImageValues(r))}  />
            </div>
            // openPopup={setEnablePopup(r)
          )
        }
      </div>

        {
          newsletters &&  newsletters?.list &&  isEmpty(newsletters?.list) && 
            <p>No Results Found!</p>
        }


      <Dialog className='news-letter-popup'
			visible={enablePopup}
			onHide={() => dispatch(setEnablePopup(null))} dismissableMask={true}>
          <div>
            {/* <h3>{current?.title}</h3>
            <p>{current?.description}</p> */}
            <iframe src={images[0]} style={{height:'94vh',width:'100%'}}> </iframe>
            {/* <Galleria value={images} item={itemTemplate}  numVisible={6} thumbnail={thumbnailTemplate}></Galleria> */}
          </div>
        </Dialog>

    </div>
  )
}

export default Newsletters