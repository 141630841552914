import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getCmsDetails } from '../../../api/cmsService';

function NAB() {

  const dispatch = useDispatch();
  const [info,setInfo] =  useState({});

  
  const { selectedHospital } = useSelector(state => state?.doctorsInfo)
  
  const getDetailData = async () => {
  const response = await dispatch(getCmsDetails({hospital_id : selectedHospital ,url:'nabh',}))
      setInfo(response)
  } 

  useEffect(() => {  
   getDetailData();
    return () => {}
  }, [])

return (
  <div className='mar-b-135 container'>
      <Helmet>
        <title>BMJH | NAB</title>
      </Helmet>
      {info && <div dangerouslySetInnerHTML={{__html: info?.content}} />}
  </div>
)
}

export default NAB