import React from 'react'
import './LoaderStyle.scss'

function Loader() {
  return (
    <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
    </div>
  )
}

export default Loader