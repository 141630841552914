import React, { useState, useEffect } from 'react'
import { PrevNext } from 'components'
import { getFacilitiesDetail } from 'api/catalogService'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
// import lab from '../../../assets/facilities/Lab.png'
import './FacilitiesDetail.scss'
import { Helmet } from 'react-helmet'
import ModalVideo from 'react-modal-video'
import { CustomizeHeading } from 'components'
import { enableLocation } from 'store/header'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import ImagesGrid from '../../components/ImagesGrid/ImagesGrid'
// import ImagesGrid from '../../components/ImagesGrid/ImagesGrid'

function FacilitiesDetail() {
	let { id } = useParams()
	const dispatch = useDispatch()
	const [facilityDetail, setFacilityDetail] = useState([])
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
	const [videoId,setVideoId] = useState(undefined);

	useEffect(() => {
		dispatch(enableLocation())
		// if (isEmpty(facilityDetail)) {
			selectedHospital && id && getFacilitiesDet()
		// }
		return () => {
			dispatch(enableLocation())
		}
	}, [selectedHospital, id])


	
	const [galleryImages,setGalleryImages] = useState([])

	const getFacilitiesDet = async () => {
		
		const response = await dispatch(
			getFacilitiesDetail({ hospital_id: selectedHospital, facility_id: id })
		)

		let image = []
		image = [response.image2,response.image3,response.image4,response.image5,response.image6,response.image7,response.image8,response.image9,response.image10]

		const val = image.filter(element => {
			return element !== null && element !== undefined && element != '';
		  });

		setGalleryImages(val)

		dispatch(setSelectedHospital(response?.hospital_id))
		setFacilityDetail(response)
	}
	return (
		<div className='main-width facilities-detail mar-b-135 container'>
			<Helmet>
				<title>BMJH - Specialities | {`${ facilityDetail?.meta_title }`}</title>
				<meta property='og:keywords' content={`${ facilityDetail?.meta_keywords }`} />
				<meta property="og:title" content={`${ facilityDetail?.meta_title }`} />
				<meta property="og:description" content={`${ facilityDetail?.meta_description }`}/>

				<meta name="twitter:title" content={`${ facilityDetail?.meta_title }`} />
				<meta name="twitter:description" content={`${ facilityDetail?.meta_description }`}/>
			</Helmet>

			<CustomizeHeading title={facilityDetail?.name}/>
			
			<div className='min-300'>
				<div className='facility-image'>
					<img src={facilityDetail?.image1} alt='' />
				</div>
				<div className='f-14'>
					{facilityDetail && <div dangerouslySetInnerHTML={{__html: facilityDetail.description}} />}
				</div>
			</div>

			<div className='m1a'>
				<ImagesGrid data={galleryImages}  />
			</div>

			{/* <ImagesGrid data={[]} /> */}

			{/* <div className='d-flex flex-wrap'>
				{
					[1,2,3,4,5,6,7,8,9,0].map((v,i) => 
						<div key={i+'s'}>
							<img src={facilityDetail?.image+i+1} onError={((e) => (e.target.src = require('../../../assets/default/env-first-last.png')))} alt='' />
						</div>	
					)	
				}
			</div> */}

			<div>
				{facilityDetail && facilityDetail?.video_link && <iframe width="100%" height="500" src={`https://www.youtube.com/embed/${facilityDetail.video_link}`}></iframe>}
			</div>

			<div>
				<PrevNext />
			</div>

			{/* <div className='d__flex align-start flex-w padd-0-6'> */}
				{/* <div className='flex-1'>
					<h3 className='mt-0'>Features</h3> */}
					{/* <p>{facilityDetail?.description}</p> */}
				{/* </div> */}
			{/* </div> */}

			{/* <ul>
				<li>Lorem Ipsum is simply dummy text of the printing </li>
			</ul> */}
			{/* <div>
				<h3 className='txt-center'>Our Work Environments</h3>
			</div> */}


			{/* <ModalVideo channel='youtube' autoplay="1" isOpen={videoId} videoId={videoId} onClose={() => setVideoId(undefined)} /> */}


		</div>
	)
}

export default FacilitiesDetail
