import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDoctorDetail } from 'api/doctorService'
import { isArray, isEmpty } from 'lodash'
import { Link, Router, useNavigate, useParams } from 'react-router-dom'
import { enableAppointment as setEnableAppointment, enableLocation   } from 'store/header'
import { BlogArticles, Icons, Testimonials } from 'components'
import { Appointments } from 'pages'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import './DoctorDetails.scss'
import { Helmet } from 'react-helmet'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { isNull } from 'lodash'

function DoctorDetails() {
	let { id } = useParams()
	const dispatch = useDispatch()
	const [getDoctorDetails, setDoctorDetails] = useState([])
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
	const { enableAppointment,enableLocation: locationStatus } = useSelector(state => state?.header)
	const navigate = useNavigate()
	const getDoctorDetailReq = async () => {
		let response = await dispatch(
			getDoctorDetail({ hospital_id: selectedHospital, doctor_id: id })
		)
	
		if(response?.details?.status == '0'){
			navigate('/')
		}
		
		dispatch(setSelectedHospital(response?.details?.hospital_id))
		setDoctorDetails(response)
	}

	useEffect(() => {
		dispatch(enableLocation({status : false}))
		selectedHospital && id && getDoctorDetailReq()
		return () => {
			dispatch(setEnableAppointment([]))
			dispatch(enableLocation({status : true}))
		}
	}, [selectedHospital, id])

	useEffect(() => {
		// console.log(isEmpty(enableAppointment));
		// isEmpty(enableAppointment)	&& dispatch(enableLocation({status : false}))
		// console.log("enable location",locationStatus);

		locationStatus && dispatch(enableLocation({status : false}))
	},[locationStatus])


	return (
		<div className='doctor-details main-width mar-b-135'>
			<Helmet>
				<title>BMJH - Doctor | {`${getDoctorDetails?.details?.meta_title}`}</title>
				<meta property='og:keywords' content={`${getDoctorDetails?.details?.meta_keywords}`} />
				<meta property="og:title" content={`${getDoctorDetails?.details?.meta_title}`} />
				<meta property="og:description" content={`${getDoctorDetails?.details?.meta_description}`} />

				<meta name="twitter:title" content={`${getDoctorDetail?.details?.meta_title}`} />
				<meta name="twitter:description" content={`${getDoctorDetails?.details?.meta_description}`} />
			</Helmet>

			<h1 className='txt-center custom-heading'>Doctor <span>Details</span></h1>

			<div className='d__flex doctor-detail align-start flex-w container'>
				<div className='doctor-detail-rt'>
					<div className='image-ctr'>
						<img src={getDoctorDetails?.details?.profile_image} alt='' onError={((e) => {(e.target.src = require('../../../assets/default/home-doctor.png') )})} />
					</div>

					{getDoctorDetails.details?.book_appointment == 1 && <div className='availability'>

						{!isEmpty(getDoctorDetails?.details?.working_slot_v) ? <>
						<div className='d__flex m-d-flex gap-20 heading'>
							<div className='available-icon'>
								<Icons name='doctor' iconType='availability' strokeWidth='0' fillColor='#ffff' viewBox='0 0 30 29'/>
							</div>
							Video Consultation Availability
						</div>
						<ul className='timings'>
							{ getDoctorDetails?.details?.working_slot_v?.map((a,index) => {
								return (
									
									
									
									((a.from_time1 || a.from_time2 || a.from_time3 || a.from_time4 || a.from_time5) && (a.from_time1 != '' || a.from_time2 != '' || a.from_time3 != '' || a.from_time4 != '' || a.from_time5 != ''))   && 


									((a.from_time1 || a.from_time2 || a.from_time3 || a.from_time4 || a.from_time5) && (a.from_time1 != '' || a.from_time2 != '' || a.from_time3 != '' || a.from_time4 != '' || a.from_time5 !=  '')) &&

										<li key={index + "DD"}>

											<div>{a?.day} : </div>
											<div className='ml-30' >
												{
													a?.slot.map((t, i) =>
														<div key={i + "T"}>
															{(t?.from_time || t?.to_time) && <div className='timing-colored'>
																{moment(`01/01/2000 ${t?.from_time}`).format('hh:mm a')} - {' '}
																{moment(`01/01/2000 ${t?.to_time}`).format('hh:mm a')}
															</div>}
														</div>
													)
												}
											</div>

										</li>
								)
							})}
						</ul>
						</> : <>
						 {/* {isArray(getDoctorDetails?.details?.working_slot_v) && 'No slots Today!'}  */}
						 </> }

						<br/>

						{(isEmpty(getDoctorDetails?.details?.working_slot_v) && isEmpty(getDoctorDetails?.details?.working_slot)) && 'No slots Today!'} 

						{!isEmpty(getDoctorDetails?.details?.working_slot) ? <>
						<div className='d__flex m-d-flex gap-20 heading'>
							<div className='available-icon'>
								<Icons name='doctor' iconType='availability' strokeWidth='0' fillColor='#ffff' viewBox='0 0 30 29'/>
							</div>
						 Hospital Visit	Availability
						</div>
						<ul className='timings'>
							{ getDoctorDetails?.details?.working_slot?.map((a,index) => {
								return (
									
									
									
									((a.from_time1 || a.from_time2 || a.from_time3 || a.from_time4 || a.from_time5  ) && (a.from_time1 != '' || a.from_time2 != '' || a.from_time3 != '' || a.from_time4 != '' || a.from_time5 != ''))   && 



										((a.from_time1 || a.from_time2 || a.from_time3 || a.from_time4 || a.from_time5) && (a.from_time1 != '' || a.from_time2 != '' || a.from_time3 != '' || a.from_time4 != '' || a.from_time5 != '')) &&

										<li key={index + "DD"}>

											<div>{a?.day} : </div>
											<div className='ml-30' >
												{
													a?.slot.map((t, i) =>
														<div key={i + "T"}>
															{(t?.from_time || t?.to_time) && <div className='timing-colored'>
																{moment(`01/01/2000 ${t?.from_time}`).format('hh:mm a')} - {' '}
																{moment(`01/01/2000 ${t?.to_time}`).format('hh:mm a')}
															</div>}
														</div>
													)
												}
											</div>

									</li>
									
									
								)
							})}
						</ul>
						</> : <> 
						{/* {isArray(getDoctorDetails?.details?.working_slot) && 'No slots Today!'} */}
						 </> }



						{getDoctorDetails.details?.book_appointment == 1 && (!isEmpty(getDoctorDetails?.details?.working_slot) || !isEmpty(getDoctorDetails?.details?.working_slot_v)) && <div className='txt-center'>
							<button
								onClick={() =>
									dispatch(setEnableAppointment(getDoctorDetails?.details))
								}
								className='doctor-detail-btn'>
								Book Appointment
							</button>
						</div>}
					</div>}
					{getDoctorDetails?.details?.doctor_blogs && isArray(getDoctorDetails?.details?.doctor_blogs) && !isEmpty(getDoctorDetails?.details?.doctor_blogs) && <div>
						<h4>Doctor's Articles</h4>
						<BlogArticles blog={getDoctorDetails?.details?.doctor_blogs} />
					</div>}
				</div>
				<div className='doctor-detail-lt flex-1'>
					{(getDoctorDetails?.details?.firstname || getDoctorDetails?.details?.lastname) && <h2 className='m-0'>
						{`${getDoctorDetails?.details?.firstname} ${getDoctorDetails?.details?.lastname}`}
					</h2>}
					<h6 className='fnt-14 detail mb-5'>{getDoctorDetails?.details?.title}</h6>
					<p className='fnt-14 detail mb-5'>{getDoctorDetails?.details?.sub_title}</p>
					{/* <h6 className='fnt-14 m-0'>
						<span>{getDoctorDetails?.details?.degree_name}</span>
						{getDoctorDetails?.details?.degree_institution && <span> ({getDoctorDetails?.details?.degree_institution})</span>}
					  	{getDoctorDetails?.details?.degree_group && <span> - {getDoctorDetails?.details?.degree_group }</span>}
					</h6> */}
					{getDoctorDetails?.details?.kmc_number && <h6 className='m-0'>
						KMC Regd. No. : {getDoctorDetails?.details?.kmc_number}
					</h6>}
					{getDoctorDetails?.details?.ksdc_number && <h6 className='m-0'>
						KSDC Regd. No. : {getDoctorDetails?.details?.ksdc_number}
					</h6>}
					<div className='detail'>
						<p>{getDoctorDetails?.details?.description}</p>
					</div>
					<div className='honor-award'>

						{!isEmpty(getDoctorDetails?.details?.doctor_awards_degrees) && <div className='d__flex  align-start gap-40 '>
							<h2>Award</h2>
							{getDoctorDetails?.details?.doctor_awards_degrees?.map((a, index) => (
								<>
								{a?.degree_type_name == 'Award' && 
									<div key={index + "DG"}>
									{/* <h2>{a?.degree_type_name}</h2> */}
									<div className='d__flex align-start home gap-20' >

										<div className='awards'>
											<div className='d__flex gap-20'>
												<Icons
													name='doctor'
													iconType='award'
													width='61'
													height='61'
													viewBox='0 0 61 61'
												/>
												<p>{a?.degree_name}</p>
											</div>
										</div>
										<div className='image-ctr'>
											<img src={a?.certificate_image} alt='' />
										</div>
									</div>
								</div>
								}
								</>
							))}
						</div>}

						{!isEmpty(getDoctorDetails?.details?.specialities) && <div className='specialty'>
							<h5>Specialty</h5>
							<ul>
								{getDoctorDetails?.details?.specialities?.map((s, index) => (
									<li key={index + "SP"}>{s?.name}</li>
								))}
							</ul>
						</div>}
						{!isEmpty(getDoctorDetails?.details?.doctor_awards_degrees) && <div className='education'>
							<h5>Education</h5>
							<ul>
								{
									getDoctorDetails?.details?.doctor_awards_degrees.map((res, index) =>
									<>
										{res.degree_type_name == 'Degree' && <li key={index + 'ED'}>
											{  res.degree_name}
											
										</li>}
									</>
									)
								}
								{/* <li>-</li>
								<li>-</li>
								<li>-</li> */}
							</ul>
						</div>}
						{getDoctorDetails?.details?.experience && <div className='experience'>
							<h5>Experience</h5>
							<ul>
								<li>{getDoctorDetails?.details?.experience} years</li>
							</ul>
						</div>}
					</div>
				</div>
			</div>
			{/* {
				!isEmpty(getDoctorDetails?.review_and_rating) &&
				<Testimonials title={`${getDoctorDetails?.details?.firstname} ${getDoctorDetails?.details?.lastname}` + " " + 'Rating & Reviews'} data={getDoctorDetails?.review_and_rating} isFromDoctor={true} />
			} */}
			<Dialog
				className='doctor-datetime'
				visible={!isEmpty(enableAppointment)}
				// style={{
				// 	width: '80%',
				// 	height: '1000px',
				// 	boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.15)',
				// 	borderRadius: '0px 40px 40px 40px',
				// 	overflow: 'hidden',
				// }}
				onHide={() => dispatch(setEnableAppointment([])) }
				dismissableMask={true}>
				<Appointments isFromDoctor={true} isFromDoctorDetail={true} />
			</Dialog>
		</div>
	)
}

export default DoctorDetails
