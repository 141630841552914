import React, { useState, useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { Icons } from 'components'
import './SearchInput.scss'

function SearchInput(props) {
	const dispatch = useDispatch()
	const [searchValue, setSearchValue] = useState('')
	const [location, setLocation] = useState()
	const { hospitalList, selectedHospital } = useSelector(
		state => state.doctorsInfo
	)
	const optionsList = hospitalList?.map(l => ({
		name: l?.name,
		id: l?.id,
	}))

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			props?.onClick(searchValue)
		}
	}

	useEffect(() => {
		setLocation(selectedHospital)
		setSearchValue('')
	}, [selectedHospital])

	return (
		<div className='search-input-ctr'>
			<div className='search-input d__flex m-d-flex'>
				{/* <Icons
					className='location-icon web-location-icon'
					name='home'
					iconType='location-filled'
					width='18'
					height='26'
					viewBox='0 0 18 26'
				/> */}

				{/* <Dropdown
					optionLabel='name'
					value={location}
					optionValue='id'
					options={optionsList}
					onChange={e => dispatch(setSelectedHospital(e?.value))}
					placeholder='Select a Hospital'
					className='location-web'
				/> */}
				
				<input
					type={'text'}
					placeholder={`Search ${props?.label}`}
					value={searchValue}
					onChange={e =>
						e?.target?.value == ''
							? (props?.onClick(''), setSearchValue(''))
							: setSearchValue(e?.target?.value)
					}
					onKeyPress={handleKeyDown}
				/>

				<i
					onClick={() => (props?.onClick(''), setSearchValue(''))}
					className={`reset-search-inp pi pi-times ${
						searchValue?.length > 0 ? '' : 'p-hidden'
					}`}
				/>

				<button
					onClick={() => props?.onClick(searchValue)}
					className='search-icon'>
					Search
				</button>
			</div>
		</div>
	)
}

export default SearchInput
export { SearchInput }
