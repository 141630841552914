import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import './Gallery.scss'
import { useDispatch, useSelector } from 'react-redux';
import { getGallery, getGalleryDepartments, getGalleryDetail } from '../../../api/cmsService';
import ImagesGrid from '../../components/ImagesGrid/ImagesGrid';
import { TabPanel, TabView } from 'primereact/tabview';
import { concat, isEmpty } from 'lodash';

function Gallery() {
  const dispatch = useDispatch();
  const [info,setInfo] =  useState({});

  const [allImage,setAllImages] = useState([])
  const [galleryImages,setGalleryImages] = useState([])
  const [departments,setDepartments] = useState([])
  const { selectedHospital }  = useSelector(state => state?.doctorsInfo)

  const [galleryListInfo,setGalleryListInfo] = useState({})
	const [page,setPage] = useState(1)


  const getGalleryImages = async (p) => {
		// page: page ,per_page: 20
		const response = await dispatch(getGallery({q : '',page : page-1,per_page:5,hospital_id:selectedHospital}))

		if(response){
			const { list, ...res} = response

      console.log(list,page)

      if(page == 1){
        let image = []
        let sections = []
        list?.map(v => {
          image = [v.image1,v.image2,v.image3,v.image4,v.image5,v.image6,v.image7,v.image8,v.image9,v.image10]
          const imageValues = image.filter(element => {
            return element !== null && element !== undefined;
          });
          sections = [...sections,{name:v?.name,images:imageValues}]
        })
        setAllImages(sections)
        
        // setDoctorList(list)
      } else {
        // setDoctorList([...doctorsList,...list])
        let image = []
        
        list?.map(v => {
          image = [v.image1,v.image2,v.image3,v.image4,v.image5,v.image6,v.image7,v.image8,v.image9,v.image10]
          const imageValues = image.filter(element => {
            return element !== null && element !== undefined;
          });
          setAllImages((prev) => [...prev,{name:v?.name,images:imageValues}])
        })
        
      }

			let v = res
			setGalleryListInfo(v)
		}
	}

  useEffect(() => {
		if(page != 0){
			selectedHospital && getGalleryImages()
		}
	}, [page])

  // const getDetailData = async (custom) => {
  //     const response = await dispatch(getGallery({q : '',page : 0,per_page:5,hospital_id:selectedHospital}))
  //     setInfo(response)
  //     let image = []
  //     response?.list?.map(v => {
  //       image = [v.image1,v.image2,v.image3,v.image4,v.image5,v.image6,v.image7,v.image8,v.image9,v.image10]
  //       const imageValues = image.filter(element => {
  //         return element !== null && element !== undefined;
  //       });
  //       setAllImages((prev) => [...prev,{name:v?.name,images:imageValues}])
  //       // console.log(imageValues)
        
  //       setGalleryImages((prev) => [...prev,...imageValues])
  //       // selectedHospital == v?.hospital_id ? setGalleryImages((prev) => [...prev,{images:imageValues,title:v?.name}]) : setGalleryImages({images:imageValues,title:v?.name})
  //     })
  //   // setImages(imageValues)

  // } 

  const getDeps = async () => {
    const response = await dispatch(getGalleryDepartments({hospital_id:selectedHospital}))
    setDepartments(concat( {name:'All Gallery images',id:0},response) )
  }

  const getDepDetail = async (value) => {
    const v = await dispatch(getGalleryDetail({hospital_id:selectedHospital,"galleryimages_id":value?.id}))      
      let image = [v.image1,v.image2,v.image3,v.image4,v.image5,v.image6,v.image7,v.image8,v.image9,v.image10]
      const imageValues = image.filter(element => {
        return element !== null && element !== undefined;
      });
      setGalleryImages(imageValues)
    
  }

  useEffect(() => {
    if(selectedHospital){
      setGalleryImages([])
      setPage(1)
      setInfo({})
      setAllImages([])
      setActiveIndex(0)
    }
    getDeps()
    selectedHospital &&  getGalleryImages()

  },[selectedHospital])

  const [activeIndex,setActiveIndex] = useState(0)



  const changed = (e) => {
			setActiveIndex(e)
      departments?.map((v,i) => {
        if(v?.id != 0 && i == e){
          console.log("v",v)
          getDepDetail(v)
        }
      })
	}


  return (
    
    <div className='gallery-ctr main-width container'>
      <h1 className='txt-center custom-heading'> BMJH  <span> Gallery</span></h1>
      <Helmet>
				<title>BMJH | Gallery</title>
			</Helmet>
      <div>

      <TabView activeIndex={activeIndex} onTabChange={(e) => changed(e.index)}  scrollable>
						{departments?.map((t, index) => (
						 	<TabPanel
								key={index+'TP'}
								header={t?.name}
								// className={`cursor-pointer ${
								// 	activeTab == t?.id ? 'active' : ''
								// }`}
								// onClick={() => setActiveTab(t?.id)}
								>
								{/* {t?.name} */}
								{/* {activeTab}
								{t?.name} */}
							</TabPanel>
						))}
			</TabView>


      {
        activeIndex == 0 ? 

        <>
        {
          allImage?.map(v => 
            <div>
              <h2>{v?.name}</h2>
              <>
                <ImagesGrid data={v?.images}  />
              </>
            </div>  
          )
        }
        {!isEmpty(allImage) && allImage?.length != galleryListInfo?.total_count && <div className='txt-center'>
                  <button className='doctor-box-scroll-more' onClick={() => setPage(page+1)}>Load More</button>
                </div> }
        </>
         :
         <>
          <ImagesGrid data={galleryImages}  />
         </>
      }
        
      </div>
    </div>
  )
}

export default Gallery