import { configureStore, combineReducers } from '@reduxjs/toolkit'
import headerSlice from './header'
import categoriesSlice from './categories'
import doctorsSlice from './doctors'

const reducer = combineReducers({
	header: headerSlice,
	categories: categoriesSlice,
	doctorsInfo: doctorsSlice,
})

const store = configureStore({
	reducer,
})

export default store
