import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { getMedicalPackageDetail } from 'api/doctorService'
import { MedicalPackage, SearchInput, Testimonials,DateTime,CustomizeHeading } from 'components'
import './MedicalPackageDetail.scss'
import { enableTimingPopup,enableLocation } from 'store/header'
import { Dialog } from 'primereact/dialog'

import { Helmet } from 'react-helmet'
import { useWindowSize } from 'react-use'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { getCmsDetails } from 'api/cmsService'


function MedicalPackageDetail() {
	const dispatch = useDispatch()
	const width = useWindowSize()
	const mobile = width  < 480 ? true : false 
	const { id } = useParams()
	const { selectedHospital } =
	useSelector(state => state?.doctorsInfo)
	const [packageDetail, setPackageDetail] = useState({})
	const [patientInstruct,setPatientInstruct] = useState({})

	const getMedicalPackageDetailReq = async () => {
		let response = await dispatch(getMedicalPackageDetail({ package_id: id ,hospital_id : selectedHospital}))
		response?.hospital_id && dispatch(setSelectedHospital(response?.hospital_id))
		setPackageDetail(response)
	}

	const getCMS = async () => {
		const response = await dispatch(getCmsDetails({url: 'patient_instruction_for_healthcheck'}))
		setPatientInstruct(response)
	}

	useEffect(() => {
		dispatch(enableLocation())
		id && getMedicalPackageDetailReq()
		id && getCMS()
		return () => {
			dispatch(enableLocation())
		}
	}, [id])
	
	const reduxValues = useSelector(state => {
		return state
	})


	return (
		<div className='medical-packages-details main-width container mar-b-135'>
			<Helmet>
				<title>BMJH - Packages | {`${ packageDetail?.meta_title }`}</title>
				<meta property='og:keywords' content={`${ packageDetail?.meta_keywords }`} />
				<meta property="og:title" content={`${ packageDetail?.meta_title }`} />
				<meta property="og:description" content={`${ packageDetail?.meta_description }`}/>

				<meta name="twitter:title" content={`${ packageDetail?.meta_title }`} />
				<meta name="twitter:description" content={`${ packageDetail?.meta_description }`}/>
			</Helmet>
			<div className='padd-0-6'>
				<h1 className='txt-center custom-heading'>
					Medical <span>Packages</span>
				</h1>
				{/* <SearchInput /> */}
			</div>

			{packageDetail && packageDetail?.name_en && (
				<div className='package-details'>
					<div className='d__flex m-d-flex align-start gap-20 package-dtl'>
						<div className='images-ctr'>
							<img src={packageDetail?.image1} width="100%" height="auto" alt='' />
						</div>
						<div className='desc-ctr flex-1'>
							<div>
								<h3>{packageDetail?.name_en}</h3>
								<p className='package-length'>
									Total Parameters : {packageDetail?.parameters?.length}
								</p>
							</div>
						</div>
						<div className='book-ctr flex-1'>
							<p className='ttl'>Selected Package</p>
							<div className='d__flex m-d-flex just-s'>
								<p className='m-0'>{packageDetail?.name_en}</p>
								<h5 className='m-0 fnt-16'>₹ {packageDetail?.price}</h5>
							</div>
							<p className='test-count'>
								Includes {packageDetail?.params_count} tests
							</p>
							<div className='d__flex m-d-flex just-s discounts'>
								<p>Discount</p>
								<p>₹ {packageDetail?.discount}</p>
							</div>
							<div className='d__flex m-d-flex just-s total'>
								<p>TOTAL</p>
								<p>
									₹ {packageDetail.discounted_price}
								</p>
							</div>
							<div className='d__flex just-s blo'>
								<div className='d__flex m-d-flex gap-20 flex-1'>
									<h2 className='price m-0'>
										₹ {packageDetail?.discounted_price}
									</h2>
									<h1 className='s-price m-0'>₹ {packageDetail?.price}</h1>
								</div>
								<div className='flex-1 d__flex just-end'>
									<button onClick={ () => dispatch(enableTimingPopup(true))} className='bk-now'>Book Now</button>
								</div>
							</div>
						</div>
					</div>
					{packageDetail?.description_en && <div className='why'>
						<p className='fnt-20'>Who should book this checkup?</p>
						<p className='package-desc'>{packageDetail?.description_en}</p>
					</div>}
					<div className='tests'>
						<p className='ttl fnt-20'>Includes {packageDetail?.params_count} Tests</p>
						<ul className='d__flex flex-w gap-40'>
							{packageDetail &&
								packageDetail?.parameters.map((res, index) => (
									<li
										className='test-item m-d-flex d__flex gap-10'
										key={index + 'TT'}>
										<div>
											<img src={require('../../../assets/test.png')} alt='' />
										</div>
										<div className='flex-1'>
											<h5>{res.name}</h5>
											{/* <p className='m-0'>{res.tests}</p> */}
										</div>
									</li>
								))}
						</ul>
					</div>

					<div className='mt-5 pharmacy-information'>
						<CustomizeHeading title={patientInstruct?.title }/>
						{patientInstruct?.content && <div dangerouslySetInnerHTML={{__html: patientInstruct?.content}} />}
					</div>

					{packageDetail?.similar_packages  && packageDetail?.similar_packages.length != 0 &&
					<div className='related-packages'>
						<h3>Related Health Checkup Packages</h3>
						<div className='d__flex flex-w medical-packages-list'>
							{
								packageDetail?.similar_packages?.map((res, index) => (
									<div className='package-item' key={index + 'PACK'}>
										<MedicalPackage packageInfo={res} />
									</div>
								))
							}	
						</div>
					</div>
					}
				</div>
			)}

			{/* <Testimonials title={'Patient Testimonials'} /> */}

			<Dialog className='package-datetime'
					visible={reduxValues.header.enableTimingPopup}
					// style={{  width: mobile? '90%' : '80%', height: mobile ? '800px' :'1000px' }}
					onHide={() => dispatch(enableTimingPopup(false))}
					dismissableMask={true}>
					<DateTime
						packageBooking={true} packagePrice={packageDetail?.discounted_price} package_id={packageDetail?.id} packageInfo={packageDetail}
						// doctorId={values?.doctor}
						// visitor_type_id={parseInt(watchPrefer?.join())}
						// speciality_id={methods.getValues('filterSpeciality')}
						// doctorInfo={
						// 	!isEmpty(enableAppointment)
						// 		? enableAppointment
						// 		: values?.doctorInfo
						// }
					/>
			</Dialog>
		</div>
	)
}

export default MedicalPackageDetail
