import './HealthCard';
import './HealthCard.scss';

import React from 'react';

import {
  Icons,
  TitleCard,
} from '../../../components';
import { isEmpty } from 'lodash';

function HealthCard({data}) {
  const healthCards = !isEmpty(data?.placeholders) ? data?.placeholders[0]?.health_cards : []
  return (
    <section className='health-card'>
      {
        healthCards?.length ? <div className=' container'>
            <div className='wid-92 mar-auto'>
            <TitleCard title='Health Card'/>
            <div className='cards d__flex gap-20 flex-w'>
              {
                healthCards.map((res,index) =>
                  <div key={index+"HC"} className='card d__flex m-d-flex' style={{backgroundColor : res?.color_code}}>
                      <div className='flex-1'>
                          <h6 className='m-0'>{res?.title}</h6>
                          <p className='m-0 fnt-12'>{res?.description}</p>
                      </div>
                      <div >
                        <img src={res?.image} alt='' height={'90px'}/>
                        {/* <Icons name='home' iconType='health-card'  width="80" height="94" viewBox="0 0 80 94" />  */}
                      </div>
                  </div>
                )
              }
            </div>
            </div>
          </div>
          :<></>
      }
    </section>
  )
}

export default HealthCard
export { HealthCard };