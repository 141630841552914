// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/bmjh.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".otp-popup{height:100%}.otp-popup .bg-image{flex:0 0 60%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;height:100%}.otp-popup .otp-comp{flex:1}@media only screen and (max-width: 600px){.otp-popup{min-height:500px;max-height:600px}.otp-popup .bg-image{display:none}}@media only screen and (min-width: 600px){.otp-popup{min-height:500px;max-height:600px}.otp-popup .bg-image{display:none}}@media only screen and (min-width: 992px){.otp-popup{height:560px}.otp-popup .bg-image{display:block !important}}", "",{"version":3,"sources":["webpack://./src/views/components/Auth/OtpPopup/OtpPopup.scss"],"names":[],"mappings":"AAAA,WAAW,WAAW,CAAC,qBAAqB,YAAY,CAAC,wDAAiD,CAAC,2BAA2B,CAAC,qBAAqB,CAAC,WAAW,CAAC,qBAAqB,MAAM,CAAC,0CAA0C,WAAW,gBAAgB,CAAC,gBAAgB,CAAC,qBAAqB,YAAY,CAAC,CAAC,0CAA0C,WAAW,gBAAgB,CAAC,gBAAgB,CAAC,qBAAqB,YAAY,CAAC,CAAC,0CAA0C,WAAW,YAAY,CAAC,qBAAqB,wBAAwB,CAAC","sourcesContent":[".otp-popup{height:100%}.otp-popup .bg-image{flex:0 0 60%;background-image:url(../../../../assets/bmjh.png);background-repeat:no-repeat;background-size:cover;height:100%}.otp-popup .otp-comp{flex:1}@media only screen and (max-width: 600px){.otp-popup{min-height:500px;max-height:600px}.otp-popup .bg-image{display:none}}@media only screen and (min-width: 600px){.otp-popup{min-height:500px;max-height:600px}.otp-popup .bg-image{display:none}}@media only screen and (min-width: 992px){.otp-popup{height:560px}.otp-popup .bg-image{display:block !important}}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
