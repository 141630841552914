import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'
import { getHomePageData } from 'api/catalogService'
import {
	BannerCard,
	Blog,
	Departments,
	Events,
	HealthCard,
	HomeCarousel,
	MeetDoctors,
	Testimonials,
} from 'components'

function Home() {
	const dispatch = useDispatch()
	const { homePageData } = useSelector(state => state?.categories)
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)

	useEffect(() => {
		selectedHospital && getHomePageResponse()
	}, [selectedHospital])

	const getHomePageResponse = async () => {
		await dispatch(getHomePageData({ hospital_id: selectedHospital }))
	}

	return (
		<>
			<Helmet>
				<title>BMJH | Home</title>
			</Helmet>
			<div className='main-width'>
				<HomeCarousel data={homePageData?.find(f => f?.title == 'banners')} />
				<Departments
					data={homePageData?.find(f => f?.url_key == 'web_deps')}
				/>
				<MeetDoctors data={homePageData?.find(f => f?.title == 'doctors')} />
			</div>
			<BannerCard data={homePageData?.find(f => f?.title == 'Packages')} />

			<div className='main-width'>
				<Testimonials title='Patient Testimonials' data={homePageData?.find(f => f?.title == 'Ratings/Reviews')} />
				<Events data={homePageData?.find(f => f?.title == 'news / events')} />
				<Blog data={homePageData?.find(f => f?.url_key == 'web_blogs')} />
				<HealthCard data={homePageData?.find(f => f?.url_key == 'web_healthcard')} />
			</div>
		</>
	)
}

export default Home
