import React, { useEffect, useState } from 'react'
import { BlogBox, SearchInput } from 'components'
import './Blogs.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getCmsList } from '../../../api/cmsService'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'lodash'
import { NavLink, useParams } from 'react-router-dom'

function Blogs() {


	const { id } = useParams();
	const dispatch = useDispatch();

	const [blogs,setBlogs] = useState();
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
	// const [blogBtn, SetActiveBlog] = useState(1)
	const [blogListInfo,setBlogListInfo] = useState({})
	const [page,setPage] = useState(0)

	const  getCms = async () => {
		// "q":"","page":"0","per_page":"15","hospital_id":"1","cms_type":"2"
		const response = await dispatch(getCmsList({ hospital_id: selectedHospital,cms_type: id,q:"",page: page.toString(),per_page:"9" }))
		//  ? setBlogs([]) : null
		if(response){
			const { cms_list, ...res} = response
			page == 0 ? setBlogs(cms_list) : (  setBlogs([...blogs,...cms_list]))
			let v = res
			setBlogListInfo(v)
		}

	}


	useEffect(() => {
		if(page != 0){
			selectedHospital && getCms()
		}
	}, [page])



	const getSearchTerm = async searchTerm => {
		setPage(0)
		setBlogListInfo({})
		if (searchTerm == '') {
			// setPage(0)
			
			getCms()
			// setFacilityData(list)
			// setBlogs(blogs)
		} else {
			let v = await dispatch(getCmsList({ hospital_id: selectedHospital,cms_type: id,q:searchTerm,page:"0",per_page:"100" }))
			setBlogs(v?.cms_list)
		}
	}

	useEffect(() => {
		selectedHospital &&	getCms();
	  return () => {}
	}, [selectedHospital,id])
	

	const types = [
		{id:1,type:'Blogs'},
		{id:3,type:'Vlogs'},
		{id:5,type:'News'},
		{id:6,type:'Events'},
		{id:7,type:'charitable activity'},
	]

	return (
		<div className='blog-vlog container mar-b-135'>
			<Helmet>
				<title>BMJH | Blogss</title>
			</Helmet>
			<h1 className='txt-center custom-heading'>
				Latest <span>Updates</span>
			</h1>

			<SearchInput onClick={getSearchTerm} label='Blogs' />

			<div> 
				<div className='d__flex just-c'>
					<div className='toggle-btn'>
						{
							types?.map((v,i) =>
							<NavLink key={i+"BL"} onClick={() => setPage(0)} className={({ isActive }) => (isActive ? 'active-btn toggling' : 'toggling')} to={'/blogs/'+v?.id} >
								<button>
									{v?.type}
								</button>
							</NavLink>
							)
						}
					</div>
				</div>

				{
					!isEmpty(blogs) ? 
					<div className='d__flex flex-w'>
					{blogs?.map((res,index) => (
						<div key={index+"Bg"} className='blogs-item'>
							<BlogBox blog={res} />
						</div>
					))}
					</div>
					:
					<>
						No Societal Found
					</>
				}
			</div>

			{ !isEmpty(blogs) && blogListInfo?.total_count && blogs?.length != blogListInfo?.total_count && <div className='txt-center'>
				<button className='doctor-box-scroll-more' onClick={() => setPage(page+1)}>Load More</button>
			</div> }

		</div>
	)
}

export default Blogs
