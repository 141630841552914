import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getConsolidatedRecords } from 'api/appointmentService'
import './RecordList.scss'
import { Image } from 'primereact/image';
import { Paginator } from 'primereact/paginator'

function RecordList() {
	const dispatch = useDispatch()
	const [getRecordsList, setRecordsList] = useState({})
	const { selectedHospital } = useSelector(state => state.doctorsInfo)
	const [basicFirst, setBasicFirst] = useState(1);
	const [basicRows, setBasicRows] = useState(10);

	useEffect(() => {
		 consolidatedRecords(1) 
	}, [selectedHospital])

	const consolidatedRecords = async (count) => {
		const response = await dispatch(
			getConsolidatedRecords({
				hospital_id: selectedHospital,
				page: count,
				per_page : basicRows
			})
		)
		setRecordsList(response != undefined ? response : {customer_appointments_records_list_consolidated : []} )
	}

	const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setBasicRows(event.rows);
		consolidatedRecords(event.first == 0 ? 1 : event.first / 10 + 1) 
		window.scrollTo(0,0);
    }


	return (
		<div className='records-list'>
			{
				getRecordsList?.customer_appointments_records_list_consolidated
				&& getRecordsList?.customer_appointments_records_list_consolidated?.length != 0 ?

				<>
					{
					getRecordsList?.customer_appointments_records_list_consolidated?.map((res,index) =>
					<div className='record d__flex align-start gap-20'key={'RL'+index}>
							<div className='date-box'>
							<span>{new Date(res?.records_created_at).getDate()}</span>
							<span>{new Date(res?.records_created_at).toLocaleString('default', {
										month: 'long',
									})}</span>
						</div>
						<div className='flex-1 record-info'>
							<h5 className='m-0'>{res?.records_name}</h5>
								<p className=''><b>Appointment ID :</b> <Link to={'/profile/my-appointments/'+res?.appointment_id}> {res?.formattedOrderId}</Link></p>
							{/* <p className='m-0'>
								Lorem Ipsum is simply dummy text of the printing and typesetting
								industry. Lorem Ipsum has been the industry's standard dummy text
								ever since the 1500s.
							</p> */}
							<div className='record-images d__flex flex-w m-d-flex gap-20'>
								{
									res?.records_files?.slice(0,4).map((value,index)=>
									<div key={index+'RF'}>
										<Image src={value?.records_file_url} preview={true} downloadable={true} alt="Image" width={'70'} height={'70'}/>
										{/* <img src={value?.records_file_url} height="40px" width={'40px'} alt=''/> */}
									</div>
									)
								}
								
								{res?.records_files?.length >= 4 && <Link className='more-images' to={'/profile/records/'+res.appointment_id}>+ more</Link>}
							</div>
						</div>
						<Link to={'/profile/records/'+res.appointment_id}>
							<button>View Detail</button>
						</Link>
					</div>
					)
					}
					<Paginator first={basicFirst} rows={basicRows} totalRecords={getRecordsList?.customer_appointments_records_total} rowsPerPageOptions={[10, 20, 30]} onPageChange={onBasicPageChange}></Paginator>
				</>
				// </>
				: 
				<>
				{
					getRecordsList?.customer_appointments_records_list_consolidated && 
					<div className='go-center'>
						<img src={require(`../../../assets/empty_item.svg`)}  height={'200px'} alt='' />
						<h2>No Record Found !!</h2>
					</div>
					}	
				</>
			}

			
		</div>
	)
}

export default RecordList
