import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { DoctorBox, Icons } from 'components'
import './DoctorBoxScroll.scss'

function DoctorBoxScroll(props) {
	let scroll = false
	
	// let [currentActiveData, setCurrentActive] = useState([])

	// useEffect(() => {
	// 	if (!isEmpty(props?.list)) {
	// 		if (props?.activeID == '00') {
	// 			setCurrentActive(props?.list)
	// 		} else {
	// 			let getDoctorsList = props?.list?.filter(f =>
	// 				f?.specialities_id?.includes(props?.activeID?.toString())
	// 			)
	// 			setCurrentActive(getDoctorsList)
	// 		}
	// 	} else {
	// 		setCurrentActive([])
	// 	}
	// }, [props])

	const scrollRef = useRef(null)
	
	const scroll_to = scrollOffset => {
		scrollRef.current.scrollLeft += scrollOffset
	}

	return (
		<div className='doctor-box-scroll'>
			<div className={`d__flex  m-d-flex gap-2 fla-1 ${ props?.scroll ? 'doctor-box-scrollable' : 'doctor-box-non-scroll' }`} ref={scrollRef}>
				{props?.list?.map((res, index) => (
					<div className='item' key={index}>
						<DoctorBox doctor={res} />
					</div>
				))}
			</div>
		</div> 
	)
}

export default DoctorBoxScroll
export { DoctorBoxScroll }
