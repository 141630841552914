import React, { useEffect } from 'react'
import { useState } from 'react';

function CustomizeHeading({title}) {

    const [heading,setHeading] = useState(title)
    useEffect(() => {
        if(title){
            let t = title.trim().split(" ")
            // let f = t.length > 2 ?  t.slice(1, 3) :  
           let f = t.shift();
            let h = (t.length > 0 ? "<span>"+ f + "</span> " : f) + t.join(" ");
            setHeading(h)
        }
      return () => {  }
    }, [title])
    

  return (
    <div className=''>
        <h1 className='txt-center custom-heading' dangerouslySetInnerHTML={{__html: heading}} ></h1>
    </div>
  )
}

export default CustomizeHeading