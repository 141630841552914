import React from 'react'
import { Link } from 'react-router-dom'
import { Icons } from '../../Icons'

function HomeDoctorCard({d}) {
  return (
    <div className='d__flex doctor-large-dtl align-start '>
      <div className='doctor-image-lg'>
        <img src={d?.profile_image} alt='' onError={((e) => {(e.target.src = require('../../../../assets/default/home-doctor.png') )})} />
        <div className='image-bg-animate'></div>
      </div>
      <div className='doctor-info'>
        <h3>{`${d?.firstname} ${d?.lastname} `}</h3>
        <div className='department my-5'>{d?.title}</div>
        <div className='department my-5'>{d?.sub_title}</div>
        <div className='department'>{d?.specialities_names}</div>
        
        {/* {
          isArray(d?.doctor_awards_degrees) && !isEmpty(d?.doctor_awards_degrees) && d?.doctor_awards_degrees?.map(v=> 
          <div>
            <h6 className='fnt-14 my-5'>
                <span>{v?.degree_name}</span>
                  {v?.degree_institution && <span> ({v?.degree_institution})</span>}
                  {d?.degree_group && <span> - {d?.degree_group }</span>}
                </h6>
          </div>	
          )
        } */}
        {/* <p>{d?.doctor_description}</p> */}
        <div className='d__flex buttons m-d-flex'>
          <Link to={'/doctors/'+d?.doctor_id}>
            <button className='d__flex gap-15  m-d-flex'>
              <span>
                <Icons name='home' iconType='view-profile' width='54' height='54' viewBox='0 0 54 54'/>
              </span>
              View Profile
            </button>
          </Link>
          {/* <Link to={'/appointment/normal'}>
            <button className='d__flex gap-15  m-d-flex'>
              <span>
                <Icons name='my-account' iconType='appointment' width='25' height='25' viewBox='0 0 20 20'/>
              </span>
              Book Appointment
            </button>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default HomeDoctorCard