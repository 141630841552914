import './OtpPopup.scss';

import React from 'react';

import OtpAuth from '../OtpAuth';

function OtpPopup() {
  return (
    <div className='otp-popup d__flex m-d-flex'>
        <div className='bg-image'></div>
        <div className='otp-comp'>
            <OtpAuth />
        </div>
    </div>
  )
}

export default OtpPopup
export { OtpPopup };