import './BlogsDetail.scss'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCmsDetails } from 'api/cmsService'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { enableLocation } from 'store/header'
import { Icons } from '../../components'
import { enableModalVideo } from '../../../store/header'

function BlogsDetail() {
	
	const { id } = useParams();
	const dispatch = useDispatch();
	const [info,setInfo] =  useState({});
  
	const getDetailData = async () => {
		const response = await dispatch(getCmsDetails({url: id }))
	  	setInfo(response)
	} 
  
	useEffect(() => {  
		dispatch(enableLocation())
		id && getDetailData();
		return () => {
			dispatch(enableLocation())
		}
	}, [id])

	return (
		<div className='blog-detail-ctr container mar-b-135 '>
			<Helmet>
				<title>BMJH | {`${ info?.meta_title }`}</title>
				<meta property="og:url" content="http://bmjh.og/" />
				<meta property='og:keywords' content={`${ info.meta_keywords }`} />
				<meta property="og:title" content={`${ info?.meta_title }`} />
				<meta property="og:description" content={`${ info.meta_description }`}/>

				<meta name="twitter:title" content={`${ info?.meta_title }`} />
				<meta name="twitter:description" content={`${ info.meta_description }`}/>
			</Helmet>
			<h1 className='txt-center custom-heading'>
				{info?.type_name} <span>Details</span>
			</h1>
			<div className='d__flex align-start flex-w'>
				<div className='blog-detail-lt flex-1'>
					<h4>{info?.title}</h4>
					<div className='d__flex gap-20 blogger-info mar-b-20'>
						<img src={info?.author_image ?? 'https://bmjh-dev-app.s3.amazonaws.com/default/env-center.png'}  alt='' height={'30px'} width={'30px'}/>
						<p className='blogger-name'>{info?.author_name}</p>
						<p className='blogger-time'>{moment(info?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
					</div>
					<div className=' pharmacy-information'>
						{info?.image && 
						<div className='detail-image pharmacy-image '>
							<img src={info?.image} alt='' />
						</div>}
						<div className='blog-detail description-blog' dangerouslySetInnerHTML={{__html: info?.content}} ></div>
							
						{
						info && info?.type_name == 'Events' && (info?.image1 || info?.image2 || info?.image3 || info?.image4 || info?.image5 || info?.image6 || info?.image7 || info?.image8 || info?.image9 || info?.image10 )  && 
						<div className='event-images'>
							{info?.image1 && <div className='slider-img' >
								<img src={info?.image1} alt='' />
							</div>}
							{info?.image2 && <div className='slider-img' >
								<img src={info?.image2} alt='' />
							</div>}
							{info?.image3 && <div className='slider-img' >
								<img src={info?.image3} alt='' />
							</div>}
							{info?.image4 && <div className='slider-img' >
								<img src={info?.image4} alt='' />
							</div>}
							{info?.image5 && <div className='slider-img' >
								<img src={info?.image5} alt='' />
							</div>}
							{info?.image6 && <div className='slider-img' >
								<img src={info?.image6} alt='' />
							</div>}
							{info?.image7 && <div className='slider-img' >
								<img src={info?.image7} alt='' />
							</div>}
							{info?.image8 && <div className='slider-img' >
								<img src={info?.image8} alt='' />
							</div>}
							{info?.image9 && <div className='slider-img' >
								<img src={info?.image9} alt='' />
							</div>}
							{info?.image10 && <div className='slider-img' >
								<img src={info?.image10} alt='' />
							</div>}
						</div>
						}

					</div>

					</div>
			</div>

			{info?.video_link && info?.video_link != '' && <div className='video-card' onClick={() => dispatch(enableModalVideo(info?.video_link))}>
				<img src={'https://i.ytimg.com/vi/'+info?.video_link+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
				<div className={'play-icon'}  >
					<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
				</div>							
			</div>}
				{/* <div className='blog-detail-rt'> */}
					{/* <div className='search-input'>
						<div className='search-icon'>
							<Icons
								name='common'
								iconType='search'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fillColor='#253D4E'
							/>
						</div>
						<input placeholder='Search Specialities' />
					</div> */}
					{/* <div className='recent-posts'>
						<h4>Recent Posts</h4>
						<BlogArticles blog={[]} />
					</div>
					<div className='recent-videos'>
						<h4>Recent Videos</h4>
						<BlogArticles blog={[]} />
					</div> */}
				{/* </div> */}
			{/* </div> */}
		</div>
	)
}

export default BlogsDetail
