import React from 'react';

import get from 'get-value';

function HeaderIcons(props) {
	const matchIcon = (iconType, iconColor, customIcon) => {
		switch (iconType) {
			case 'user':
				return (
					<>
                        <path d="M14 18.4674C19.4233 18.4674 24 19.3487 24 22.7487C24 26.15 19.3933 27 14 27C8.57797 27 4 26.1187 4 22.7187C4 19.3174 8.60673 18.4674 14 18.4674ZM14 2C17.6739 2 20.6175 4.94253 20.6175 8.61382C20.6175 12.2851 17.6739 15.2289 14 15.2289C10.3274 15.2289 7.38252 12.2851 7.38252 8.61382C7.38252 4.94253 10.3274 2 14 2Z" fill="url(#paint0_linear_788_5824)"/>
                        <defs>
                        <linearGradient id="paint0_linear_788_5824" x1="4.22152" y1="14.5" x2="24" y2="14.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F06426"/>
                        <stop offset="1" stopColor="#E21E29"/>
                        </linearGradient>
                        </defs>
					</>
				)
			case 'menu':
				return (
					<>
                        <path  d="M31.4727 2.24797C31.4727 1.65177 31.2358 1.07999 30.8142 0.658414C30.3927 0.236839 29.8209 0 29.2247 0H2.24911C1.65291 0 1.08113 0.236839 0.659554 0.658414C0.237978 1.07999 0.00114059 1.65177 0.00114059 2.24797C0.00114059 2.84416 0.237978 3.41594 0.659554 3.83752C1.08113 4.25909 1.65291 4.49593 2.24911 4.49593H29.2247C29.8209 4.49593 30.3927 4.25909 30.8142 3.83752C31.2358 3.41594 31.4727 2.84416 31.4727 2.24797ZM31.4727 13.4878C31.4727 12.8916 31.2358 12.3198 30.8142 11.8982C30.3927 11.4767 29.8209 11.2398 29.2247 11.2398H2.24911C1.65291 11.2398 1.08113 11.4767 0.659554 11.8982C0.237978 12.3198 0.00114059 12.8916 0.00114059 13.4878C0.00114059 14.084 0.237978 14.6558 0.659554 15.0773C1.08113 15.4989 1.65291 15.7358 2.24911 15.7358H29.2247C29.8209 15.7358 30.3927 15.4989 30.8142 15.0773C31.2358 14.6558 31.4727 14.084 31.4727 13.4878ZM31.4727 24.7276C31.4727 24.1314 31.2358 23.5596 30.8142 23.1381C30.3927 22.7165 29.8209 22.4797 29.2247 22.4797H15.7369C15.1407 22.4797 14.5689 22.7165 14.1473 23.1381C13.7258 23.5596 13.4889 24.1314 13.4889 24.7276C13.4889 25.3238 13.7258 25.8956 14.1473 26.3172C14.5689 26.7387 15.1407 26.9756 15.7369 26.9756H29.2247C29.8209 26.9756 30.3927 26.7387 30.8142 26.3172C31.2358 25.8956 31.4727 25.3238 31.4727 24.7276Z" fill="url(#paint0_linear_1016_830)"/>
                        <defs>
                        <linearGradient id="paint0_linear_1016_830" x1="31.1241" y1="13.4878" x2="0.00113898" y2="13.4878" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F06426"/>
                        <stop offset="1" stopColor="#E21E29"/>
                        </linearGradient>
                        </defs>
					</>
				)
			case 'menu-expand':
				return (
					<>
						<title>{customIcon || iconType + ' icon'}</title>
						<circle cx='11' cy='11' r='8'></circle>
						<line x1='21' y1='21' x2='16.65' y2='16.65'></line>
					</>
				)
			default:
				undefined
		}
	}

	const className = get(props, 'className', '')
	const iconType = get(props, 'iconType', '')
	const iconColor = get(props, 'iconColor', '#000')
	const customIcon = get(props, 'customIcon', '')
	const width = get(props, 'width', '20px')
	const height = get(props, 'height', '')
	const viewBox = get(props, 'viewBox', '0 0 24 24')
	const onClick = get(props, 'onClick', {})
	const strokeColor = get(props, 'strokeColor', '')
	const strokeWidth = get(props, 'strokeWidth', '')
	const fill = get(props, 'fillColor', 'none')
	return (
		<svg
			className={className}
			viewBox={viewBox}
			stroke={strokeColor}
			strokeWidth={strokeWidth}
			fill={fill}
			onClick={onClick}
			style={{ width: width, height: height }}>
			{matchIcon(iconType, iconColor, customIcon)}
		</svg>
	)
}

export { HeaderIcons };
export default HeaderIcons
