import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { getUserProfile } from 'api/authService'
import { getHospitalsList } from 'api/doctorService'
import { getFacilitiesList, getCategoriesList } from 'api/catalogService'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Toast } from 'primereact/toast'
import bgcol from '../../../assets/bg-col.png'
// import logo from '../../../assets/BMJH-Girinagar.png'
// import logo from '../../../assets/BMJH-Girinagar.png'
import nab from '../../../assets/nab-j.png'
import nab1 from '../../../assets/nab-v.png'
import caring from '../../../assets/caring.png'
import { Icons, SecondaryHeader } from 'components'
import { enableToast as clearToast } from 'store/header'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { getSubCategoriesList } from 'api/catalogService'
import './Header.scss'
import { Dropdown } from 'primereact/dropdown'
import ModalVideo from 'react-modal-video'
import {enableModalVideo as disableModalVideo } from 'store/header'

function Header(props) {
	const dispatch = useDispatch()
	const { isRegisteredUser, userProfile, enableToast,enableLocation,staticInfos,enableModalVideo  } = useSelector(
		state => state.header
	)
	const { facilityList } = useSelector(state => state.categories)
	const getCatList = useSelector(state => state.categories.categoriesList)
	const { hospitalList, selectedHospital } = useSelector(
		state => state.doctorsInfo
	)
	const toast = useRef(null)

	useEffect(() => {
		dispatch(getUserProfile())
	}, [isRegisteredUser])

	useEffect(() => {
		if (!isEmpty(enableToast)) {
			let severity = enableToast?.http_code == 200 ? 'success' : 'error'
			let summary = enableToast?.message || ''
			let detail = enableToast?.detail || ''

			toast.current.show({
				severity,
				summary,
				detail,
				life: 5000,
			})
			setTimeout(() => {
				dispatch(clearToast({}))
			}, '6000')
		}
	}, [enableToast])

	useEffect(() => {
		if (isEmpty(hospitalList)) {
			 	dispatch(getHospitalsList())
		}
		dispatch(setSelectedHospital(hospitalList?.[0]?.id) || null)
	}, [hospitalList])

	useEffect(() => {
		// if (isEmpty(facilityList)) {
			selectedHospital && dispatch(getFacilitiesList({ hospital_id: selectedHospital }))
		// }/
			selectedHospital && dispatch(getSubCategoriesList({hospital_id:selectedHospital}))
		// if (isEmpty(getCatList)) {
			selectedHospital && dispatch(getCategoriesList({ hospital_id: selectedHospital }))
		// }
	},[selectedHospital])


	// const [location, setLocation] = useState()
	const optionsList = hospitalList?.map(l => ({
		name: l?.name,
		id: l?.id,
	}))

	// const handleKeyDown = (event) => {
	// 	if (event.key === 'Enter') {
	// 		props?.onClick(searchValue)
	// 	}
	// }

	return (
		<>
			<div className='header'>
				<img className='bg-col' src={bgcol} alt='' />
				<nav className='main-width container'>
					<ul className='d__flex header-row m-d-flex'>
						<li>
							<Link to={'/'}>
							
							{/* ${selectedHospital!=1?'BMJH-Girinagar.png':'BMJH-V-Logo.png'} */}
								<img className='logo-image' src={require(`../../../assets/logo-trans.png`)} alt='' />
							</Link>
						</li>
						<li className='d__flex'>
							<Link to={ selectedHospital == 1 ? '/pharmacy/nabh':'/pharmacy/nabh-girinagar'}>
								<img className='nab-image' src={selectedHospital == 1?nab:nab1} alt='' />
							</Link>
							{hospitalList?.map((item, index) => (
								<button 
									
									key={index}
									onClick={() => enableLocation && dispatch(setSelectedHospital(item?.id))}
									className={`locate-btn ${
										selectedHospital !== item?.id ? 'inactive-btn' : null	
									} ${!enableLocation ? 'opacity-5' : ''}` }>
									<span />
									{item?.name}
								</button>
							))}


							
						
							
							<img src={caring} className='hide-mb' height={40} alt='' />
							{/* <Link to={'/appointment/normal'}>
								<button className='apmnt-btn'>
									<div className='d__flex icon-ctr'>
										<Icons
											name='my-account'
											iconType='appointment'
											width='25'
											height='25'
											viewBox='0 0 20 20'
										/>
									</div>
									Book Appointment
								</button>
							</Link> */}
						</li>
					</ul>
					
				</nav>
				<Toast ref={toast} />
			
			</div>
			<div className='header-2 container'>
			<div className='mobile-location'>
								<Icons className='location-icon' name='home' iconType='location-white' width='18' height='20' viewBox='0 0 18 26' fill="#fff"  />
								<Dropdown optionLabel='name' disabled={!enableLocation} value={selectedHospital} optionValue='id' options={optionsList} onChange={e => dispatch(setSelectedHospital(e?.value))} className='location-dropdown'/>
							</div>
				<SecondaryHeader
					navFacilityList={facilityList?.list}
					navSpecList={getCatList}
				/>
				
			</div>
			<a href={`tel:${staticInfos?.[ selectedHospital == 1 ?'girinagar_emergency_phone':'vasanthnagar_emergency_phone']?.value}`} className='quick d__flex m-d-flex gap-10'>

			{/* <a href={`tel:${staticInfos?.[ selectedHospital == 1 ?'girinagar_emergency_phone':'']?.value}`} className='quick d__flex m-d-flex gap-10'> */}
				
			 <div className='blob'></div> 

			 	Emergency
				 {/* <i class='pi pi-chevron-up'></i> */}
			</a>
			<ModalVideo channel='youtube' autoplay="1" isOpen={enableModalVideo} videoId={enableModalVideo} onClose={() => dispatch(disableModalVideo(undefined))} />
		</>
	)
}

export { Header }
export default Header
