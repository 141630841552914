import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getCmsDetails } from '../../../api/cmsService';
import { CustomizeHeading } from '../../components';

function Faq() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [info,setInfo] =  useState({});
    const getDetailData = async () => {
		const response = await dispatch(getCmsDetails({url:id}))
        setInfo(response)
	} 


  useEffect(() => {  
    id && getDetailData();
    return () => {
    }
  }, [id])

  return (
    <div className='mar-b-135 container pharmacy-information'>
      	<Helmet>
				  <title>BMJH | FAQ</title>
			  </Helmet>
        {info && <>
          <CustomizeHeading title={info?.title }/>
          <div dangerouslySetInnerHTML={{__html: info?.content}} />
        </>}
    </div>
  )
}

export default Faq