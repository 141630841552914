import React from 'react'
import { Link } from 'react-router-dom'
import './MedicalPackage.scss'

function MedicalPackage({ packageInfo }) {
	return (
		<div className='medical-package-box d__flex'>
			<div className='image-ctr'>
				<img src={packageInfo?.image1} alt='' onError={((e) => { (e.target.src = require('../../../assets/default/Specialties-list.png') )})} />
				<div className='d__flex m-d-flex gap-20'>
					<h2 className='price mb-0'>₹ {packageInfo?.discounted_price}</h2>
					<h1 className='s-price mb-0'>₹ {packageInfo?.price}</h1>
				</div>
			</div>
			<div className='flex-1 packages-infoo'>
				<Link to={'/medical-packages/' + packageInfo?.id}>
					<h3>{packageInfo?.name_en}</h3>
				</Link>
				<p className='package-length'>
					Total Parameters : {packageInfo?.parameters?.length}
				</p>
				<div>
					<p className='package-desc m-0 fnt-12'>
						{packageInfo?.parameters[0]?.name}
					</p>
					<div className='d__flex m-d-flex gap-10'>
						<p className='package-desc m-0 fnt-12'>
							{packageInfo?.parameters[1]?.name}
						</p>
						<span className='more'><Link to={'/medical-packages/' + packageInfo?.id}>+ More</Link> </span>
					</div>
				</div>
				<Link to={'/medical-packages/' + packageInfo?.id}>
					<button>Book Appointment</button>
				</Link>
			</div>
		</div>
	)
}

export default MedicalPackage
