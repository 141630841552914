import React from 'react'
import './ImagesGrid.scss'

function ImagesGrid({data}) {
  // console.log(data)
  // let data = ['https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg','https://bmjh-dev-app.s3.amazonaws.com/Cms/178_cms2_1671786566.jpg']
  return (
    <div className='container padd-0'>
          <div className='d-flex flex-wrap images-grid'>
            {
              data?.map((v,i) =>
                <div key={i+'IG'} className='slider-imgs'>
                    <img src={v} alt='' />
                </div>
              )
            }
        </div>
    </div>
  )
}

export default ImagesGrid