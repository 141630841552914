import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty, clone, flatten, uniqBy, isArray } from 'lodash'
import { TabView, TabPanel } from 'primereact/tabview'
import { DoctorBoxMiniScroll,DoctorBoxMini, Icons, TitleCard } from 'components'
import './MeetDoctors.scss'
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux'
import { getDoctorsListPaginated } from 'api/doctorService'
import HomeDoctorCard from '../HomeDoctorCard/HomeDoctorCard'

function MeetDoctors(props) {
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(null)
	const [activeIndex,setActiveIndex] = useState(0)
	const [doctorsList,setDoctorList] = useState([])
	const [nav1, setNav1] = useState(); 
	const [nav2, setNav2] = useState();


	const { recentlyVisited, departments, selectedHospital } = useSelector(state => state?.doctorsInfo)

	const getDoctorList = async (p) => {
		// page: page ,per_page: 20
		const response = await dispatch(getDoctorsListPaginated({ hospital_id: selectedHospital,specialitie_id :  activeTab == null || activeTab == 0 ? undefined : activeTab.toString(),page:1, per_page: 20 }))
		if(response){
			const { list, ...res} = response
			// console.log("doctor list",list)
			// page == 1 ? setDoctorList(list) : setDoctorList([...doctorsList,...list])
			// let v = res
			setDoctorList(list)
			// setDoctorListInfo(v)
		}
	
	}

	useEffect(() => {
		// isEmpty(subCategoriesList) && selectedHospital && dispatch(getSubCategoriesList({hospital_id:selectedHospital}))
		selectedHospital && (setActiveIndex(0),setActiveTab(null))
		selectedHospital && getDoctorList()
	}, [selectedHospital])


	useEffect(() => {
		// if(page != 0){
			selectedHospital && getDoctorList()
		// }
	}, [activeTab])

	// useEffect(() => {

	// 	getDoctorList()
	
	//   return () => {
		
	//   }
	// }, [])
	




	const getDoctorsList = !isEmpty(props?.data?.placeholders) ? props?.data?.placeholders[0]?.doctors : []

	const { subCategoriesList } = useSelector(state => state?.categories)

	let getSpecialities  = [{id: '00',name: 'All Department',},...subCategoriesList]

	const changed = (e) => {
		// const changed = (e) => {
			setActiveIndex(e)
			// console.log(e,activeTab);
			getSpecialities.map((v,i)=>{
				if(e == i) {
					
					if(activeTab != v?.id){
						
						
						if(v?.id != '00'){
							// setPage(1)
							setDoctorList([])
							// setDoctorListInfo({})
							setActiveTab(v?.id)
						} else{
							if(activeTab != null){
								// setPage(1)
								setDoctorList([])
								// setDoctorListInfo({})
							}
							setActiveTab(null)
						}
						// console.log("department id",v?.id)
						// setPage(1)
					}

				}
			})
		// }
	}

	// .filter(t => t.book_appointment == 1 )

	// if(getSpecialities && getSpecialities?.length){
	// 	getSpecialities = [...{id: 100001,name: 'All Department',},...getSpecialities]
	// 	// getSpecialities.push({id: 100001,name: 'All Department',})
	// }
	// getSpecialities.unshift({id: 100001,name: 'All Department',})

	// getSpecialities = uniqBy(flatten(getSpecialities), 'id')



	// slidesToShow={4}
	// swipeToSlide={true}
	// focusOnSelect={true}
	// centerPadding={'20px'}
	// const nav2 = useRef();

	

	let sliderSetting =  {
		// initialSlide: 5,
		// centerPadding: "20px",
		slidesToShow: 4,
		swipeToSlide : true,
		infinite: false,
		// speed: 500,
		// autoplay: true,
		// dots :true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 4,
				// infinite: false,
				// initialSlide: 1,
				dots: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				// initialSlide: 1,
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				// initialSlide: 1,
				arrows : false
			  }
			}
		]
	};
	

	const [sliderSettings,setSliderSettings] = useState(sliderSetting)

	const tabChanged = async (e) => {
		setActiveTab(e.index)
	}


	// const [sliderSettings,setSliderSettings] = useState(sliderSetting)

	// const tabChanged = async (e) => {
	// 	setActiveTab(e.index)
	// }

	// const [getDoctors,setDoctors] = useState([]);

	// const tabChanged = async (e) => {
	// 	setActiveTab(e.index)	
				
		
	// 	let d = []

	// 	getSpecialities.map((t, index) => {
	// 		if (e.index == 0) {
	// 			d = getDoctorsList
	// 		} else {
	// 			d = getDoctorsList?.filter(d => d?.specialities_id.includes(t?.id?.toString()))
	// 		}
	// 	})

	// 	setDoctors(d)

	// 	if(d?.length > 4 ) {
	// 		setSliderSettings({sliderSettings,...{infinite:false}})
	// 	} else {
	// 		setSliderSettings({sliderSettings,...{infinite:true}})
	// 	}
	// 	// {? s : setSliderSettings({sliderSettings,...{infinite:false}}); }
		
	// }


	return (
		<section className='meet-doctors'>
			<div className='container'>
				<div className=''>
					<TitleCard title={props?.data?.name} description={props?.data?.description}/>

				<TabView activeIndex={activeIndex} onTabChange={(e) => changed(e.index)} scrollable>

						{getSpecialities.map((t, index) =>  {

							return (
								<TabPanel key={index} header={t?.name}>
								
									<Slider asNavFor={nav2}  initialSlide={0} ref={(e) => setNav1(e)}  className={'doctor-detail'} >
										{doctorsList?.map((d, index) => (
										<div  key={index+"SL"}>
											<HomeDoctorCard d={d} />
										</div>			
										))}
									</Slider>

									<div className='d__flex gap-15 just-end arrows-prev-next m-d-flex'>
										<button className=''>
											<Icons
												name='common'
												iconType='arrow-prev'
												width='15px'
												strokeWidth='0'
												strokeColor='#ffff'
												viewBox='0 0 30 29'
											/>{' '}
										</button>
										<button >
											<Icons
												name='common'
												iconType='arrow-next-white'
												width='15px'
												strokeWidth='0'
												strokeColor='#ffff'
												viewBox='0 0 30 29'
											/>{' '}
										</button>
									</div>

									{doctorsList.length ? <Slider asNavFor={nav1}  ref={(e) => setNav2(e)} initialSlide={0} {...sliderSettings} {...{ infinite : doctorsList?.length > 4 ?true:false }} className={'list-doctors-slides'}>
										{doctorsList.length && doctorsList?.map((res, index) => (
											<div className='doctor-item-list' key={index + 'dbx'}>
												<DoctorBoxMini doctor={res} small={false} />
											</div>
										))}
									</Slider> : <></>}

								</TabPanel>
							)
						})}
					</TabView>
				</div>
			</div>
		</section>
	)
}

export default MeetDoctors
export { MeetDoctors }
