import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import {
	enableAppointment,
	enableLoginModal,
	enableTimingPopup,
	enableToast
} from 'store/header'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
import { getDoctorAvailableSlots,getVisitors } from 'api/appointmentService'
import { isNull, isNaN } from 'lodash'
import { useNavigate } from 'react-router-dom'
import './DateTime.scss'

function DateTime(props) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const nextDate= moment(new Date(),"DD-MM-YYYY").add(1,'days')

	// const   = new Date().setDate(nextDate.getDate() + 1);
	// const d = () =>  ;
	// const  = new Date(new Date.setDate(new Date.getDate() + 1))
	const [date, setDate] = useState(new Date())	
	const [getAvailableSlot, setAvailableSlot] = useState([])
	const [getSelectedSlot, setSelectedSlot] = useState(null)
	const [enableSubmitBtn, setEnableSubmitBtn] = useState(true)
	const [visitors,setVisitors] = useState([])
	const methods = useFormContext()


	// function splitGivenTime()
    // {
    //     getMorningTime = []
    //     getAfternoonTime = []
    //     getEveningTime = []
    //     getNightTime = []
    //     for (_,value) in getFullTime.enumerated()
    //     {
    //         let dateFormatterGet = DateFormatter()
    //         dateFormatterGet.dateFormat = "yyyy-MM-dd HH:mm:ss"
    //         if let getDate = dateFormatterGet.date(from: value.slot_start_time)
    //        {
    //             let hour = Calendar.current.component(.hour, from: getDate)
        
    //             if(getDate > Date())
    //             {
    //                 value.isAvailable = true
    //             }else
    //             {
    //                 value.isAvailable = false
    //             }
                    
    //             switch hour {
    //             case 6..<12 :
    //                 getMorningTime.append(value)
    //                 print(NSLocalizedString("Morning", comment: "Morning"))
    //             case 12..<17 :
    //                 getAfternoonTime.append(value)
    //                 print(NSLocalizedString("Afternoon", comment: "Afternoon"))
    //             case 17..<22 :
    //                 getEveningTime.append(value)
    //                 print(NSLocalizedString("Evening", comment: "Evening"))
    //             default:
    //                 getNightTime.append(value)
    //                 print(NSLocalizedString("Night", comment: "Night"))
    //             }
    //         }
            
    //     }
    //     self.tableView.reloadSections([1,2,3,4], with: .automatic)
        
    // }


	useEffect(() => {
		!props.packageBooking && getAvailableSlotReq(date)
	},[props.packageBooking,props?.visitor_type_id])
	

	useEffect(() => {

		if(!props.packageBooking){
			if (
				!isNaN(props?.speciality_id) &&
				!isNaN(props?.speciality_id) &&
				!isNull(getSelectedSlot)
			) {
				setEnableSubmitBtn(false)
			} else {
				setEnableSubmitBtn(true)
			}
		} else {
			setDate(new Date(nextDate))
			setEnableSubmitBtn(false)
		}

	}, [getSelectedSlot])

	const { hospitalList, selectedHospital } = useSelector(
		state => state?.doctorsInfo
	)

	const { isRegisteredUser, userProfile } = useSelector(state => state?.header)

	const getAvailableSlotReq = async selectedDate => {
		let response = await dispatch(
			getDoctorAvailableSlots({
				doctor_id: props?.doctorId,
				date: moment(selectedDate).format('DD-MM-YYYY'),
				visit_type:props?.visitor_type_id
			})
		)

		response?.map(value => {
			let d = moment(value.slot_start_time).format('yyyy-MM-dd HH:mm:ss')
			let current = moment(selectedDate).format('yyyy-MM-dd HH:mm:ss')
               d > current ? (value.availablity_status == 1 ? value.availablity_status = true : null) : value.availablity_status = false
			//    d > current ? value.availablity_status = true  : value.availablity_status = false
		})

		// let hour = Calendar.current.component(.hour, from: getDate)

		setAvailableSlot(response)
	}

	const handleDateChange = e => {
		setDate(e.value)
		!props.packageBooking && getAvailableSlotReq(e.value)
		setSelectedSlot(null)
	}

	const continue_to = e => {
		
		if (isRegisteredUser && userProfile.email != '') {
			dispatch(enableTimingPopup(false))
			navigate('/checkout', {
				state: {
					hospital_id: selectedHospital,
					language_id: 1,
					doctor_id: props?.doctorId,
					speciality_id: props?.speciality_id,
					covid_info: props?.covid_info,
					visitor_type_id: !props.packageBooking ? props?.visitor_type_id : 1 ,
					authentication_device_id: userProfile?.authentication_id,
					[!props.packageBooking?'appointment_from_time':'package_date']: moment(!props.packageBooking?getSelectedSlot:date).format(
						!props.packageBooking ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
					),
					doctorInfo: props?.doctorInfo,
					doctor_fees: !props.packageBooking ? parseInt(props?.doctorInfo?.doctor_fees) : props?.packagePrice,
					speciality_name: props?.doctorInfo?.specialities?.find(
						s => s?.id == props?.speciality_id
					)?.name,
					location: hospitalList?.find(f => f?.id == selectedHospital)?.name,
					package_id : props?.package_id,
					packageName : props?.packageInfo?.name_en,
					packageInfo : props?.packageInfo
				},
			})
		} else {
		 	!isRegisteredUser ? dispatch(enableLoginModal()) : ( enableToast({http_code:400,message:'Please Fill Profile info'}), navigate('/profile/edit'))
		}
	}

	// const [visitorType,setVisitorType] = useState(1);

	// const collectVisitors = async () => {
	// 	const response = await dispatch(getVisitors({}))
	// 	setVisitors(response)
	// }

	// useEffect(() => {
		
	// 	props.packageBooking &&	collectVisitors()
	
	//   return () => {
	// 	// second
	//   }
	// }, [])
	


	

	return (
		<div className='date-time-ctr'>



			<div>
			<h5>{!props.packageBooking ? 'Choose Date and Time' : 'Choose Date'}</h5>
			{/* Date {JSON.stringify(date)} <br /> next {JSON.stringify(nextDate)} */}
			<div>
				<p className='title-mb'>Date</p>
				<div className='d__flex dates m-d-flex position-relative'>
					<Calendar
						id='basic'
						value={date}
						readOnlyInput
						onChange={handleDateChange}
						minDate={!props.packageBooking?new Date():new Date(nextDate)}
						dateFormat='DD, dd M, yy'
						showIcon
						// disabledDates={[new Date()]}
					/>
				</div>
				<p className='title-mb'>Time</p>
				<div className='d__flex align-start timings'>
					<span></span>
					{getAvailableSlot ? (
					<ul className={`slots-wrapper w-100`}>
						{getAvailableSlot?.map((value, i) => (
							<li key={i} className={`time-item ${ value?.slot_start_time == getSelectedSlot ? 'isSelected' : ''} 
							${ value?.availablity_status == '0' || moment(value?.slot_start_time).isSame( moment().format('YYYY-MM-DD HH:mm')) ? 'isBooked': ''}`}>
								<label className='time-item-label' htmlFor={`timeSlots-${i}`}>
									<input className='p-hidden' id={`timeSlots-${i}`} name='timeSlots' type='radio' value={value?.slot_start_time} onChange={e => setSelectedSlot(e.target?.value)}/>
									{moment(value?.slot_start_time).format('hh:mm a')}
								</label>
							</li>
						))}
					</ul>
					) : (
						<p className='w-100 txt-center'>No slots available for the day</p>
					)}

					<span></span>
				</div>
			</div>
			</div>

			<div className='txt-right'>
				<button
					disabled={enableSubmitBtn}
					className='continue-btn'
					onClick={e => continue_to(e)}>
					Continue
				</button>
			</div>
		</div>
	)
}

export default DateTime
