import React, { useEffect } from 'react'
import './NewsletterBox.scss'

function NewsletterBox({newsletter,openPopup}) {


  return (
    <div className='blog-card news-box' onClick={() => openPopup()}>
        {/* <div className='image-ctr'>
            <img src={newsletter?.image1} onError={((e) => (e.target.src = require('../../../assets/default/home-blog.png') ))} alt='' />
        </div> */}
        <div className='blog-info'>
           <p className='title'>{newsletter?.title}</p>
          <p className='newsletter-desc' title={newsletter?.description}>{newsletter?.description}</p>
        </div>
        <div className='txt-right'>
          <button>View Newsletter</button>
        </div>
    </div>
  )
}

export default NewsletterBox