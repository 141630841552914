import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Icons, TitleCard } from 'components'
import './Departments.scss'

function Departments(props) {
	const [activeTab, setActiveTab] = useState()
	const getCategoriesList = !isEmpty(props?.data?.placeholders)
		? props?.data?.placeholders[0]?.departments
		: []

	useEffect(() => {
		setActiveTab(getCategoriesList[0]?.id)
	}, [props])
	
	return (
		<div className='container'>
			<div className='departments wid-92 mar-auto'>
				<TitleCard
					title={props?.data?.name}
					description={props?.data?.description}
				/>
				<div className='d__flex align-start department flex-w'>
					<div className='department-left'>
						<ul>
							{getCategoriesList?.map(res => (
								<li
									key={res?.id}
									className={`${res?.id == activeTab ? 'active' : ''}`}
									onClick={() => setActiveTab(res?.id)}>
									{res?.name}
								</li>
							))}
						</ul>
					</div>
					<div className='department-right flex-1'>
						<div className='d__flex flex-w departments-sec gap-20'>
							{getCategoriesList?.map(
								list =>
									list?.id == activeTab &&
									list?.subcategory?.slice(0,7)?.map((item, i) => (
										<div className='department-item' key={i}>
											<Link to={`/specialties/${item?.id}`} >
												<div>
													<img className='h-auto w-full' src={item.icon} alt='' />
													{/* <Icons
														name='specialty'
														iconType='view-all'
														width='90'
														height='90'
														fillColor='#4C4372'
														viewBox='0 0 90 90'
													/> */}
												</div>
												<p>{item?.name}</p>
											</Link>
										</div>
									))
							)}
							<div className='department-item' >
								<Link to={`/specialties#`+activeTab}>
									<div className='d__flex m-d-flex just-c'>
										<Icons
											name='specialty'
											iconType='view-all'
											width='40'
											height='40'
											fillColor='#4C4372'
											viewBox='0 0 50 50'
										/>
									</div>
									<p>View All</p>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Departments
export { Departments }
