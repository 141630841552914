import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import './BlogBox.scss'

function BlogBox({ blog }) {
	return (
		<div className='blog-card'>
			<div className='image-ctr'>
				<Link to={`/blog-detail/${blog?.slug}`}>
					<img src={blog?.image ? blog?.image : require('../../../assets/default/home-blog.png')} onError={((e) => (e.target.src = require('../../../assets/default/home-blog.png') ))} alt='' />
				</Link>
			</div>
			<div className='blog-info'>
				<div className='d__flex just-s m-d-flex'>
					<div className='fnt-12 department'>{blog?.type_name}</div>
					<p className='date-time'>{moment(blog?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
				</div>
				<Link to={`/blog-detail/${blog?.slug}`}>
					<p className='title'>{blog?.title}</p>
					{/* <p className='title' dangerouslySetInnerHTML={{__html: blog?.content}}></p> */}
				</Link>
				<div className='d__flex gap-15 avatar  m-d-flex'>
					<img src={blog?.author_image ? blog?.author_image : require('../../../assets/default/blog-author-image.png') } onError={((e) => (e.target.src = require('../../../assets/default/blog-author-image.png') ))} alt='' />
					<h5>{blog?.author_name}</h5>
				</div>
			</div>
		</div>
	)
}

export default BlogBox
export { BlogBox }
