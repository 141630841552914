import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, concat, isArray, isNull } from 'lodash'
import { getRecentlyVisitedDoctors, findDoctorsWithNode } from 'api/doctorService'
import { DoctorBoxMiniScroll, DoctorBoxScroll, Icons } from 'components'
import { enableAppointment as setEnableAppointment } from 'store/header'
import { Appointments } from 'pages'
import './Doctors.scss'
import { Helmet } from 'react-helmet'
import { Dropdown } from 'primereact/dropdown'
import { getSubCategoriesList } from 'api/catalogService'
import { findDoctors } from 'api/doctorService'
import { AutoComplete } from 'primereact/autocomplete';
import { getDoctorsListPaginated } from 'api/doctorService'
import { TabView, TabPanel } from 'primereact/tabview';
import { enableToast } from '../../../store/header'
import { getLanguages, getVisitors } from '../../../api/appointmentService'
import { useSearchParams } from 'react-router-dom'

function Doctors() {
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(null)
	const [isSearchView, setSearchView] = useState(false)
	const [filteredData, setFilteredData] = useState([])
	const [specSearch, setSpecSearch] = useState('')
	const [doctorSearch, setDoctorSearch] = useState('')
	const [selectedSpeciality,setSelectedSpeciality] = useState('')
	const [doctorsList,setDoctorList] = useState([])
	const [doctorsListInfo,setDoctorListInfo] = useState({})
	const [page,setPage] = useState(1)
	const [doctorInfo,seDoctorInfo] = useState({})
	const [visitors,setVisitors] = useState([])
	let [searchParams, setSearchParams] = useSearchParams()
	const [activeVisitId,setActiveVisitId] = useState(searchParams.get('visit_type'))
	const { isRegisteredUser } = useSelector(state => state.header)
	const { recentlyVisited, departments, selectedHospital } = useSelector(state => state?.doctorsInfo)
	

	const { subCategoriesList } = useSelector(state => state?.categories)
	const [activeIndex,setActiveIndex] = useState(0)


	useEffect(() => {
		isEmpty(subCategoriesList) && selectedHospital && dispatch(getSubCategoriesList({hospital_id:selectedHospital}))
		selectedHospital && (setActiveIndex(0),setActiveTab(null))
		selectedHospital && getDoctorList()
		selectedHospital && dispatch(getRecentlyVisitedDoctors({ hospital_id: selectedHospital }))
		setPage(1)
		return () => {
			dispatch(setEnableAppointment([]))
		}
		// dispatch(getDoctorsList({ hospital_id: selectedHospital,page: page ,per_page: 20 }))
	}, [selectedHospital])


	const getDoctorList = async (p) => {
		// page: page ,per_page: 20
		const response = await dispatch(getDoctorsListPaginated({ hospital_id: selectedHospital,page: page ,specialitie_id :  activeTab == null || activeTab == 0 ? undefined : activeTab.toString(), per_page: 20,visit_type:activeVisitId}))

		if(response){
			const { list, ...res} = response
			page == 1 ? setDoctorList(list) : setDoctorList([...doctorsList,...list])
			let v = res

			setDoctorListInfo(v)
		}
	  	isEmpty(visitors) && getVisits()
	}

	const getVisits = async () => {
		const response = await dispatch(getVisitors({}))
		setVisitors(response)
	}

	useEffect(() => {
		if(page != 0){
			selectedHospital && getDoctorList()
		}
	}, [page,activeTab,activeVisitId])

	useEffect(() => {
		setActiveVisitId(searchParams.get('visit_type') == null ? undefined :searchParams.get('visit_type') )
	},[searchParams.get('visit_type')])

	const { enableAppointment } = useSelector(state => state?.header)

	const handleSearch = () => {
		if (doctorSearch == '') {
			setSearchView(false)
			setDoctorSearch('')
		} else if (specSearch == '') {
			setSearchView(false)
			setSpecSearch('')
		}
		
		if (doctorSearch != '' || specSearch != '') {
			let getFilteredData = doctorsList?.filter(f => {
				let name = f?.firstname + ' ' + f?.lastname
				return (
					(name != '' ? name?.toLowerCase()?.includes(doctorSearch) : false) &&
					(f?.specialities_names != ''
						? f?.specialities_names?.toLowerCase()?.includes(specSearch)
						: false)
				)
			})
			setSearchView(true)
			setFilteredData(getFilteredData)
		}
	}

	// const [catList,setCatList] = useState(subCategoriesList)
	// function searchCountry(event) {
    //     setTimeout(() => {
    //         let filteredCountries;
    //         if (!event.query.trim().length) {
    //             filteredCountries = subCategoriesList;
    //         }
    //         else {
    //             filteredCountries = subCategoriesList.filter((country) => {
    //                 return country.name.toLowerCase().startsWith(event.query.toLowerCase());
    //             });
    //         }
	// 		setCatList(filteredCountries)
    //         // this.setState({ filteredCountries });
    //     }, 550);
    // }


	const getSearchList = async (event) => {
		event.preventDefault();
		// let v = subCategoriesList?.find(r => r.name == selectedSpeciality)
		
		// !v?.id && dispatch(enableToast({http_code: 400,message:'Please select Speciality'}))
		if(doctorSearch == ''){
			setSearchView(false)
			setFilteredData([])
		} else {
			// console.log("searching department",activeTab)
			// const response = await dispatch(
			// 	findDoctors({
			// 		hospital_id: selectedHospital,
			// 		key: doctorSearch == ''?undefined:doctorSearch,
			// 		speciality_id : activeTab
			// 	})
			// )
			// let doc = response?.doctor_list
			// let docs = doc.filter(v=> {return v != null})
			// setFilteredData(docs)
			const r = await dispatch(
				findDoctorsWithNode({
				hospital_id: selectedHospital,
				specialitie_id : activeTab ? activeTab?.toString() : undefined,
				key: doctorSearch == ''?undefined:doctorSearch,
			}))

			setSearchView(true)
			
			setFilteredData(r?.data)
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			getSearchList(event)
		}
	}

	function itemTemplate(item) {
        return (
            <div className="country-item">
                {/* <img alt={item.name} src={`images/flag/flag_placeholder.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${item.code.toLowerCase()}`} /> */}
                <div>{item.name}</div>
            </div>
        );
    }



	const changed = (e) => {
		// const changed = (e) => {
			setActiveIndex(e)
			// console.log(e,activeTab);
			renderTabs.map((v,i)=>{
				if(e == i) {
					
					if(activeTab != v?.id){
						
						
						if(v?.id != '00'){
							setPage(1)
							setDoctorList([])
							setDoctorListInfo({})
							setActiveTab(v?.id)
						} else{
							if(activeTab != null){
								setPage(1)
								setDoctorList([])
								setDoctorListInfo({})
							}
							setActiveTab(null)
						}
						// console.log("department id",v?.id)
						// setPage(1)
					}

				}
			})
		// }
	}

	
	const renderTabs = concat({ id: '00', name: 'All Department' ,book_appointment :1 }, subCategoriesList)

	return (
		<div className='doctors mar-b-135 container'>
			<Helmet>
				<title>BMJH | Doctors</title>
			</Helmet>

			<h1 className='txt-center custom-heading'> Meet Our <span>Consultants</span></h1>


			<form className='d__flex doctors-search gap-20'>
				
				<div className='search-item d__flex m-d-flex'>
					<div className='icon-box'>
						<Icons name='doctor' iconType='broken-heart' strokeWidth='0' fillColor='#ffff' viewBox='0 0 30 29'/>
					</div>
					<select value={activeVisitId} onChange={e => setActiveVisitId(e.target.value != 'All'? e.target.value : undefined) }>
						<option value={undefined}>All</option>
						{visitors.map((v,i) =>
						<option key={i+"VI"} value={v?.id} >{v.name}</option>
						)}
					</select>
				</div>

				<div className='search-item d__flex m-d-flex'>
					<div className='icon-box'>
						<Icons name='doctor' iconType='broken-heart' strokeWidth='0' fillColor='#ffff' viewBox='0 0 30 29'/>
					</div>
					{/* 
					<input value={specSearch} placeholder='Specialities'
						onChange={e => e?.target?.value == '' ? (setSearchView(false), setSpecSearch('')) : setSpecSearch(e?.target?.value)}/>
					<i
						onClick={() => (
							doctorSearch == '' && setSearchView(false), setSpecSearch('')
						)}
						className={`reset-search-inp pi pi-times ${
							specSearch?.length > 0 ? '' : 'p-hidden'
						}`}
					/> */}
												
					{/* <Dropdown
						required={true}
						style={{border:'unset'}}
						value={selectedSpeciality}
						options={[...renderTabs]}
						optionLabel='name'
						optionValue='name'
						placeholder='Specialties'
						className='mr-30'
						onChange={e => (setSelectedSpeciality(e.target.value) ,dropdownChanged(e.target.value))}
					/>  */}

				

					<select value={activeIndex} onChange={e => changed(Number(e.target.value)) }>
						{renderTabs.map((v,i) =>
						<option key={i+'RT'} value={i} >{v.name}</option>
						)}
					</select>

					{/* <AutoComplete value={selectedSpeciality} suggestions={catList} completeMethod={searchCountry} field="name" dropdown forceSelection itemTemplate={ itemTemplate} onChange={e => (setSelectedSpeciality(e.target.value))} /> */}
				</div>

				<div className='search-item d__flex m-d-flex'>
					<div className='icon-box'>
						<Icons name='doctor' iconType='user' strokeWidth='0' fillColor='#ffff' viewBox='0 0 30 30'/>
					</div>
					<input
						value={doctorSearch}
						placeholder='Search Doctor'
						required={true}
						onChange={e =>
							e?.target?.value == ''
								? (setSearchView(false), setDoctorSearch(''))
								: setDoctorSearch(e?.target?.value)
						}
						onKeyPress={handleKeyDown}
					/>
					<i onClick={() => (
							specSearch == '' && setSearchView(false), setDoctorSearch('')
						)}
						className={`reset-search-inp pi pi-times ${ doctorSearch?.length > 0 ? '' : 'p-hidden' }`}
					/>
				</div>

				{/* 	<div className='search-item d__flex m-d-flex'>
					<div>
						<Icons name='doctor' iconType='calendar' strokeWidth='0' fillColor='#ffff' viewBox='0 0 22 25'
						/>
					</di
					<input type='text' placeholder='date' />
				</div> */}

				<button type='submit' onClick={(e) => getSearchList(e)} className='flex-1 search-btn'>
					Search
				</button>

			</form>


			<div>
				{isRegisteredUser && !isSearchView &&
					recentlyVisited && recentlyVisited?.length != 0 && (
						<DoctorBoxMiniScroll title='Recent Appointments' doctors={recentlyVisited} small={true}/>
					)}
			</div>

			{
			<div>
				{!isSearchView && (
					<div className='all-doctors'>

						{/* <div className='d__flex m-d-flex tabs gap-20'>
							{renderTabs?.map((t, index) => (
								<div
									key={index}
									className={`cursor-pointer ${
										activeTab == t?.id ? 'active' : ''
									}`}
									onClick={() => setActiveTab(t?.id)}>
									{t?.name}
								</div>
							))}
						</div> */}

					
						<TabView activeIndex={activeIndex} onTabChange={(e) => changed(e.index)} scrollable>
							{renderTabs?.map((t, index) => (
								t.book_appointment == 1 && <TabPanel
									key={t?.id}
									header={t?.name}
									// className={`cursor-pointer ${
									// 	activeTab == t?.id ? 'active' : ''
									// }`}
									// onClick={() => setActiveTab(t?.id)}
									>
									{/* {t?.name} */}
									{/* {activeTab}
									{t?.name} */}
								</TabPanel>
							))}
						</TabView>

						{
							isArray(doctorsList) && !isEmpty(doctorsList) ? 
							<DoctorBoxScroll activeID={activeTab} list={doctorsList} />
							:
							<>
							{doctorsListInfo?.count == 0 && 'No Doctors List Found!'}
							</>
						}
					</div>
				)}

				{isSearchView && <div>
					{!filteredData.length && <p> No Results Found!</p>}
					<DoctorBoxScroll activeID='00' list={filteredData} />
				</div>}
			</div>
			}

			{ !doctorSearch && !activeTab && !isEmpty(doctorsList) && doctorsList?.length != doctorsListInfo?.total_count && <div className='txt-center'>
				<button className='doctor-box-scroll-more' onClick={() => setPage(page+1)}>Load More</button>
			</div> }

			<Dialog visible={!isEmpty(enableAppointment)} className='doctor-datetime' onHide={() => dispatch(setEnableAppointment([]))} dismissableMask={true}>
				<Appointments isFromDoctor={true} />
			</Dialog>
				
		</div>
	)
}

export default Doctors
