import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
	getAppointmentDetail,
	cancelAppointment,
	addMedicalRecords,
	getRecords,
	getPrescriptions
} from 'api/appointmentService'
import { Icons,DateTime } from 'components'
import './AppointmentDetail.scss'
import {Records,PrescriptionList} from 'components'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { enableRecordModal } from 'store/header'
import { paymentConfirmation } from 'api/appointmentService'
import { getRecentlyVisitedDoctors, getDoctorsList } from 'api/doctorService'
import logoForPayment from '../../../assets/logoForPayment.png'
import { enableTimingPopup as enableTiming } from 'store/header'
import { Dialog } from 'primereact/dialog'
import { getMedicalPackageDetail } from 'api/doctorService'
import { enableLocation } from 'store/header'

import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { isArray, isEmpty } from 'lodash'

function AppointmentDetail({ page }) {
	const dispatch = useDispatch()
	let { id } = useParams()
	const [appointmentDetail, setAppointmentDetail] = useState()
	const [updateCancelStatus, setUpdateCancelStatus] = useState(false)
	const { selectedHospital ,doctorsList } = useSelector(state => state?.doctorsInfo)
	const { enableTimingPopup,enableLocation: locationState  } = useSelector(state => state?.header)
	const [prescriptionList,setPrescriptionList] = useState(null);
	// const { recentlyVisited, , departments, selectedHospital } =
	// 	useSelector(state => state?.doctorsInfo)


	const getAppointment = async () => {
		let response = await dispatch(
			getAppointmentDetail({
				hospital_id: selectedHospital,
				page: '1',
				appointment_id: id,
			})
		)
		if(!response?.package_id){
			dispatch(getDoctorsList({ hospital_id: selectedHospital }))
		} else {
			getMedicalPackageDetailReq(response?.package_id)
		}
		response?.hospital_id && dispatch(setSelectedHospital(response?.hospital_id))
		setAppointmentDetail(response)
		checkTime(response)
	}

	const cancelConfirm = () => {
		confirmDialog({
			message: 'Are you sure you want to proceed?',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				await dispatch(
					cancelAppointment({
						appointment_id: appointmentDetail?.appointment_id,
					})
				)
				setUpdateCancelStatus(true)
			},
			// reject:
		});
	}

	const { userProfile } = useSelector(state => state.header)


	const [packageDetail, setPackageDetail] = useState({})

	const getMedicalPackageDetailReq = async (packageId) => {
		let response = await dispatch(getMedicalPackageDetail({ package_id: packageId ,hospital_id : selectedHospital}))
		setPackageDetail(response)
	}

	const [cancelBtn,setCancelBtn] = useState(false);
	const [payNowBtn,setPayNowBtn] = useState(false);

	const checkTime = (v) => {
		const at = (new Date(v?.appointment_from_time).getTime())/1000
		const ct = (new Date().getTime())/1000
		
		let diffSeconds = at -ct
        const t = Number(diffSeconds)

		t <= 7200 ? setCancelBtn(false) : setCancelBtn(true)
		t <= 1800 ? setPayNowBtn(false) : setPayNowBtn(true)
	}


	useEffect(() => {
		selectedHospital && getAppointment()
	}, [selectedHospital, updateCancelStatus,id])

	useEffect(() =>{
		dispatch(enableLocation())
		return () => {
			dispatch(enableLocation())
		}
	},[])

	// const handleAddRecords = e => {
	// 	const arr = [...e.target?.files]

	// 	dispatch(
	// 		addMedicalRecords({
	// 			hospital_id: selectedHospital,
	// 			records: arr,
	// 			appoinment_id: id,
	// 			name: `${appointmentDetail?.customer_first_name}_${id}_${appointmentDetail?.formattedOrderId}`,
	// 		})
	// 	)
	// }

	useEffect(() => {		
		const script = document.createElement('script')
		script.src = 'https://checkout.razorpay.com/v1/checkout.js'
		script.async = true
		document.body.appendChild(script)
	}, [])

	const bookAgain = () => {
		dispatch(enableTiming(true))
	}
	
	const pay = async (response) => {
		const razor_key = process.env.RAZOR_KEY
		const options = {
			key: razor_key,
			amount: response?.amount_payable,
			name: 'BMJH',
			currency: 'INR',
			description: 'Bhagwan Mahaveer Jain Hospital',
			image: logoForPayment,
			order_id: response?.payment_order_id,
			handler: async paymentResponse => {
				dispatch(
					paymentConfirmation({
						razorpay_order_id: paymentResponse?.razorpay_order_id,
						razorpay_payment_id: paymentResponse?.razorpay_payment_id,
						razorpay_signature: paymentResponse.razorpay_signature,
					})
				).then(() => getAppointment())
			},
			retry: false,
			prefill: {
				name: userProfile?.firstname + ' ' + userProfile?.lastname,
				contact: userProfile?.phone,
				email: userProfile?.email,
			},
			notes: {
				address: userProfile?.address,
			},
			theme: {
				color: '#e73728',
				hide_topbar: false,
			},
		}

		let rzp1 = new window.Razorpay(options)
		rzp1.open();
		rzp1.on('payment.failed', async function (v) {
			dispatch(
				enableToast({
					http_code: 400,
					message: 'Payment Failed',
				})
			)
			getAppointment()
		})
	}

	return (
		<div
			className={`appointment-detail ${
				page == 'checkout' ? 'checkout-page-appointments' : null
			}`}>
			<div className='appointment-doctor-card d__flex align-start gap-20'>
				<div className='image-ctr'>
					<img src={ !appointmentDetail?.package_id ? appointmentDetail?.doctor_image : appointmentDetail?.package_image1} alt='' />
				</div>
				<div className='flex-1'>
					<h3> { !appointmentDetail?.package_id ? appointmentDetail?.doctor_name : appointmentDetail?.package_name }</h3>
					<div className='d__flex gap-10'>
						<p>{appointmentDetail?.speciality_name}</p>
					</div>
					<p>{appointmentDetail?.hospital_name_en}</p>
					{!appointmentDetail?.package_id && <p>{ isArray(appointmentDetail?.Doctor_languages ) && !isEmpty(appointmentDetail?.Doctor_languages ) && appointmentDetail?.Doctor_languages?.map((r,i)=> <p className='m-0' style={{display:'inline-block'}} index={'LNG'}>{r} {appointmentDetail?.Doctor_languages?.length - 1 > i && ', '} </p>)}</p>}
					<div>
						{/* <p>{appointmentDetail?.order_status}</p> */}
						<p className='appointment-status-detail'><span className={`fnt-12 ${'status-'+appointmentDetail?.order_status_id}`}>{appointmentDetail?.order_status}</span></p>
						{/* <p>{appointmentDetail?.payment_mode_en}</p> */}
					</div>
					{/* <div>
						<p>{appointmentDetail?.visitor_type_name}</p>
					</div> */}
				</div>
				<div className='d__flex flex-w buttons gap-20'>

				{/* meeting_password
: 
"1667331001"
meeting_url
: 
"https://us05web.zoom.us/j/88157279407?pwd=T2VFV1ZkV2ZwdzQ5Y1BYaTFvNVNvQT09" */}

					{
					 appointmentDetail?.order_status_id == 2 &&	appointmentDetail?.meeting_url && <button style={{backgroundColor:'green'}}>
							<a target='_blank' href={appointmentDetail?.meeting_url}>Connect</a>
						</button>
					}
					{/* <button>RESCHEDULE</button> */}
					
					{/* 5.doctor cancel 4.customer cancel 1.request 2.confirm 3.completed 6.transaction failed */}
					{
						appointmentDetail?.order_status_id == 6 && appointmentDetail?.payment_mode != 2 && payNowBtn && <button onClick={() => pay(appointmentDetail)} >
							Pay Now
						</button>
					}
					{
						appointmentDetail?.order_status_id == 3 && appointmentDetail?.doctor_status == "1" && <button onClick={() => bookAgain()} >
							Book Again
						</button>
					}
					{   appointmentDetail?.order_status_id !== 4 &&
						appointmentDetail?.order_status_id !== 6 &&
						appointmentDetail?.order_status_id !== 3 &&
						appointmentDetail?.order_status_id !== 2 && 
						appointmentDetail?.order_status_id !== 5 && (
							<>
							{cancelBtn && <button
								onClick={cancelConfirm}>
								{appointmentDetail?.package_id?'Cancel Package':'Cancel Appointment'}
							</button>}

							{!appointmentDetail?.package_id && <button className='txt-right'  onClick={() => ( dispatch(enableRecordModal(false)))}>
								<label>
									{/* htmlFor='add-records-id' onClick={() => setShowDialog(true)}*/}
									<div className='d__flex m-d-flex add-record-btn gap-20'>
										<div>
											<Icons iconType='add' name='common' width='20' height='20' viewBox='0 0 20 20'/>
										</div>
										Add Records
									</div>
									{/* <input id='add-records-id' className='d__none' type='file' name='add-records' onChange={handleAddRecords} multiple/> */}
								</label>
							</button>}

							</>
						)}
						<ConfirmDialog />
					{/* <button className='txt-right'>
						<div className='d__flex add-record-btn gap-20'>
							Pay Again
						</div>
					</button> */}
				</div>
			</div>
			<div className='appointment-details-info'>
				<h4>Appointment Details</h4>
				<div className='appointment-detail-info d__flex align-start'>
					<ul>
						<li>
							<span>Booking ID</span> :{' '}
							<span>#{appointmentDetail?.formattedOrderId}</span>
						</li>
						<li>
							<span>Hospital Location</span> :{' '}
							<span>{appointmentDetail?.hospital_name_en}</span>
						</li>
						{/* <li>
							<span>Type</span> :{' '}
							<span>
								{appointmentDetail?.visitor_type_id == 1
									? 'Physical Hospital Visit'
									: 'Video Consultation'}
							</span>
						</li> */}
												
						<li>
							<span>Visitor type</span> :{' '}
							<span>{appointmentDetail?.visitor_type_name}</span>
						</li>
						<li>
							<span>{ !appointmentDetail?.package_id ?'Speciality':'Package Name'}</span> :{' '}
							<span>{ !appointmentDetail?.package_id ? appointmentDetail?.speciality_name : appointmentDetail?.package_name }</span>
						</li>
						{ !appointmentDetail?.package_id &&  isArray(appointmentDetail?.Doctor_languages ) && !isEmpty(appointmentDetail?.Doctor_languages ) &&  <li>
							<span>Language</span> :{' '}
							<span>{appointmentDetail?.Doctor_languages?.map((r,i)=> <p className='m-0' style={{display:'inline-block'}} index={'LNG'}>{r} {appointmentDetail?.Doctor_languages?.length - 1 > i && ', '} </p>)}</span>
						</li>}
						<li>
							<span>Payment Type</span> :{' '}
							<span>{appointmentDetail?.payment_mode_en}</span>
						</li>
						{!appointmentDetail?.package_id && <li>
							<span>Vaccinated</span> : <span className='capitalize'>{appointmentDetail?.covid_info}</span>
						</li>}
					</ul>
					<ul>
						<li>
							<span>Patient Name</span> :{' '}
							<span>
								{appointmentDetail?.patient_name} 
								{/* +
									' ' +
									appointmentDetail?.customer_last_name} */}
							</span>
						</li>
						<li>
							<span>Patient Contact</span> :{' '}
							<span>
								{appointmentDetail?.patient_contact}
							</span>
						</li>
						<li>
							<span>Patient Age</span> :{' '}
							<span>
								{appointmentDetail?.patient_age}
							</span>
						</li>
						<li>
							<span>Patient Gender</span> :{' '}
							<span>
								{appointmentDetail?.patient_gender}
							</span>
						</li>
						
						<li>
							<span>Date</span> :{' '}
							<span>
								{moment(appointmentDetail?.appointment_from_time).format(
									'dddd, Do MMM'
								)}
							</span>
						</li>
						{ !appointmentDetail?.package_id && <li>
							<span>Time</span> :{' '}
							<span>
								{moment(appointmentDetail?.appointment_from_time).format(
									'hh: mm a'
								)}
							</span>
						</li>}
						<li>
							<span>Consultation charges</span> : <span>{appointmentDetail?.grand_total != '0.00'?appointmentDetail?.grand_total:'FREE'}</span>
						</li>
					</ul>
				</div>
			</div>

			<div className='d__flex record-prescription align-start col-12'>
				<div className={` ${prescriptionList?.length == 0 ? 'col-12':'col-6'} record-lists`}>
					{/* <RecordList appointmentId={id} /> */}
					<Records appointmentId={id} isFromDetail={true} hospitalID={appointmentDetail?.hospital_id}/>
				</div>
				<div className='col-6 prescriptions-list'>
					<PrescriptionList appointmentId={id} hospitalID={appointmentDetail?.hospital_id} setPrescriptionLists={(data) => setPrescriptionList(data)}/>
				</div>
			</div>

			<Dialog 
					visible={enableTimingPopup}
					// style={{ width: '80%', height: '1000px' }}
					className=' wid-res doctor-datetime'
					onHide={() => dispatch(enableTiming(false))}
					dismissableMask={true}>
					<DateTime
						doctorId={appointmentDetail?.doctor_id}
						visitor_type_id={appointmentDetail?.visitor_type_id}
						speciality_id={appointmentDetail?.speciality_id}
						doctorInfo={ appointmentDetail?.doctor_id ? doctorsList?.list?.find(d => d.doctor_id == appointmentDetail?.doctor_id) : []}
						packageBooking={appointmentDetail?.package_id} packagePrice={appointmentDetail?.amount_payable} package_id={appointmentDetail?.package_id} packageInfo={appointmentDetail?.package_id? packageDetail :{}}
					/>
				</Dialog>
			
		</div>
	)
}

export default AppointmentDetail
