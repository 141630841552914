import axios from 'axios'
import { enableToast, recentlyBooked } from 'store/header'

axios.defaults.baseURL = process.env.API_DOMAIN
const vendor_id = process.env.API_VENDOR_ID
const customer_id = sessionStorage.getItem('customerID')
const token = sessionStorage.getItem('token')

export const getDoctorAvailableSlots = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/oms/v-1-0-0/doctor-slots/list', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const createAppointment = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/oms/v-1-0-0/appointment/store', {
			vendor_id,
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			device_type: 'web',
			...payload,
		})
		if (response?.data?.http_code == 200) {
			dispatch(recentlyBooked(response?.data?.data))
			return response?.data
		} else if (response?.data?.http_code == 400) {
			// enableToast(res?.data)
			return response?.data
		} else {
			return dispatch(
				enableToast(response?.data)
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const paymentConfirmation = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/oms/v-1-0-0/appointment/razorpay-callback',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const cancelAppointment = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/oms/v-1-0-0/appointment/update-cancel-order-status',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			dispatch(enableToast(response?.data))
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getAppointmentList = payload => async (dispatch, getState) => {
	//const { header } = getState() TODO:
	try {
		let response = await axios.post('customer/oms/v-1-0-0/appointment/list', {
			token: sessionStorage.getItem('token'),
			customer_id: sessionStorage.getItem('customerID'),
			vendor_id,
			...payload,
		})
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getAppointmentDetail = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/oms/v-1-0-0/appointment/detail',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const addMedicalRecords = payload => async dispatch => {
	try {
		const file = new FormData()
		file.append('vendor_id', vendor_id)
		file.append('token', sessionStorage.getItem('token'))
		file.append('customer_id', sessionStorage.getItem('customerID'))
		file.append('hospital_id', payload?.hospital_id)
		file.append('appoinment_id', payload?.appoinment_id)
		file.append('name', payload?.name)

		payload?.records?.forEach((a, index) => file.append('customer_record_files[' + index + ']', a))

		let response = await axios.post(
			'customer/oms/v-1-0-0/appointment/records/add',
			file
		)
		if (response?.data?.http_code == 200) {
			dispatch(enableToast(response?.data))
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const updateMedicalRecords = payload => async dispatch => {
	try {
		const file = new FormData()
		file.append('vendor_id', vendor_id)
		file.append('token', sessionStorage.getItem('token'))
		file.append('customer_id', sessionStorage.getItem('customerID'))
		file.append('hospital_id', payload?.hospital_id)
		file.append('appoinment_id', payload?.appoinment_id)
		file.append('name', payload?.name)
		file.append('records_id', payload?.records_id)

		payload?.records?.forEach((a, index) => file.append('customer_record_files[' + index + ']', a))

		let response = await axios.post(
			'customer/oms/v-1-0-0/appointment/records/update',
			file
		)
		if (response?.data?.http_code == 200) {
			return dispatch(enableToast({ http_code, message: response?.data?.message }))
			// return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const deleteMedicalRecords = payload => async dispatch => {
	try {
		const file = new FormData()
		file.append('vendor_id', vendor_id)
		file.append('token', sessionStorage.getItem('token'))
		file.append('customer_id', sessionStorage.getItem('customerID'))
		file.append('hospital_id', payload?.hospital_id)
		file.append('appoinment_id', payload?.appoinment_id)
		file.append('records_id', payload?.records_id)
		file.append('records_file_id', payload?.records_file_id)

		let response = await axios.post(
			'customer/oms/v-1-0-0/appointment/records/remove',
			file
		)
		if (response?.data?.http_code == 200) {
			dispatch(enableToast(response?.data))
			return response?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}
export const getConsolidatedRecords = payload => async dispatch => {
	try {
		let response = await axios.post(
			'customer/oms/v-1-0-0/appointment/records/consolidated-list',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getRecords = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/oms/v-1-0-0/appointment/records/list',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getPrescriptions = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/oms/v-1-0-0/appointment/prescriptions/list',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getVisitors = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/crm/v-1-0-0/doctor/visitor-types',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getLanguages = payload => async dispatch => {
	try {
		let response = await axios.post(
			'/customer/crm/v-1-0-0/doctor/languages',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getPayments = payload => async dispatch => {
	try {
		let response = await axios.post('/customer/oms/v-1-0-0/payment-list',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

export const getCost = payload => async dispatch => {
	try {
		let response = await axios.post('customer/oms/v-1-0-0/appointment/customer-appointment-cost',
			{
				vendor_id,
				token: sessionStorage.getItem('token'),
				customer_id: sessionStorage.getItem('customerID'),
				...payload,
			}
		)
		if (response?.data?.http_code == 200) {
			return response?.data?.data
		} else if (response?.data?.http_code == 400) {
			//return dispatch(enableToast(response?.data))
		} else {
			return dispatch(
				enableToast({ http_code, message: 'Something went wrong' })
			)
		}
	} catch (e) {
		return dispatch(enableToast(e?.response?.data))
	}
}

// }	http://dev.bmjhservice.com/