import './DoctorBoxMini.scss'

import React from 'react'
import { Link } from 'react-router-dom'

function DoctorBoxMini({ doctor, small }) {
	return (
		<div
			className={`mini-doctor-box ${
				small ? 'mini-doctor-box-sm' : 'mini-doctor-box-lg'
			}`}>
			<div className='image-ctr'>
				<img src={doctor?.profile_image} alt='' onError={((e) => {(e.target.src = require('../../../assets/default/home-doctor.png') )})} />
			</div>
			<div>
				<Link to={'/doctors/' + doctor?.doctor_id}>
					<h5 className='m-0'>{`${doctor?.firstname} ${doctor?.lastname} `}</h5>
				</Link>
				<div>
					{
						doctor?.specialities?.map((v,i)=>
							<p key={i+"SP"} className='m-0'><Link to={'/specialties/'+v?.id} title={v?.name}>{v?.name}</Link></p>		
						)
					}
				</div>
			</div>
		</div>
	)
}

export default DoctorBoxMini
export { DoctorBoxMini }
