import './Records.scss';

import React, { useEffect, useState } from 'react';
import { Icons } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getRecords } from 'api/appointmentService';
import { Link, useParams } from 'react-router-dom';
import { Image } from 'primereact/image';
import { addMedicalRecords, deleteMedicalRecords, updateMedicalRecords } from '../../../api/appointmentService';
import { ConfirmDialog } from 'primereact/confirmdialog' // To use <ConfirmDialog> tag
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { enableToast } from 'store/header'
import { enableRecordModal as enableRecord} from 'store/header'
import { enableLocation } from 'store/header'

function Records({appointmentId,isFromDetail,hospitalID}) {
	const dispatch = useDispatch()
	const [getRecordsList, setRecordsList] = useState([])
    const [value,setValue] = useState('')
    const [selectedImages,setSelectedImages] = useState([]);
    const [tempFiles,setTempFiles] = useState([]);
    const [visible, setVisible] = useState(false)
	const { selectedHospital } = useSelector(state => state.doctorsInfo)
    const { enableRecordModal,enableLocation:locationState } = useSelector(state => state.header)

    const {id} = useParams();

	useEffect(() => {
        id == undefined ? id = appointmentId  : null
		recordsList()
	}, [selectedHospital,id,hospitalID])

    useEffect(() => {
        !isFromDetail && dispatch(enableLocation())
        return () => {
            !isFromDetail && dispatch(enableLocation())
		}
    },[isFromDetail])

    const recordsList = async () => {
		let response = await dispatch(
			getRecords({
				hospital_id: hospitalID ?? selectedHospital ,
				appoinment_id: id,
			})
		)
		setRecordsList(response?response?.customer_appointments_records_list:[])
	}

    const [deleteRecords,setDeleteId] = useState({record_id:undefined,file_id:undefined})
    const deleteConfirm = (record_id,file_id) => {
        setVisible(true)
        setDeleteId({record_id:record_id,file_id:file_id})
        
        // confirmDialog({
        //     message: 'Do you want to delete this record?',
        //     header: 'Delete Confirmation',
        //     icon: 'pi pi-info-circle',
        //     accept : async () => {
        //         const v =  await dispatch(deleteMedicalRecords({hospital_id: selectedHospital, appoinment_id: id,records_id:record_id,records_file_id:file_id}))
        //         v.http_code == 200 && recordsList();
        //     } 
        // })
    };

    const deleteRecord = async () => {
        const v =  await dispatch(deleteMedicalRecords({hospital_id: selectedHospital, appoinment_id: id,records_id:deleteRecords.record_id,records_file_id:deleteRecords.file_id}))
        setDeleteId({record_id:undefined,file_id:undefined})
        v.http_code == 200 && recordsList();
    } 

    const addRecords = async (arr) =>  {
        arr.length == 0  && dispatch(enableToast({http_code:400,message:"Please Add Record Files"}))
        if(arr.length == 0) return false
        if(value != '') {
            dispatch(enableRecord(false))
            const v = await dispatch(
                addMedicalRecords({
                    hospital_id: selectedHospital,
                    records: arr,
                    appoinment_id: id,
                    name: value
                })
            )
            v && recordsList()   
            setValue('');
            setTempFiles([])
        } else {
            dispatch(enableToast({http_code:400,message:"Please Enter Record Name"}))
        }
    }

    const updateRecord = async (records,recordID,record_name) =>  {
        const v = await dispatch(
            updateMedicalRecords({
                hospital_id: selectedHospital,
                records: records,
                appoinment_id: id,
                name: record_name,
                records_id : recordID
            })
        )
        v && recordsList()   
    }

    const handleAddRecords = e => {
		const records = [...e.target?.files]
        var t = []
        records.map(res => {
            t = [...t,URL?.createObjectURL(res)]
        })
        setSelectedImages(t)
        setTempFiles(records)
	}

    const updateRecords = (e,id,record_name) => {
		const records = [...e.target?.files]
        updateRecord(records,id,record_name)
	}

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() =>windowHide()  } className="p-button-text" />
                <Button label="Upload" icon="pi pi-check" onClick={() => addRecords(tempFiles)} autoFocus />
            </div>
        );
    }

    const removeItem = (index) => {
        let v = tempFiles
        let d = v.splice(index,1);
        setTempFiles(v)
    }


    const windowHide = () => {
        dispatch(enableRecord(false))
        setTempFiles([])
        setValue('');
    }
    
  return (
    <div className='records'>
        <div className='record-head'>
            {getRecordsList.length !=0 && <div>
                <h4>Record List</h4>
                <p className='m-0'><b>Appointment ID : </b> <Link to={'/profile/my-appointments/'+id}> {'BMJH'+id} </Link>  </p>
            </div>}
            {!isFromDetail && <button className='cursor-pointer d__flex gap-20 add-record'  onClick={() => dispatch(enableRecord())}> 
                <div>
                    <Icons iconType='add' name='common' width="20" height="20" viewBox="0 0 20 20"/>
                </div>
                Add More Files
			</button>}
		</div>

        <div className='all-records'>
        {
            getRecordsList?.map((res,index) =>
                <div className='record' key={index+'records'}>
                    <div className=''>
                    <div>
                        <h5 className='m-0'>{res?.records_name}</h5>
                    </div>
                    <div className='d__flex flex-w gap-20'>
                    {
                        res?.records_files?.map((value,index) =>
                            <div className='' key={index+"RFF"}>
                                <Image src={value?.records_file_url} preview={true} downloadable={true} alt="Image" width={'200'} height={'auto'}/>
                                <button className='main-add' onClick={() => deleteConfirm(res?.records_id,value?.records_file_id)}>Delete</button>
                            </div>
                        )
                    }
                        <div className='add-images'>
                            {/* {res?.records_id} */}
                            <button className='add-image-btn '>
                                    <label className='cursor-pointer d__flex gap-20' htmlFor='update-records'>
                                        <div>
                                            <Icons iconType='add' name='common' width="20" height="20" viewBox="0 0 20 20"/>
                                        </div>
                                        <input id='update-records'  className='d__none' type='file' name='add-records' onChange={(e) => updateRecords(e,res?.records_id,res?.records_name)} multiple/>
                                    </label>
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
        </div>

        <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message='Do you want to delete this record?'
            header='Delete Confirmation'
            icon='pi pi-info-circle'
            accept={deleteRecord}
            className='dialogue-box' 
        />

         {/* <ConfirmDialog className='dialogue-box' /> */}

         <Dialog  className='record-add' visible={enableRecordModal} style={{ width: '300px',borderRadius : '8px',overflow: 'hidden' }} onHide={() => windowHide()} dismissableMask={true} footer={renderFooter}>
            <div className='file-uploads'>
                <div>
                    <div className='head-label'>File Name *</div>
                    <InputText value={value} onChange={(e) => setValue(e.target.value)} />
                </div>
                <div className='input-placeholder-file'>
                    <div className='head-label'>
                        Choose Files
                    </div>
                    <div className='placeholder-label'>
                         <label htmlFor='add-records-id' className='add-record-label'>
                                Choose File
                                <input id='add-records-id' className='d__none' type='file' name='add-records' onChange={handleAddRecords} multiple/>
                        </label>
                    </div>
                    <div className=''>
                        {
                            tempFiles?.map((res,index) =>
                                <div key={index+"SI"}>
                                    <img  src={URL?.createObjectURL(res)} alt='' height={'100px'} />

                                    {/* <button onClick={() => removeItem(index)}>delete + {index}</button> */}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
  )
}

export default Records
