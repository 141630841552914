import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import './CareerAdd.scss'
import * as yup from 'yup'
import 'yup-phone'
import { classNames } from 'primereact/utils'
import { useRef } from 'react'
import { useState } from 'react'
import { careerAdd as addCareer } from 'api/cmsService'
import { useDispatch, useSelector } from 'react-redux'

const schema = yup.object({
	firstname: yup.string().required('Enter your First name'),
	lastname: yup.string(),
	email: yup.string().email().required('Enter your email address'),
	phone: yup.string().phone('IN', true, 'Invalid mobile number'),
	address: yup.string().required('Enter your Address'),
	// dob: yup.string().required('Enter your DOB'),
	// marital_status: yup.string().required('Select your martial status'),
	// gender: yup.string().required('Select your gender'),
	// address: yup.string().required('Enter your Address'),
})


function CareerAdd({careerId,hidePopup,careerInfo}) {
	const dispatch= useDispatch();
	const resumeRef = useRef(null);
	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
    const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		getValues,
		setValue,
		watch
	} = useForm({
		mode: 'onBlur',
		defaultValues: {
		},
		resolver: yupResolver(schema),
	})


    const getFormErrorMessage = name => {
		return (
			errors?.[name] && (
				<small className='p-error'>{errors[name].message}</small>
			)
		)
	}

	const [btnDisabled,setDisabled] = useState(false)
	const [fileError,setFileError] = useState(false)
	const onSubmit = async data => {
		setDisabled(true)
		if(resumeRef?.current?.files.length == 0){
			setFileError(true);
		} else {

			setFileError(false);
			const file = [...resumeRef?.current?.files]
			const v = { resume : file[0], hospital_id :selectedHospital ,career_id : careerId,...data }
			const s = await dispatch(addCareer(v))
			s && reset()
			s && hidePopup()
		}
		setDisabled(false)
	}

  return (
    <div className='career-add'>
			<h1 className='txt-center custom-heading'> <span>{careerInfo?.title}</span></h1>
			<form onSubmit={handleSubmit(onSubmit)} className='p-fluid'>

				<div className='input-item'>
					<div className='input-label'>First Name*</div>
					<input placeholder='Enter Your First Name*' type='text'
						className={classNames({ 'p-invalid': errors['name'] })}
						{...register('firstname', { required: true, maxLength: 30,})}
					/>
					{getFormErrorMessage('firstname')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Last Name</div>
					<input placeholder='Enter Your Last Name' type='text'
						className={classNames({ 'p-invalid': errors['name'] })}
						{...register('lastname', { required: false, maxLength: 30,})}
					/>
					{getFormErrorMessage('lastname')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Email*</div>
					<input
						placeholder='Enter Your Email*' type='email'
						className={classNames({ 'p-invalid': errors['email'] })}
						{...register('email', { required: true,maxLength: 30,})}
					/>
					{getFormErrorMessage('email')}
				</div>

				<div className='input-item'>
					<div className='input-label'>Mobile Number*</div>
					<input placeholder='Enter Your Mobile Number*'
						type='phone' 
						className={classNames({ 'p-invalid': errors['phone'] })}
						{...register('phone', { required: true,})}
					/>
					{getFormErrorMessage('phone')}
				</div>


				<div className='input-item'>
					<div className='input-label'>Address*</div>
					<input placeholder='Enter Your Address' type='text' name='address'
						className={classNames({ 'p-invalid': errors['address'] })}
						{...register('address', { required: true,})}
					/>
					{getFormErrorMessage('address')}
				</div>
                
				<div className='input-item'>
					<div className='input-label'>Resume* (pdf)</div>
					<input id='update-records' accept='.pdf' style={{border:'unset '}} ref={resumeRef} type='file' onChange={() => setFileError(false)} name='add-records' />
					{fileError && <small className='p-error' >Select file</small> }
				</div>

				<div className='txt-right flex-1'>
					<button disabled={btnDisabled}>Update</button>
				</div>
			</form>
    </div>
  )
}

export default CareerAdd

