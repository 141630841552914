import './OtpAuth.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { signUp, verifyOtp, resendOtp, getUserProfile } from 'api/authService'
import { InputText } from 'primereact/inputtext'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import 'yup-phone'
import { classNames } from 'primereact/utils'
import logo from '../../../../assets/logo-trans.png'
import { isEmpty } from 'lodash'
import { useRef } from 'react'
import MobileOtpInput from "react-otp-input";

const schema = yup.object({
	mobileNumber: yup
		.string()
		.phone('IN', true, 'Invalid mobile number')
		.required(),
})

// const OtpSchema = yup.object({
// 	otp0: yup.number().required(),
// 	otp1: yup.number().required(),
// 	otp2: yup.number().required(),
// 	otp3: yup.number().required(),
// 	otp4: yup.number().required(),
// 	otp5: yup.number().required(),
// })


const otpLoginFormSchema = yup.object({
	otp: yup
	  .string()
	  .required("Please Enter the otp code")
	  .min(6, "Code must be at least 6 characters"),
  });

function OtpAuth() {
	const [otpSend, setOtpSend] = useState(false)
	const [counter, setCounter] = useState(0)
	const [otpInput, setOtpInput] = useState([])
	const [otpResponse, setOtpResponse] = useState([])
	const [mobileNumber,setMobileNumber] = useState();
	// const [activeOtpIndex,setOtpIndex] = useState(0)
	// const inputRef = useRef(null);
	const dispatch = useDispatch()
	const {
		register: initialRegister,
		formState: { errors },
		handleSubmit: handleInitialSubmit,
	} = useForm({ resolver: yupResolver(schema) })

	// const {
	// 	register: registerOtp,
	// 	formState: { errors1 },
	// 	handleSubmit: handleOtpSubmit,
	// 	setFocus,
	// } = useForm({ resolver: yupResolver(OtpSchema) })


	const { register,handleSubmit :handleOtpSubmit ,control, formState: { errors: errors1 } } = useForm({
		// defaultValues: { otp:''},
		resolver: yupResolver(otpLoginFormSchema),
		// shouldUnregister: true,
	  });


	const onFormSubmit = async data => {
		setMobileNumber(data?.mobileNumber)
		const response = await dispatch(
			signUp({
				phone: data?.mobileNumber || '',
			})
		)
		if (!isEmpty(response)) {
			setOtpResponse(response?.data)
			setOtpInput(Array.from(Array(6).keys()))
			setOtpSend(true)
			setCounter(58)
		}
	}

	const onOTPSubmit = data => {
		dispatch(
			verifyOtp({
				authentication_device_id: otpResponse?.authentication_device_id,
				authentication_id: otpResponse?.authentication_id,
				otp: data?.otp,
			})
		)
		dispatch(getUserProfile())
	}
	const handleResendOtp = () => {
		dispatch(
			resendOtp({
				authentication_device_id: otpResponse?.authentication_device_id,
				authentication_id: otpResponse?.authentication_id,
			})
		)
		setCounter(58)
	}

	const getFormErrorMessage = name => {
		return (
			(errors?.[name] || errors1?.[name]) && (
				<small className='p-error'>{errors[name].message}</small>
			)
		)
	}
	// const handleOtpChange = (e,index) => {
	// 	let getId = e.target?.id?.split('-')[1]
	// 	// if( document.getElementById(`otp-${parseInt(getId)}`).value == ''){
	// 	// 	document.getElementById(`otp-${parseInt(getId) - 1}`)?.focus()
	// 	// } else {
	// 		// if
	// 		// setOtpIndex(index+1)
			 
	// 		document.getElementById(`otp-${e.target.value?index + 1:index -1}`)?.focus()
	// 	// }
	// }

	// const keyPressed = (e,index) => {
	// 	const values = [0,1,2,3,4,5,6,7,8,9]
	// 	// values.includes(e.key)
	// 	// if(e.key == 'Backspace'){
	// 	// 	document.getElementById(`otp-${index -1}`)?.focus()
	// 	// }
	// }

	useEffect(() => {
		if (otpSend) {
			const timer =
				counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
			return () => clearInterval(timer)
		}
	}, [counter])

	// useEffect(() => {
	// 	inputRef?.current?.focus();
	// },[activeOtpIndex])



	return (
		<div className='otp-auth'>
			<div className='logo-image txt-center'>
				<img src={logo} alt='' />
			</div>
			<div>
				<h1 className='txt-center'>Login</h1>
				<p className='txt-center'> We will send you a <span>One Time Password</span> on this mobile number</p>
				{!otpSend ? (
					<form onSubmit={handleInitialSubmit(onFormSubmit)} className='p-fluid'>
						<div className='input-item'>
							<label
								htmlFor='name'
								className={classNames({ 'p-error': errors['mobileNumber'] })}>
								Enter Your Mobile Number
							</label>
							<InputText
								id='mobileNumber'
								type='number'
								min='0'
								inputMode='numeric'
								pattern='\d*'
								className={classNames({ 'p-invalid': errors['mobileNumber'] })}
								{...initialRegister('mobileNumber', {
									required: true,
									minLength: 10,
									maxLength: 10,
									value: '',
								})}
							/>
							{getFormErrorMessage('mobileNumber')}
						</div>
						<button>Submit</button>
					</form>
				) : (
					<div className='otp-sent txt-center'>
						<form onSubmit={handleOtpSubmit(onOTPSubmit)} className='p-fluid'>
							<div className=''>
								<Controller control={control}
								render={({ field: { onChange, onBlur: _, value }, fieldState: { error },}) => (
									<>
										<MobileOtpInput value={value} onChange={onChange} numInputs={6}
											separator={
												<span className="hidden sm:inline-block mx-3"></span>
											}
											containerStyle="just-c"
											inputStyle=""
											disabledStyle="!bg-gray-100"
										/>
										{error && (<div className='p-error otp-error'>{error?.message}</div>)}
										</>
									)}
									name="otp"
									defaultValue=""
									/>
								</div>

							{/* <div className='d__flex m-d-flex gap-10 just-c'>
								{otpInput.map((res, index) => {
									return (
										<input
											// ref={index  == activeOtpIndex ? inputRef : null }
											maxLength={1}
											name='otp'
											min='0'
											inputMode='numeric'
											type={'text'}
											id={`otp-${index}`}
											key={index + 'otp'}
											onKeyUp={(e) => keyPressed(e,index)  }
											{...registerOtp(`otp${index}`, {
												required: true,
												onChange: (e) => handleOtpChange(e,index),
											})}
										/>
									)
								})}
							</div> */}

							{counter !== 0 && (
								<p className='fnt-12 timer'>
									Resend code in <span>0:{counter}</span>
								</p>
							)}
							{counter == 0 && (
								<p className='fnt-14 mb-0'>
									Didn’t receive a OTP?{' '}
									<span onClick={handleResendOtp} className='cursor-pointer'>
										Resend Otp
									</span>
								</p>
							)}
							<p className='m-0 fnt-12'>Wrong Number - {mobileNumber} <span className='cursor-pointer' onClick={() => setOtpSend(false)}> Go back</span></p>
							<button type='submit' >Submit</button> 
						</form>

					</div>
				)}
			</div>
		</div>
	)
}

export default OtpAuth
export { OtpAuth }
