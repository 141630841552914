import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import './Summary.scss'

function Summary(props) {
	let appointmentDetail = props?.info?.doctorInfo
	let packageDetail = props?.info?.packageInfo
	const { isRegisteredUser, userProfile } = useSelector(state => state?.header)
	const { hospitalList } = useSelector(state => state?.doctorsInfo)

	return (
		<div className={`summary-detail`}>
			<div className='appointment-doctor-card align-start gap-20'>
				<div className='image-ctr'>
					<img src={!props?.info?.package_id ? appointmentDetail?.profile_image : packageDetail?.image1} alt='' />
				</div>


				{!props?.info?.package_id ? <div className='flex-1'>
					<h3>
						{appointmentDetail?.firstname + ' ' + appointmentDetail?.lastname}
					</h3>
					<div className='d__flex gap-10'>
						<p>{appointmentDetail?.specialities_names}</p>
					</div>
					<p>{appointmentDetail?.hospital_name_en}</p>
					<p>{appointmentDetail?.language_names?.map((r,i)=> <p className='m-0' style={{display:'inline-block'}} index={'LNG'}>{r} {appointmentDetail?.language_names?.length - 1 > i && ', '} </p>)}</p>
				</div>
				:
				<div className='flex-1'>
					<h3>
						{packageDetail?.name_en}
					</h3>
					{/* <p>Tests : {packageDetail?.total_tests}</p> */}
				</div>
				}
			</div>
			<div className='appointment-details-info'>
				<h4>Appointment Details</h4>
				<div className='appointment-detail-info d__flex align-start'>
					<ul>
						<li>
							<span>Location</span> : <span>{props?.info?.location}</span>
						</li>
						<li>
							<span>Type</span> :{' '}
							<span>
								{props?.info?.visitor_type_id == 1
									? 'Physical Hospital Visit'
									: 'Video Consultation'}
							</span>
						</li>
						{!props?.info?.package_id && <>
						<li>
							<span>Speciality</span> :
							<span>{props?.info?.speciality_name}</span>
						</li>
						<li>
							<span>Language</span> :{' '}
							<span>{appointmentDetail?.language_names?.map((r,i)=> <p className='m-0' style={{display:'inline-block'}} index={'LNG'}>{r} {appointmentDetail?.language_names?.length - 1 > i && ', '} </p>)}</span>
						</li>
						<li>
							<span>Gender</span> : <span>{appointmentDetail?.gender}</span>
						</li> </>}
						<li>
							<span>Date</span> :{' '}
							<span>
								{moment(!props?.info?.package_id ? props?.info?.appointment_from_time : props?.info?.package_date).format(
									'dddd, Do MMM'
								)}
							</span>
						</li>
						{!props?.info?.package_id && <li>
							<span>Time</span> :{' '}
							<span>
								{moment(props?.info?.appointment_from_time).format('hh: mm a')}
							</span>
						</li>}
						<li>
							<span>Consultation charges</span> : <span>{props.fees != '0'&&'₹'}{!props?.info?.package_id ? props.fees == '0' ? 'FREE' : props.fees : props?.info?.doctor_fees}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Summary
