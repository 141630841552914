import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subscribeNewsLetter } from 'api/authService'
import iosstore from '../../../assets/iosstore.png'
import logo from '../../../assets/logo-trans.png'
import payments from '../../../assets/payments.png'
import playstore from '../../../assets/playstore.png'
import { Icons } from 'components'
import './Footer.scss'
import { Link } from 'react-router-dom'
import { getStaticInfos } from '../../../api/cmsService'

function Footer(props) {
	const dispatch = useDispatch()
	const [subscribeEmail, setSubscribeEmail] = useState()
	const [isError, setError] = useState(false)
	const { selectedHospital, hospitalList } = useSelector(state => state.doctorsInfo)
	const { staticInfos } = useSelector(state => state.header)

	const handleSubmit = () => {
		let emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (emailValidation.test(subscribeEmail)) {
			setError(false)
			dispatch(
				subscribeNewsLetter({
					hospital_id: selectedHospital,
					email: subscribeEmail,
				})
			)
		} else {
			setError(true)
		}
	}

	const staticInfo = async () => {
		let response = await dispatch(getStaticInfos())
	}


	useEffect(() => {
		staticInfo()
		return () => {

		}
	}, [])

	return (
		<div className='footer-ctr'>
			<div className='main-width container'>
				<div className='news-letter'>
					<div className='inside-news-letter d__flex'>
						<div className='flex-1'>
							<h3>Get in touch</h3>
							<p>
								{staticInfos?.description?.value}
							</p>
						</div>
						<div className='flex-1'>
							<div className='subscribe-input flex-1 d__flex m-d-flex'>
								<input
									type='email'
									value={subscribeEmail}
									placeholder='Enter Email Address'
									onChange={e => setSubscribeEmail(e?.target?.value)}
								/>
								<button onClick={e => handleSubmit(e)}>
									<Icons
										name='footer'
										iconType='subscribe'
										width='35'
										height='35'
										viewBox='0 0 54 54'
									/>
									Subscribe
								</button>
							</div>
							{isError && (
								<span className='input-error-label'>Invalid Email</span>
							)}
						</div>
					</div>
				</div>
				<div className='footer d__flex align-start flex-w wid-92 mar-auto'>
					<div className='footer-item'>
						<img src={logo} alt='' />
						<p>
							{
								staticInfos?.['footer-content-text']?.value
							}
						</p>

						<ul className='footer-styled-list'>
							{
								hospitalList?.map((v, i) =>
									<li key={i + 'AS'}>
										<Icons name='footer' iconType='location' width='22' height='25' viewBox='0 0 22 25' fillColor='#6A6A6A' />
										<div>
											<span>Address - BMJH {v?.name}</span>
											<span><a href={`http://maps.google.com/maps?q=` + v?.address} target='_blank'>{v?.address}</a></span>
											{/* <span>{staticInfos?.['footer-address']?.value}</span> */}
										</div>
									</li>
								)
							}
							{/* <li>
								<Icons name='footer' iconType='location' width='22' height='25' viewBox='0 0 22 25' fillColor='#6A6A6A'/>
								<div>
									<span>Address - Girinagar</span>
									<span>#17, Millers Road, Vasanthnagar, Bengaluru – 560 052</span>
									<span>{staticInfos?.['footer-address']?.value}</span>
								</div>
							</li>
							<li>
								<Icons name='footer' iconType='location' width='22' height='25' viewBox='0 0 22 25' fillColor='#6A6A6A'/>
								<div>
									<span>Address - Vasanthnagar</span>
									<span>6th Cross, Girinagar, Banashankari 3rd Stage, Avalahalli Extn, Bengaluru 560085</span>
									<span>{staticInfos?.['footer-address']?.value}</span>
								</div>
							</li> */}
						</ul>
						{/* <div>
							<button className='download-btn d__flex gap-15 m-d-flex'>
								<Icons
									name='footer'
									iconType='news-letter'
									width='35'
									height='35'
									viewBox='0 0 54 54'
								/>
								Download Newsletters
							</button>
						</div> */}
					</div>
					<div className='footer-item'>
						<h3>Contact Us</h3>
						<ul className='footer-styled-list'>
							{/* <li>
								<Icons name='footer' iconType='contact' width='25' height='25' viewBox='0 0 25 25' fillColor='#6A6A6A'/>
								<div>
									<span>{staticInfos?.email?.name}</span>
									<span> <a href={`mailto:${ selectedHospital == 1 ? staticInfos?.email?.value : 'care.gn@bmjh.org' }`}>{selectedHospital == 1 ? staticInfos?.email?.value :  'care.gn@bmjh.org' }</a></span>
								</div>
							</li>
							<li>
								<Icons name='footer' iconType='call' width='25' height='21' viewBox='0 0 25 21' fillColor='#6A6A6A'/>
								<div>
									<span>Call Us</span>
									<span> <a href={`tel:${ selectedHospital == 1 ? staticInfos?.['footer-mobile']?.value : '080 6176 5555' }`}>{selectedHospital == 1 ? staticInfos?.['footer-mobile']?.value : '080 6176 5555' }</a> </span>
								</div>
							</li> */}
							<li>
								<Icons name='footer' iconType='contact' width='25' height='25' viewBox='0 0 25 25' fillColor='#6A6A6A' />
								<div>
									<span>Email</span>
									<span className='my-5'><b>Vasanthnagar</b></span>
									<span> <a href={`mailto:${hospitalList?.[0]?.contact_person_email}`}>{hospitalList?.[0]?.contact_person_email}</a> </span>
									<span className='my-5'><b>Girinagar</b></span>
									<span><a href={`mailto:${hospitalList?.[1]?.contact_person_email}}`}>{hospitalList?.[1]?.contact_person_email}</a></span>
								</div>
							</li>
							<li>
								<Icons name='footer' iconType='call' width='25' height='21' viewBox='0 0 25 21' fillColor='#6A6A6A' />
								<div>
									<span>Appointment Call</span>
									<span className='my-5'><b>Vasanthnagar</b></span>
									<span><a href='tel:080 2253 5353'>080 2253 5353</a> / <a href='tel:4087 5555'>4087 5555</a> </span>
									<span className='my-5'><b>Girinagar</b></span>
									<span><a href="tel:080 6176 5555/56">080 6176 5555/56</a></span>
								</div>
							</li>
							<li>
								<Icons name='footer' iconType='call' width='25' height='21' viewBox='0 0 25 21' fillColor='#6A6A6A' />
								<div>
									<span>Emergency Call</span>
									<span className='my-5'><b>Vasanthnagar</b></span>
									<span> <a href="tel: 080 2253 5353"> 080 2253 5353 </a>  / <a href="tel:4087 5500">4087 5500 </a></span>
									<span className='my-5'><b>Girinagar</b></span>
									<span><a href="tel:080 6176 5566">080 6176 5566</a></span>
								</div>
							</li>




							{/* <li>
								<Icons name='footer' iconType='location' width='22' height='25' viewBox='0 0 22 25' fillColor='#6A6A6A'/>
								<div>
									<span>Address</span>
									<span>{selectedHospital == 1 ? '#17, Millers Road, Vasanthnagar, Bengaluru – 560 052':'6th Cross, Girinagar, Banashankari 3rd Stage, Avalahalli Extn, Bengaluru 560085'}</span>
									<span>{staticInfos?.['footer-address']?.value}</span>
								</div>
							</li> */}
							{/* <li>
								<Icons name='footer' iconType='location' width='22' height='25' viewBox='0 0 22 25' fillColor='#6A6A6A'/>
								<div>
									<span>Address - Girinagar</span>
									<span>#17, Millers Road, Vasanthnagar, Bengaluru – 560 052</span>
									<span>{staticInfos?.['footer-address']?.value}</span>
								</div>
							</li>
							<li>
								<Icons name='footer' iconType='location' width='22' height='25' viewBox='0 0 22 25' fillColor='#6A6A6A'/>
								<div>
									<span>Address - Vasanthnagar</span>
									<span>6th Cross, Girinagar, Banashankari 3rd Stage, Avalahalli Extn, Bengaluru 560085</span>
									<span>{staticInfos?.['footer-address']?.value}</span>
								</div>
							</li> */}
						</ul>
					</div>
					<div className='footer-item'>
						<h3>About</h3>
						<ul>
							<li> <Link to={'/newsletters'}>Newsletters</Link> </li>
							<li> <Link onClick={() => window.scroll(0, 0)} to={'/faq/payment-faq'}>Disclaimer</Link> </li>
							<li> <Link onClick={() => window.scroll(0, 0)} to={'/faq/privacy-policy'}>Privacy Policy</Link> </li>
							<li ><Link onClick={() => window.scroll(0, 0)} to={'/terms-conditions'}>Terms & Conditions</Link></li>
							<li><Link to={'/contact-us'}>Contact Us</Link></li>
							<li><Link to={'/sitemap'}>Sitemap</Link></li>
							{/* <a href='../../../assets/sitemap.xml'>sitemap</a> */}
							{/* <li>Support Center</li> */}
						</ul>
					</div>
					<div className='footer-item'>
						<h3 className='padd-l-0'>App & Payment</h3>
						<p>Install App from App Store or Google Play</p>
						<div className='d__flex gap-10'>
							<a href={staticInfos?.['playstore-link']?.value}>
								<img src={playstore} alt='' />
							</a>
							<a href={staticInfos?.['appstore-link']?.value}>
								<img src={iosstore} alt='' />
							</a>
						</div>
						<p>Secured Payment Gateways</p>
						<img src={payments} alt='' />
						{/* <button className='d__flex gap-15 m-d-flex'>
							<div>
								<Icons
									name='footer'
									iconType='make-donation'
									width='38'
									height='38'
									viewBox='0 0 38 38'
								/>
							</div>
							Make A Donation
						</button> */}
					</div>
				</div>
				<div className='secondary-footer d__flex just-s wid-92 mar-auto'>
					<p>
						Copyright © 2023, <b>BMJH</b> - all right reserved{' '}
					</p>
					<div className='d__flex m-d-flex gap-10'>
						Follow Us on Social Media
						<ul className='d__flex gap-10 m-d-flex social-icons'>
							{/* <li><Icons  name='social' iconType='facebook' width="37" height="37" viewBox="0 0 37 37" fillColor='#fff' /></li>
						<li><Icons  name='social' iconType='instagram' width="37" height="37" viewBox="0 0 37 37" fillColor='#fff'/></li>
						<li><Icons  name='social' iconType='twitter' width="37" height="37" viewBox="0 0 37 37" fillColor='#fff'/></li> */}
							{/* <svg  fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="white"/>
						</svg> */}


							{/* <svg width="136" height="37" viewBox="0 0 136 37" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.6"  d="M117.5 36C127.165 36 135 28.165 135 18.5C135 8.83502 127.165 1 117.5 1C107.835 1 100 8.83502 100 18.5C100 28.165 107.835 36 117.5 36Z" stroke="#6A6A6A" /> */}
							{/* <path opacity="0.6"  d="M66.5 36C76.165 36 84 28.165 84 18.5C84 8.83502 76.165 1 66.5 1C56.835 1 49 8.83502 49 18.5C49 28.165 56.835 36 66.5 36Z" stroke="#6A6A6A" /> */}
							{/* <path opacity="0.6"  d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="#6A6A6A" /> */}
							{/* <path d="M113.484 14.9671C114.303 14.9671 114.967 14.3029 114.967 13.4835C114.967 12.6642 114.303 12 113.484 12C112.664 12 112 12.6642 112 13.4835C112 14.3029 112.664 14.9671 113.484 14.9671Z" fill="#6A6A6A"/>
								<path d="M114.72 15.957H112.247C112.111 15.957 112 16.0678 112 16.2043V23.622C112 23.7585 112.111 23.8693 112.247 23.8693H114.72C114.856 23.8693 114.967 23.7585 114.967 23.622V16.2043C114.967 16.0678 114.856 15.957 114.72 15.957Z" fill="#6A6A6A"/>
								<path d="M122.088 15.5484C121.031 15.1864 119.709 15.5043 118.916 16.0745C118.889 15.9682 118.792 15.8891 118.677 15.8891H116.204C116.068 15.8891 115.957 15.9998 115.957 16.1363V23.5541C115.957 23.6906 116.068 23.8013 116.204 23.8013H118.677C118.813 23.8013 118.924 23.6906 118.924 23.5541V18.2232C119.324 17.879 119.838 17.7692 120.26 17.9482C120.668 18.1208 120.902 18.5422 120.902 19.1034V23.5541C120.902 23.6906 121.013 23.8013 121.149 23.8013H123.622C123.759 23.8013 123.869 23.6906 123.869 23.5541V18.6055C123.841 16.5735 122.885 15.8213 122.088 15.5484Z" fill="#6A6A6A"/>
							</svg> */}

							<a href={staticInfos?.['linkedin-link']?.value} target='_blank'>
								<svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clip-path="url(#clip0_12530_2)">
										<path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 36.5C28.165 36.5 36 28.665 36 19C36 9.33502 28.165 1.5 18.5 1.5C8.83502 1.5 1 9.33502 1 19C1 28.665 8.83502 36.5 18.5 36.5Z" stroke="#6A6A6A" stroke-width="1.5" />
										<path d="M14.4835 15.4671C15.3029 15.4671 15.9671 14.8029 15.9671 13.9835C15.9671 13.1642 15.3029 12.5 14.4835 12.5C13.6642 12.5 13 13.1642 13 13.9835C13 14.8029 13.6642 15.4671 14.4835 15.4671Z" fill="#6A6A6A" />
										<path d="M15.7198 16.457H13.2473C13.1108 16.457 13 16.5678 13 16.7043V24.122C13 24.2585 13.1108 24.3693 13.2473 24.3693H15.7198C15.8563 24.3693 15.9671 24.2585 15.9671 24.122V16.7043C15.9671 16.5678 15.8563 16.457 15.7198 16.457Z" fill="#6A6A6A" />
										<path d="M23.0876 16.0484C22.0308 15.6864 20.7089 16.0043 19.9162 16.5745C19.889 16.4682 19.7921 16.3891 19.6769 16.3891H17.2043C17.0678 16.3891 16.957 16.4998 16.957 16.6363V24.0541C16.957 24.1906 17.0678 24.3013 17.2043 24.3013H19.6769C19.8134 24.3013 19.9241 24.1906 19.9241 24.0541V18.7232C20.3237 18.379 20.8385 18.2692 21.2598 18.4482C21.6683 18.6208 21.9022 19.0422 21.9022 19.6034V24.0541C21.9022 24.1906 22.013 24.3013 22.1495 24.3013H24.622C24.7585 24.3013 24.8693 24.1906 24.8693 24.0541V19.1055C24.8411 17.0735 23.8852 16.3213 23.0876 16.0484Z" fill="#6A6A6A" />
									</g>
									<defs>
										<clipPath id="clip0_12530_2">
											<rect width="37" height="37" fill="white" transform="translate(0 0.5)" />
										</clipPath>
									</defs>
								</svg>
							</a>

							{/* <a href={staticInfos?.['linkedin-link']?.value} target='_blank'> */}
							
							{/* </a> */}

							<a href={staticInfos?.['insta-link']?.value} target='_blank'>
								<img src={`${require('../../../assets/insta.png')}`} alt='' />
							</a>

							<a href={staticInfos?.['youtube_link']?.value} target='_blank'>
								<svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clip-path="url(#clip0_12530_7)">
										<path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 36.5C28.165 36.5 36 28.665 36 19C36 9.33502 28.165 1.5 18.5 1.5C8.83502 1.5 1 9.33502 1 19C1 28.665 8.83502 36.5 18.5 36.5Z" stroke="#6A6A6A" stroke-width="1.5" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M26.1045 14.4698C26.6439 15.0306 26.82 16.3042 26.82 16.3042C26.82 16.3042 27 17.8002 27 19.2956V20.698C27 22.194 26.82 23.6894 26.82 23.6894C26.82 23.6894 26.6439 24.963 26.1045 25.5239C25.4878 26.1873 24.8039 26.2559 24.4174 26.2946C24.3749 26.2989 24.336 26.3028 24.3011 26.3071C21.7822 26.4942 18 26.5 18 26.5C18 26.5 13.32 26.4561 11.88 26.314C11.8118 26.3009 11.7291 26.2907 11.6354 26.2792C11.1793 26.223 10.4633 26.1348 9.89494 25.5239C9.3555 24.963 9.18 23.6894 9.18 23.6894C9.18 23.6894 9 22.194 9 20.698V19.2956C9 17.8002 9.18 16.3042 9.18 16.3042C9.18 16.3042 9.3555 15.0306 9.89494 14.4698C10.513 13.8054 11.1975 13.7376 11.5841 13.6993C11.626 13.6951 11.6644 13.6913 11.6989 13.6871C14.2177 13.5 17.9961 13.5 17.9961 13.5H18.0039C18.0039 13.5 21.7822 13.5 24.3011 13.6871C24.3356 13.6913 24.374 13.6951 24.4159 13.6993C24.8022 13.7376 25.487 13.8055 26.1045 14.4698ZM16.1406 17.2031L16.1412 22.3963L21.004 19.8087L16.1406 17.2031Z" fill="#6A6A6A" />
									</g>
									<defs>
										<clipPath id="clip0_12530_7">
											<rect width="37" height="37" fill="white" transform="translate(0 0.5)" />
										</clipPath>
									</defs>
								</svg>
							</a>

							<a href={staticInfos?.['fb-link']?.value} target='_blank'>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.6" d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="#6A6A6A" />
									<path d="M20.0932 14.0208H21.7016C21.8495 14.0208 21.9696 13.9076 21.9696 13.7682V12.2526C21.9696 12.1132 21.8495 12 21.7016 12H20.0932C18.4677 12 17.1445 13.2463 17.1445 14.7786V16.5468H15.2681C15.1201 16.5468 15 16.6599 15 16.7994V18.3149C15 18.4544 15.1201 18.5675 15.2681 18.5675H17.1445V23.8721C17.1445 24.0115 17.2646 24.1247 17.4126 24.1247H19.0209C19.1689 24.1247 19.289 24.0115 19.289 23.8721V18.5675H21.1655C21.2807 18.5675 21.3831 18.4978 21.4201 18.3948L21.9562 16.8792C21.9836 16.8024 21.9696 16.7175 21.9192 16.6513C21.8683 16.5857 21.7879 16.5468 21.7016 16.5468H19.289V14.7786C19.289 14.3608 19.6498 14.0208 20.0932 14.0208Z" fill="#6A6A6A" />
								</svg>
							</a>

							{/* <a href={staticInfos?.['twitter-link']?.value} target='_blank'>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.6" d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="#6A6A6A" />
									<path d="M24.2202 15.2647C24.139 15.1729 24.0081 15.1498 23.9007 15.2064C23.8339 15.2416 23.7083 15.2878 23.5592 15.3334C23.7473 15.0762 23.8985 14.7882 23.9653 14.5293C23.9942 14.4177 23.952 14.3001 23.8601 14.2336C23.7682 14.1677 23.6458 14.1677 23.5539 14.2336C23.4091 14.3375 22.7102 14.6497 22.2683 14.7508C21.2696 13.8511 20.1074 13.7577 18.8992 14.4831C17.9171 15.0729 17.7034 16.2721 17.7451 17.0031C15.4971 16.7805 14.0998 15.5593 13.3132 14.5485C13.2582 14.4776 13.1695 14.4403 13.0861 14.4458C12.9985 14.4524 12.9194 14.5024 12.8745 14.5799C12.5026 15.2257 12.3995 15.9396 12.5774 16.6447C12.6747 17.0295 12.8446 17.3686 13.0418 17.6434C12.9472 17.5956 12.8558 17.5357 12.7698 17.4642C12.6907 17.3972 12.5801 17.3845 12.4866 17.429C12.3936 17.4747 12.3343 17.5714 12.3343 17.6775C12.3343 18.8882 13.0706 19.6962 13.7583 20.1183C13.6472 20.1045 13.5312 20.0792 13.4142 20.0424C13.3138 20.0111 13.2048 20.0424 13.1353 20.1238C13.0658 20.2046 13.0493 20.32 13.0926 20.4184C13.4789 21.2972 14.2109 21.9017 15.1166 22.121C14.3258 22.5986 13.2673 22.8328 12.298 22.7179C12.1724 22.6998 12.0527 22.7817 12.0132 22.9059C11.9736 23.0301 12.0255 23.1664 12.1361 23.2301C13.6066 24.0793 14.936 24.3827 16.0849 24.3827C17.7568 24.3827 19.0472 23.7407 19.8327 23.2038C21.9503 21.7583 23.2701 19.163 23.0863 16.841C23.4256 16.5832 23.9333 16.1072 24.248 15.5955C24.3132 15.4922 24.3014 15.3559 24.2202 15.2647Z" fill="#6A6A6A" />
								</svg>
							</a> */}


						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export { Footer }
export default Footer
