import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getSitemap } from '../../../api/cmsService'
import { CustomizeHeading } from '../../components'
import './Sitemap.scss'

function Sitemap() {
    const routes = [
        {
            group_title :'Top Pages',
            groups :[
                {title:"Home",link:'/'},
                {title:'Facilities',link:'/facilities'},
                {title:'Specialities',link:'/specialties'},
                {title:'Doctors',link:'/doctors'},
                {title:'Medical Packages',link:'/medical-packages'},
                {title:'Book Appointment',link:'/appointment/normal'}
            ]
        },
       {
        group_title :'Pharmacy Information',
        groups :[
            {title:'Admissions',link:'/pharmacy/admissions'},
            {title:'Discharges',link:'/pharmacy/discharges'},
            {title:'Billing',link:'/pharmacy/billings'},
            {title:'Cashless Service',link:'/pharmacy/cashless'},
            {title:`Do's and Don'ts`,link:'/pharmacy/dos-dots'},
            {title:'TPA Insurance',link:'/about/insurance'},
            {title:'Utility And Services',link:'/pharmacy/utilities'},
            {title:'Tariff & Deposit',link:'/pharmacy/tariff-deposits'},
            {title:'Pharmacy Information',link:'/pharmacy/info'},
            {title:'NABH Certifications',link:'/pharmacy/nabh'},
        ]
       },
       {
        group_title : "About Us",
        groups:[
            {title:'Message From The Director',link:'/about/message-from-director'},
            {title:'Bhagwan Mahaveer Memorial Jain Hospital',link:'/about/bmjh-jain'},
            {title:'Bhagwan Mahaveer Memorial Jain Medicals',link:'/about/bmjh-medicals'},
        ]
       },
       {
        group_title:'Other',
        groups:[
            {title:'Contact Us',link:'/contact-us'},
            {title:'Privacy Policy',link:'/faq/privacy-policy'},
            {title:'Terms & Conditions',link:'/terms-conditions'},
            {title:'Disclaimer',link:'/faq/payment-faq'},
            {title:'Managing Trustees',link:'/about/managing_trustees'},
            {title:'Careers',link:'/careers'},
            {title:'Newsletters',link:'/newsletters'},
            {title:'Academics',link:'/academics'},
            {title:'Ethics Committee',link:'/about/ethics_committee'},
            {title:'Patient Instruction',link:'/about/patient_instruction_for_healthcheck'},
            {title:'Telephone Extension Nos',link:'/about/telephone_extension_nos'},
            {title:'Blogs /Vlogs',link:'/blogs'}
        ]
       }
    ]


    const [siteMap,setSiteMap] = useState([])
    const dispatch = useDispatch();

    const { selectedHospital } = useSelector(state => state?.doctorsInfo)

    const sieMapInfo =async () =>{
       let response =  await dispatch(getSitemap({hospital_id:selectedHospital}));
       setSiteMap([{group_title:'Consultants',group_link:'/doctors/', groups: response?.consultants,},{group_title:'Facilities',group_link:'/facilities/', groups: response?.facilities,},{ group_title:'specialties',group_link:'/specialities/',groups:response?.specialities,},...routes])
    }


    useEffect(()=>{
        selectedHospital && sieMapInfo()
    },[selectedHospital])

  return (
    <div className='pharmacy-information  mar-b-135 container'>
        <CustomizeHeading title={'Sitemap'}/>
        <div>
            {
                siteMap.map((v,i)=>
                <div>
                    <h3>{v?.group_title}</h3>
                    <div className='sitemap-links'>
                    {
                        v?.groups?.map((value,index) =>
                        <div>
                            <Link to={value?.link ? value?.link : v?.group_link+value?.id }>
                                <p>{value?.title ?? value?.name}</p> 
                            </Link>
                            |
                        </div>
                        )
                    }
                    </div>
                </div>
                )
            }
        </div>
    </div>
  )
}

export default Sitemap