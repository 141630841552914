import './PharmacyInformation.scss';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCmsDetails } from '../../../api/cmsService';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { CustomizeHeading } from '../../components';
import Slider from 'react-slick';
import { enableLocation } from '../../../store/header';
import { isEmpty } from 'lodash';

function PharmacyInformation() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [info,setInfo] =  useState([]);
  const { selectedHospital } = useSelector(state => state.doctorsInfo)
  const getDetailData = async (custom) => {
		const response = await dispatch(getCmsDetails({hospital_id:selectedHospital, url: id == 'info' ? 'pharmacy-information' : (custom ?? id) }))
    if( custom && id == 'utilities'){
      setInfo((prev)=> [...prev,response])
    } else {
      setInfo([response])
    }
	} 

  let pages = (selectedHospital == 1) ? ['private-nurses','ambulance','cafeteria','parking'] :  ['private-nurses-girinagar','ambulance-girinagar','cafeteria-girinagar','parking-girinagar']

  const [align,setAlign] = useState(false)
  const alignPages = () => {
    let d = []
    d.push(info.find(v => v.slug == pages[0]))
    d.push(info.find(v => v.slug == pages[1]))
    d.push(info.find(v => v.slug == pages[2]))
    d.push(info.find(v => v.slug == pages[3]))

    const val = d.filter(element => {
      return element !== null && element !== undefined;
    });
    setInfo(val)
    setAlign(true)
  }

  useEffect(() => {
    dispatch(enableLocation())
    if(info?.length && info.length == 4 && !align){
      alignPages()
    }
    return () => {
			dispatch(enableLocation())
		}
  },[info])

  useEffect(() => {  
    if(selectedHospital){
      if(id) {
        setInfo([])
      } 
      (id &&  id != 'utilities' ) ? getDetailData(null) : getUtilities()
    }
    return () => {}
  }, [id,selectedHospital])


  const getUtilities = () => {
    if(id == 'utilities'){
      setAlign(false)
      pages.map(v=>{
        getDetailData(v)
      })
    }
  }

  
  

  const sliderSettings =  {
		centerPadding: "20px",
		slidesToShow: 1,
		swipeToSlide : true,
		speed: 500,
		autoplay: true,
		dots :true,
		focusOnSelect: false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 1,
				infinite: false,
				dots: false,
				arrows : false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				// initialSlide: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : false
			  }
			}
		]
	};

  return (
    <div className='mar-b-135 container'>
      	<Helmet>
          <title>BMJH | {`${ info?.[0]?.meta_title }`}</title>
          <meta property='og:keywords' content={`${ info?.[0]?.meta_keywords }`} />
          <meta property="og:title" content={`${ info?.[0]?.meta_title }`} />
          <meta property="og:description" content={`${ info?.[0]?.meta_description }`}/>

          <meta name="twitter:title" content={`${ info?.[0]?.meta_title }`} />
          <meta name="twitter:description" content={`${ info?.[0]?.meta_description }`}/>
        </Helmet>

        {
         info[0]?.banner_image &&  (id == 'nabh' || id =='nabh-girinagar' ) && 
          <div className='about-carousel nabh-carousel'>
          <Slider {...sliderSettings}>
            {info[0]?.banner_image && <div className='slider-img' >
              <img src={info[0]?.banner_image} alt='' />
            </div>}
            {/*
            (info[0]?.image || info[0]?.image1 || info[0]?.image2 || info[0]?.image3 )  &&
            {info[0]?.image1 && <div className='slider-img' >
              <img src={info[0]?.image1} alt='' />
            </div>}
            {info[0]?.image2 && <div className='slider-img' >
              <img src={info[0]?.image2} alt='' />
            </div>}
            {info[0]?.image3 && <div className='slider-img' >
              <img src={info[0]?.image3} alt='' />
            </div>} */}
          </Slider>
        </div>}
        
        <div className='pharmacy-information'>
        {
        info?.map((v,i) => 
        <div key={'UL'+i}>
          <CustomizeHeading title={v?.title }/>

          {/* style={{minHeight:'350px'}} */}
          <div>
          <div className='d-flex' >
            { v?.slug != 'nabh' && v?.slug != 'nabh-girinagar' && <div className='pharmacy-image' style={{display:'grid',gap:'20px'}}>
                {v?.image && <img src={v?.image} alt='' />}
            </div>}
            {info && <div dangerouslySetInnerHTML={{__html: v?.content}} />}
            </div>
            {(v?.slug == 'nabh' || v.slug == 'nabh-girinagar') && <div className='d__flex flex-w gap-10'>
                {v?.image && <img src={v?.image} alt='' width={'400'}/>}
                {v?.image1 && <img src={v?.image1} alt='' width={'400'}/>}
                {v?.image2 && <img src={v?.image2} alt='' width={'400'}/>}
                {v?.image3 && <img src={v?.image3} alt='' width={'400'} /> }
            </div>} 
          </div>  

          <div className='blog-detail-ctr'>
              

            {
              v && v?.slug == 'room-beds' && (v?.image1 || v?.image2 || v?.image3 || v?.image4 || v?.image5 || v?.image6 || v?.image7 || v?.image8 || v?.image9 || v?.image10 )  && 
              <div className='event-images'>
                {v?.image1 && <div className='slider-img' >
                  <img src={v?.image1} alt='' />
                </div>}
                {v?.image2 && <div className='slider-img' >
                  <img src={v?.image2} alt='' />
                </div>}
                {v?.image3 && <div className='slider-img' >
                  <img src={v?.image3} alt='' />
                </div>}
                {v?.image4 && <div className='slider-img' >
                  <img src={v?.image4} alt='' />
                </div>}
                {v?.image5 && <div className='slider-img' >
                  <img src={v?.image5} alt='' />
                </div>}
                {v?.image6 && <div className='slider-img' >
                  <img src={v?.image6} alt='' />
                </div>}
                {v?.image7 && <div className='slider-img' >
                  <img src={v?.image7} alt='' />
                </div>}
                {v?.image8 && <div className='slider-img' >
                  <img src={v?.image8} alt='' />
                </div>}
                {v?.image9 && <div className='slider-img' >
                  <img src={v?.image9} alt='' />
                </div>}
                {v?.image10 && <div className='slider-img' >
                  <img src={v?.image10} alt='' />
                </div>}
              </div>
              }

          </div>

        </div>
        )
       }
       
       
        </div>



    </div>
  )
}

export default PharmacyInformation
