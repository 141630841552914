import { createSlice } from '@reduxjs/toolkit'

const categoriesSlice = createSlice({
	name: 'categories',
	initialState: {
		homePageData: [],
		categoriesList: [],
		subCategoriesList: [],
		facilityList: [],
	},
	reducers: {
		homePageData: (state, action) => ({
			...state,
			homePageData: action.payload,
		}),
		categoriesList: (state, action) => ({
			...state,
			categoriesList: action.payload,
		}),
		subCategoriesList: (state, action) => ({
			...state,
			subCategoriesList: action.payload,
		}),
		facilityList: (state, action) => ({
			...state,
			facilityList: action.payload,
		}),
	},
})

export const { homePageData, categoriesList, subCategoriesList, facilityList } =
	categoriesSlice.actions

export default categoriesSlice.reducer
