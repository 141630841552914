import React from 'react'

import { Footer, Header } from 'components'
import { BookAppointment, FindDoctor, Home } from 'pages'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ScrollTop } from 'primereact/scrolltop'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
	AllAppointments,
	AppointmentDetail,
	DigitalCard,
	Profile,
	RecordList,
	Records,
	MobileTab,
	AutoScroll
} from '../views/components'
import {
	AboutUs,
	Appointments,
	Blogs,
	BlogsDetail,
	Checkout,
	BookingStatus,
	ContactUs,
	DoctorDetails,
	Doctors,
	Facilities,
	FacilitiesDetail,
	MedicalPackageDetail,
	MedicalPackages,
	MyProfile,
	PharmacyInformation,
	Specialties,
	SpecialtiesDetail,
	Gallery,
	Faq,
	Terms,
	About,
	Careers,
	CareersDetail,
	Newsletters,
	NABH,
	NAB,
	Academics,
	Certifications,
	Sitemap,
	Testimonials,
} from '../views/pages'
import { useSelector } from 'react-redux'
import RoomBeds from '../views/pages/RoomBeds/RoomBeds'
import Questionary from '../views/pages/Questionary/Questionary'


class RoutesConfig extends React.Component {

	render() {



		return (
			<>
				<BrowserRouter>
					<AutoScroll />
					<Header />
					<div style={{ minHeight: '110vh' }}>
						<Routes>
							<Route path='/findDoctor' element={<FindDoctor />} />
							<Route path='/bookAppointment' element={<BookAppointment />} />
							<Route path='/facilities' element={<Facilities />} />
							<Route path='/facilities/:id' element={<FacilitiesDetail />} />
							<Route path='/specialties' element={<Specialties />} />
							<Route path='/specialties/:id' element={<SpecialtiesDetail />} />
							<Route path='/doctors' element={<Doctors />} />
							<Route path='/doctors/:id' element={<DoctorDetails />} />
							<Route path='/blogs/:id' element={<Blogs />} />
							<Route path='/blog-detail/:id' element={<BlogsDetail />} />
							{/* <Route path='/history' element={<AboutUs />} /> */}
							<Route path='/about/:id' element={<About />} />
							<Route path='/contact-us' element={<ContactUs />} />
							<Route path='/pharmacy/:id' element={<PharmacyInformation />} />
							<Route path='/appointment/:type' element={<Appointments />} />
							<Route path='/room-beds' element={<RoomBeds />} />
							<Route path='/profile' element={<MyProfile />}>
								<Route path='records' element={<RecordList />} />
								<Route path='records/:id' element={<Records />} />
								<Route path='card' element={<DigitalCard />} />
								<Route path='my-appointments' element={<AllAppointments />} />
								<Route
									path='my-appointments/:id'
									element={<AppointmentDetail />}
								/>
								<Route path='edit' exact element={<Profile />} />
							</Route>
							<Route path='/checkout' element={<Checkout />} />
							<Route path='/Bookingstatus' element={<BookingStatus />} />
							<Route path='/medical-packages' element={<MedicalPackages />} />
							<Route path='/faq/:id' element={<Faq />} />
							<Route path='/faq/:id' element={<Faq />} />
							<Route path='/terms-conditions' element={<Terms />} />
							<Route
								path='/medical-packages/:id'
								element={<MedicalPackageDetail />}
							/>
							<Route path='/gallery' element={<Gallery />} />
							<Route path='/newsletters' element={<Newsletters />} />
							<Route path='/careers' element={<Careers />} />
							<Route path='/nab' element={<NAB />} />
							<Route path='/academics' element={<Academics />} />
							<Route path='/sitemap' element={<Sitemap />} />
							<Route path='/testimonials' element={<Testimonials />} />
							<Route path='/feedbacks' element={<Questionary />} />
							<Route path='/' exact element={<Home />} />
						</Routes>
					</div>
					<ScrollTop icon='pi pi-arrow-circle-up' />
					<Footer />
					<MobileTab />
				</BrowserRouter>
			</>
		)
	}
}

export default RoutesConfig
export { RoutesConfig }
