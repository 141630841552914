import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCmsDetails } from 'api/cmsService';

function Terms() {
    const dispatch = useDispatch();
    const [info,setInfo] =  useState({});

    const getDetailData = async () => {
		const response = await dispatch(getCmsDetails({url:'terms-conditions'}))
        setInfo(response)
	} 


  useEffect(() => {  
    getDetailData();
    return () => {
    }
  }, [])

  return (
    <div className='mar-b-135 container'>
        {info && <div dangerouslySetInnerHTML={{__html: info?.content}} />}
    </div>
  )
}

export default Terms