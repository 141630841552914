import React, { useState } from 'react'
import './TestimonialCard.scss'
import Icons from '../../Icons'
import ModalVideo from 'react-modal-video';
import { useDispatch } from 'react-redux';
import { enableModalVideo } from '../../../../store/header';

function TestimonialCard({value:res,isFromDoctor = false}) {
  const [videoId,setVideoId] = useState(undefined);
  const dispatch = useDispatch()
  return (
    <div className='info-text'>
    <div className='testimonial-card '>
        {res?.youtube_link && 
        <div className='position-relative'>
            <img className='test-blog-image' src={'https://i.ytimg.com/vi/'+res?.youtube_link+'/0.jpg'} alt=''  height="" />
            <div className='play-icon cursor-pointer' onClick={() => dispatch(enableModalVideo(res?.youtube_link))}>
              <Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
            </div>
        </div>}
        
        {res?.rating_title && <h3>{res?.rating_title}</h3>}
        {res.message != '' && <p className={`fnt-16 ${res?.youtube_link?'info-video-text':''}`} title={res.message ?? res?.rating_descriptions}>"{ !isFromDoctor ? (res.message) : res?.rating_descriptions}"</p>}
        <div className='profile-card d__flex m-d-flex gap-15'>
            {/* <div className='image-avatar'>
                <img src={require('../../../../assets/default/blog-author-image.png') } alt='' />
            </div> */}
            <div className='flex-1'>
            <h5 className='m-0'>{!isFromDoctor ? (res?.customer_full_name ?? res?.customer_name) : res?.user_name}</h5>
            <p className='fnt-14 p-0'>{res?.type ?? res?.customer_place }</p>
            </div>
        </div>
        
    </div>
    
    </div>
  )
}

export default TestimonialCard