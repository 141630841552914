import React from 'react';

import get from 'get-value';

function CommonIcons(props) {
	const matchIcon = (iconType, iconColor, customIcon) => {
		switch (iconType) {
			case 'arrow-prev':
				return (
					<>
                        <g clipPath="url(#clip0_855_6654)">
                        <path d="M1.35231 10.0063L11.2592 -0.296875L14.5223 3.09672L6.92307 10.9999H30V15.7999H6.92307L14.5223 23.7031L11.2592 27.0967L1.35231 16.7935C0.487055 15.8934 0.000983092 14.6727 0.000983092 13.3999C0.000983092 12.1271 0.487055 10.9065 1.35231 10.0063V10.0063Z" fill="url(#paint0_linear_855_6654)"/>
                        </g>
                        <defs>
                        <linearGradient id="paint0_linear_855_6654" x1="30" y1="18.7593" x2="0.000884122" y2="18.7614" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E5F8FF"/>
                        <stop offset="1" stopColor="#E5F8FF"/>
                        </linearGradient>
                        <clipPath id="clip0_855_6654">
                        <rect width="30" height="28.8" fill="white" transform="matrix(-1 0 0 1 30 0)"/>
                        </clipPath>
                        </defs>
					</>
				)
			case 'arrow-next':
				return (
					<>
                        <g clipPath="url(#clip0_855_6646)">
                        <path d="M28.6477 10.0063L18.7408 -0.296875L15.4777 3.09672L23.0769 10.9999H0V15.7999H23.0769L15.4777 23.7031L18.7408 27.0967L28.6477 16.7935C29.5129 15.8934 29.999 14.6727 29.999 13.3999C29.999 12.1271 29.5129 10.9065 28.6477 10.0063V10.0063Z" fill="url(#paint0_linear_855_6646)"/>
                        </g>
                        <defs>
                        <linearGradient id="paint0_linear_855_6646" x1="0.332268" y1="13.3999" x2="29.999" y2="13.3999" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F06426"/>
                        <stop offset="1" stopColor="#E21E29"/>
                        </linearGradient>
                        <clipPath id="clip0_855_6646">
                        <rect width="30" height="28.8" fill="white"/>
                        </clipPath>
                        </defs>
					</>
				)
			case 'arrow-next-white':
				return (
					<>
						<path d="M29.1086 10.3061L19.2017 0.00292969L15.9386 3.39653L23.5379 11.2997H0.460938V16.0997H23.5379L15.9386 24.0029L19.2017 27.3965L29.1086 17.0933C29.9739 16.1932 30.46 14.9725 30.46 13.6997C30.46 12.4269 29.9739 11.2063 29.1086 10.3061Z" fill="white"/>
					</>
				)
			case 'play-button' :
				return (
					<>
						<path d="M4.61062 0.934034C2.61345 -0.336896 0 1.09775 0 3.46502V24.3532C0 26.7204 2.61345 28.1551 4.61063 26.8841L21.0227 16.4401C22.8752 15.2612 22.8752 12.557 21.0227 11.3781L4.61062 0.934034Z" fill="url(#paint0_linear_788_8171)"/>
						<defs>
						<linearGradient id="paint0_linear_788_8171" x1="0.276899" y1="13.9091" x2="25" y2="13.9091" gradientUnits="userSpaceOnUse">
						<stop stopColor="#F06426"/>
						<stop offset="1" stopColor="#E21E29"/>
						</linearGradient>
						</defs>
					</>
				)
			case 'search':
				return (
					<>
						<path d="M20.873 19.5945L15.6617 14.3831C17.0154 12.7275 17.681 10.6149 17.5207 8.48226C17.3605 6.34967 16.3867 4.36024 14.8008 2.92547C13.2149 1.49071 11.1382 0.720392 9.00025 0.773847C6.86231 0.827302 4.8267 1.70044 3.31448 3.21267C1.80225 4.72489 0.929109 6.7605 0.875654 8.89844C0.822199 11.0364 1.59252 13.1131 3.02728 14.699C4.46204 16.2849 6.45147 17.2587 8.58407 17.4189C10.7167 17.5791 12.8293 16.9136 14.4849 15.5599L19.6963 20.7712L20.873 19.5945ZM9.22192 15.7779C7.90513 15.7779 6.61792 15.3874 5.52305 14.6559C4.42818 13.9243 3.57484 12.8845 3.07093 11.6679C2.56701 10.4514 2.43517 9.11273 2.69206 7.82124C2.94895 6.52976 3.58304 5.34345 4.51415 4.41235C5.44526 3.48124 6.63157 2.84714 7.92305 2.59025C9.21453 2.33336 10.5532 2.46521 11.7697 2.96912C12.9863 3.47303 14.0261 4.32638 14.7577 5.42124C15.4892 6.51611 15.8797 7.80333 15.8797 9.12011C15.8777 10.8853 15.1756 12.5775 13.9275 13.8257C12.6793 15.0738 10.9871 15.7759 9.22192 15.7779Z" fill="#253D4E"/>
					</>
				)
			case 'chevron-backward':
				return (
					<>
						<path fillRule="evenodd" clipRule="evenodd" d="M7 1L1 7L7 13" fill="url(#paint0_linear_788_11890)"/>
						<path d="M7 1L1 7L7 13" stroke="#979797" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<defs>
						<linearGradient id="paint0_linear_788_11890" x1="4" y1="-5" x2="13.6" y2="-0.2" gradientUnits="userSpaceOnUse">
						<stop stopColor="#EAEAEA"/>
						<stop offset="1" stopColor="#EAEAEA"/>
						</linearGradient>
						</defs>
					</>
				)
			case 'chevron-forward':
				return (
					<>
						<path fillRule="evenodd" clipRule="evenodd" d="M1 1L7 7L1 13" fill="url(#paint0_linear_788_11894)"/>
						<path d="M1 1L7 7L1 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<defs>
						<linearGradient id="paint0_linear_788_11894" x1="1.06646" y1="7" x2="7" y2="7" gradientUnits="userSpaceOnUse">
						<stop stopColor="#F06426"/>
						<stop offset="1" stopColor="#E21E29"/>
						</linearGradient>
						</defs>
					</>
				)
			case 'add':
				return (
					<>
						<path fillRule="evenodd" clipRule="evenodd" d="M11.2416 1.10422C11.1694 0.48255 10.641 0 10 0C9.30964 0 8.75 0.559644 8.75 1.25V8.75H1.25L1.10422 8.75841C0.48255 8.83062 0 9.35896 0 10C0 10.6904 0.559644 11.25 1.25 11.25H8.75V18.75L8.75841 18.8958C8.83062 19.5175 9.35896 20 10 20C10.6904 20 11.25 19.4404 11.25 18.75V11.25H18.75L18.8958 11.2416C19.5175 11.1694 20 10.641 20 10C20 9.30964 19.4404 8.75 18.75 8.75H11.25V1.25L11.2416 1.10422Z" fill="url(#paint0_linear_788_15224)"/>
						<defs>
						<linearGradient id="paint0_linear_788_15224" x1="0.624357" y1="9.89617" x2="20.099" y2="9.89617" gradientUnits="userSpaceOnUse">
						<stop stopColor="#F06426"/>
						<stop offset="1" stopColor="#E21E29"/>
						</linearGradient>
						</defs>	
					</>
				)
			default:
				undefined
		}
	}

	const className = get(props, 'className', '')
	const iconType = get(props, 'iconType', '')
	const iconColor = get(props, 'iconColor', '#000')
	const customIcon = get(props, 'customIcon', '')
	const width = get(props, 'width', '20px')
	const height = get(props, 'height', '')
	const viewBox = get(props, 'viewBox', '0 0 24 24')
	const onClick = get(props, 'onClick', {})
	const strokeColor = get(props, 'strokeColor', '')
	const strokeWidth = get(props, 'strokeWidth', '')
	const fill = get(props, 'fillColor', 'none')
	return (
		<svg
			className={className}
			viewBox={viewBox}
			stroke={strokeColor}
			strokeWidth={strokeWidth}
			fill={fill}
			onClick={onClick}
			style={{ width: width, height: height }}>
			{matchIcon(iconType, iconColor, customIcon)}
		</svg>
	)
}

export { CommonIcons };
export default CommonIcons
