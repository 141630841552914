import axios from 'axios'

import { enableToast } from 'store/header'
import { staticInfos } from '../store/header'

axios.defaults.baseURL = process.env.API_DOMAIN
const vendor_id = process.env.API_VENDOR_ID
const customer_id = sessionStorage.getItem('customerID')
const token = sessionStorage.getItem('token')

export const getCmsList = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/cms/listall', {
            vendor_id,
            token: sessionStorage.getItem('token'),
            ...payload,
        })
        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const getTestimonials = payload => async dispatch => {
    try {
        let response = await axios.post('customer/crm/v-1-0-0/hospital/hospital-ratings', {
            vendor_id,
            token: sessionStorage.getItem('token'),
            ...payload,
        })
        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const getCmsDetails = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/cms/details', {
            vendor_id,
            token: sessionStorage.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const getRoomBeds = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/roomnbeds/list', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}


export const setQuestions = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/customer_feedbacks/create', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            customer_id: sessionStorage.getItem('customerID'),
            ...payload,
        })
        // dispatch(enableToast(response?.data))
        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const setFeedbackUser = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/customer_feedbacks/master_create', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            customer_id: sessionStorage.getItem('customerID'),
            ...payload,
        })
        dispatch(enableToast(response?.data))
        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const getQuestions = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/feedback/list', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}


export const getRoomBedDetail = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/roomnbeds/view', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}


export const getGallery = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/gallery/list', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}


export const getGalleryDepartments = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/gallery/title_list', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const getGalleryDetail = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/gallery/view', {
            vendor_id,
            token: sessionStorage?.getItem('token'),
            device_type: "web",
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const newsletterList = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/pims/v-1-0-0/news_letter/list', {
            vendor_id,
            token: sessionStorage.getItem('token'),
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const careerList = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/pims/v-1-0-0/careers/list', {
            vendor_id,
            token: sessionStorage.getItem('token'),
            ...payload,
        })

        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const careerAdd = payload => async dispatch => {
    try {

        const file = new FormData()
        file.append('vendor_id', vendor_id)
        file.append('token', sessionStorage.getItem('token'))
        file.append('customer_id', sessionStorage.getItem('customerID'))
        file.append('hospital_id', payload?.hospital_id)
        file.append('firstname', payload?.firstname)
        file.append('lastname', payload?.lastname)
        file.append('email', payload?.email)
        file.append('phone', payload?.phone)
        file.append('address', payload?.address)
        file.append('status', 1)
        file.append('resume', payload?.resume)
        file.append('career_id', payload?.career_id)
        // file.append('appoinment_id', payload?.appoinment_id)
        // file.append('name', payload?.name)

        // payload?.resume?.forEach((a, index) => file.append('resume[' + index + ']', a))
        let response = await axios.post('/customer/crm/v-1-0-0/careerform/store', file)
        dispatch(enableToast(response?.data))
        return response?.data?.data
    } catch (e) {
        // return dispatch(enableToast(response?.data?.message))
    }
}

export const getStaticInfos = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/static-pages', {
            vendor_id,
            token: sessionStorage.getItem('token'),
        })
        dispatch(staticInfos(response?.data?.data))
        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

export const getSitemap = payload => async dispatch => {
    try {
        let response = await axios.post('/customer/crm/v-1-0-0/sitemap-details', {
            vendor_id,
            token: sessionStorage.getItem('token'),
            ...payload
        })
        return response?.data?.data
    } catch (e) {
        return dispatch(enableToast(response?.data?.message))
    }
}

