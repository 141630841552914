
import React, { useRef } from 'react'
import { DoctorBoxMini, Icons } from 'components'
import { useSelector } from 'react-redux'
import './DoctorBoxMiniScroll.scss'
import Slider from 'react-slick'

function DoctorBoxMiniScroll({ scroll, small, title, doctors }) {
	const scrollRef = useRef(null)
	const scroll_to = scrollOffset => {
		scrollRef.current.scrollLeft += scrollOffset
	}

	const sliderSettings = {
		infinite: false,
		centerPadding: "0px",
		slidesToShow: 3,
		speed: 500,
		autoplay: false,
		dots :false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				dots: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : false
			  }
			}
		]
	};

	const slider = useRef();

	const next = ()  => {
	  slider.current.slickNext();
	}
	
	const previous = () => {
	  slider.current.slickPrev();
	}
  

	// const { recentlyVisited } = useSelector(state => state.doctorsInfo)

	return (
		<div className='doctor-box-mini-scroll'>
			<div className='d__flex just-s m-d-flex '>
				<div>{title && <h4>{title}</h4>}</div>
				<div className='d__flex gap-15 arrows-prev-next m-d-flex'>
					<button className='' onClick={previous}>
						<Icons
							name='common'
							iconType='arrow-prev'
							width='15px'
							strokeWidth='0'
							strokeColor='#ffff'
							viewBox='0 0 30 29'
						/>{' '}
					</button>
					<button onClick={next}>
						<Icons
							name='common'
							iconType='arrow-next-white'
							width='15px'
							strokeWidth='0'
							strokeColor='#ffff'
							viewBox='0 0 30 29'
						/>{' '}
					</button>
				</div>
			</div>
			<div>
				<Slider {...sliderSettings} ref={slider} >
					{doctors?.map((res, index) => (
						<div className='item' key={index + 'dbx'}>
							<DoctorBoxMini doctor={res} small={small} />
						</div>
					))}
				</Slider>
			</div>
			{/* <div
				className={`d__flex gap-60 scroll-item m-d-flex ${
					scroll ? 'scrollable' : 'non-scrollable'
				}`}
				ref={scrollRef}>

			</div> */}
		</div>
	)
}

export default DoctorBoxMiniScroll
export { DoctorBoxMiniScroll }
