import './styles.scss'

import React from 'react'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'store/configureStore'

import { RoutesConfig } from '../routesConfig'
import "primereact/resources/themes/lara-light-indigo/theme.css";

const root = ReactDOM.createRoot(document.getElementById('app-root'))
const sitemap = require('../assets/sitemap.xml')

root.render(
	<div className='App'>
		<Provider store={store}>
			<RoutesConfig />
		</Provider>
	</div>
)
// const App = () => {
// 	return (
// 		<div className='App'>
// 			<Provider store={store}>
// 				<RoutesConfig />
// 			</Provider>
// 		</div>
// 	)
// }
// ReactDOM.render(<App />, document.getElementById('app-root'))
