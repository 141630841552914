import React, { useState, useEffect } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog' // To use <ConfirmDialog> tag
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signOut, getUserProfile } from 'api/authService'
import { isEmpty } from 'lodash'
import Icons from '../Icons'
import './ProfileMenu.scss'

function ProfileMenu() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState(false)
	const { userProfile } = useSelector(state => state.header)

	const accept = () => {
		dispatch(signOut())
		navigate('/')
	}

	useEffect(() => {
		if (isEmpty(userProfile)) {
			dispatch(getUserProfile())
		}
	}, [userProfile])

	const reject = () => {}

	return (
		<div className='profile-menu'>
			<div className='info-ctr'>
				<div className='image-ctr'>
					<img
						src={userProfile?.profile_image}
						// onError={({ currentTarget }) => {
						// 	currentTarget.onerror = null
						// 	currentTarget.src = profile
						// }}
						alt={userProfile?.firstname}
					/>
				</div>
				<h6 className='fnt-14 m-0'>
					{userProfile?.firstname + ' ' + userProfile?.lastname}
				</h6>
				<p className='m-0'>{userProfile?.phone}</p>
			</div>

			<ul>
				<li>
					<NavLink
						to={'/profile/card'}
						className={({ isActive }) => (isActive ? 'active' : 'none')}>
						<Icons
							name='my-account'
							iconType='card'
							width='20'
							height='20'
							viewBox='0 0 17 23'
						/>
						My Digital Card
						<Icons
							name='my-account'
							iconType='arrow'
							width='13'
							height='14'
							viewBox='0 0 13 14'
						/>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={'/profile/edit'}
						exact={'true'}
						strict='true'
						className={({ isActive }) => (isActive ? 'active' : 'none')}>
						<Icons
							name='my-account'
							iconType='my-profile'
							width='20'
							height='20'
							viewBox='0 0 27 27'
						/>
						My Profile
						<Icons
							name='my-account'
							iconType='arrow'
							width='13'
							height='14'
							viewBox='0 0 13 14'
						/>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={'/profile/my-appointments'}
						className={({ isActive }) => (isActive ? 'active' : 'none')}>
						<Icons
							name='my-account'
							iconType='appointment'
							width='20'
							height='20'
							viewBox='0 0 20 20'
						/>
						My Appointments
						<Icons
							name='my-account'
							iconType='arrow'
							width='13'
							height='14'
							viewBox='0 0 13 14'
						/>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={'/profile/records'}
						className={({ isActive }) => (isActive ? 'active' : 'none')}>
						<Icons
							name='my-account'
							iconType='record'
							width='20'
							height='20'
							viewBox='0 0 16 21'
						/>
						My Records
						<Icons
							name='my-account'
							iconType='arrow'
							width='13'
							height='14'
							viewBox='0 0 13 14'
						/>
					</NavLink>
				</li>
			</ul>

			<div className='d__flex log-out-btn just-c mb-30'>
				<button className='logout' onClick={() => setVisible(true)}>
					Logout
				</button>
			</div>
			<ConfirmDialog
				visible={visible}
				onHide={() => setVisible(false)}
				message='Are you sure you want to Logout?'
				header='Confirmation'
				icon='pi pi-exclamation-triangle'
				accept={accept}
				reject={reject}
				className='cfrm'
			/>
		</div>
	)
}

export default ProfileMenu
