import React, { useEffect } from 'react'
import './Testimonials.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getTestimonials } from '../../../api/cmsService';
import { useState } from 'react';
import { CustomizeHeading, TestimonialCard } from '../../components';

function Testimonials() {

  const dispatch = useDispatch();
  const { selectedHospital } = useSelector(state => state?.doctorsInfo)
  const [testimonial,setTestimonial] = useState([])
  const [testimonialVideos,setTestimonialVideos] = useState([])
  const getTestimonial = async () => {
    let testi = await dispatch(getTestimonials({hospital_id:selectedHospital}));
    //

    let testimonialVideo = []
		let testimonialNonVideo = []

    testi.map(res => {
      (res.youtube_link && res.youtube_link != '') ? testimonialVideo.push(res) : testimonialNonVideo.push(res)
   })

   setTestimonial(testimonialNonVideo)
   setTestimonialVideos(testimonialVideo)

  }
  useEffect(() => {
    
    selectedHospital && getTestimonial()
    
    return () => {
      
    }
  }, [selectedHospital])
  
  return (
    <div className='testimonials-all testimonials mar-b-135 container'>
        <CustomizeHeading title='Patient Testimonials'/>

        {testimonial.map((v,i)=>
        <div key={i+"te"}>
          <TestimonialCard value={v} />
        </div>
        )}

        <div className='d__flex flex-w gap-10' >
          {testimonialVideos.map((v,i)=>
            <div key={i+"te"} className='testimonial-card-box'>
              <TestimonialCard value={v} />
            </div>
          )}
        </div>
    </div>
  )
}

export default Testimonials