import React, { useEffect, useRef, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { Sidebar } from 'primereact/sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Menubar } from 'primereact/menubar'
import { Dropdown, GuestAuth, Icons, MenuItems, OtpPopup } from 'components'
import { enableLoginModal as setLoginModal } from 'store/header'
import { concat } from 'lodash'
import { Menu } from 'primereact/menu';
import './SecondaryHeader.scss'
import { Button } from 'primereact/button';

function SecondaryHeader(props) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [visible, setVisible] = useState(false)
	const [sideMenu, setSideMenu] = useState(false)
	//const [showLoginModal, setLoginModal] = useState(false)
	const { isRegisteredUser, userProfile, enableLoginModal } = useSelector(
		state => state.header
	)

	const { selectedHospital } = useSelector(state => state?.doctorsInfo)
	
	const clicked = event => {
		menuRef.current && !menuRef.current.contains(event.target)
			? setVisible(false)
			: null
	}

	// useEffect(() => {
	// 	if (isRegisteredUser) {
	// 		setLoginModal(false)
	// 	}
	// })

	const getFacilityMenu = props?.navFacilityList?.map(m => {
		return {
			label: m?.name,
			command: () => {
				navigate(`/facilities/${m?.id}`)
			},
		}
	})
	const getSpecMenu = props?.navSpecList?.map(m => {
		return {
			label: m?.name,
			url: '',
			items: m?.subcategory?.map(s => {
				return {
					label: s?.name,
					command: () => {
						navigate(s.name_slug != 'preventive-healthcare-checkup-services' && s.name_slug != 'preventive-health-checkup-services' ?`/specialties/${s?.id}`:'/medical-packages')
						// item?.name_slug != 'preventive-healthcare-checkup-services' && item?.name_slug !='preventive-health-checkup-services'?`/specialties/${item?.id}`:'/medical-packages'
					},
				}
			}),
		}
	})


	// console.log(selectedHospital)
	const menu = [
		
		{
			label: 'Home',
			command: () => {
				navigate('/')
			},
		},
		{
			label: 'About Us',
			items: concat(
				{
					label: 'History of Trust, Hospital',
					command: () => {
						navigate('/about/history-of-trust-hospital')
					},
				},
				{
					label: 'Message From The Director',
					command: () => {
						navigate('/about/message-from-director')
					},
				},
				{
					label:'Components of Bhagwan Mahaveer Memorial Jain Trust',
					items:concat(
						{
							label : 'Bhagwan Mahaveer Jain Hospital Vasanthnagar',
							command : () => {
								navigate('/about/bhagwan-mahaveer-jain-hospital-vasanthnagar')
							}
						},
						{
							label : 'Bhagwan Mahaveer Jain Hospital Girinagar',
							command : () => {
								navigate('/about/bhagwan-mahaveer-jain-hospital-girinagar')
							}
						},
						{
							label : 'Bhagwan Mahaveer Jain Medicals',
							command : () => {
								navigate('/about/bmjh-medicals')
							}
						},
					)
				},
				{
					label : "Managing Trustees",
					link: '/about/managing_trustees',
					command : () => {
						navigate('/about/managing_trustees')
					}
				},
			)
		},
		{
			label: 'Photo Gallery',
			command: () => {
				navigate('/gallery')
			},
		},
		{
			label: 'Patient Information',
			// url: '/pharmacy',
			items: concat(
				{
					label: 'Patient Guide',
					items: concat(
						{
							label: `Registration`,
							command: () => {
								navigate(selectedHospital == 1 ?'/pharmacy/registration-patient' : '/pharmacy/registration-patient-girinagar')
							},	
						},
						{
							label: 'Admissions',
							command: () => {
								navigate( selectedHospital == 1 ? '/pharmacy/admissions': '/pharmacy/ admissions-girinagar')
							},	
						},
						{
							label: 'Discharges',
							command: () => {
								navigate(selectedHospital == 1 ? '/pharmacy/discharges': '/pharmacy/discharges-girinagar')
							},	
						},
						
						{
							label: 'Billing',
							command: () => {
								navigate( selectedHospital == 1 ? '/pharmacy/billings' : '/pharmacy/billings-girinagar')
							},	
						},
						{
							label : "Insurance and Cashless Services",
							link: '/about/managing_trustees',
							command : () => {
								navigate( selectedHospital == 1 ? '/about/insurance' : '/about/insurance-girinagar')
							}
						},
						{
							label: `Do's and Don'ts`,
							command: () => {
								navigate( selectedHospital == 1 ? '/pharmacy/dos-dots' : '/pharmacy/dos-dots-girinagar')
							},	
						},

										
						{
							label: `MLC`,
							command: () => {
								navigate(selectedHospital == 1 ? '/pharmacy/medical': '/pharmacy/medical-girinagar')
							},	
						},
					
						{
							label: 'Other Services',
							items: concat(
								{
									label : 'Nursing Services',
									command: () => {
										navigate(selectedHospital == 1 ? '/pharmacy/private-nurses' : "/pharmacy/private-nurses-girinagar")
									},
								},
								// {
								// 	label : 'Ambulance',
								// 	command: () => {
								// 		navigate(selectedHospital == 1 ? '/pharmacy/ambulance' : '/pharmacy/Ambulance-girinagar')
								// 	},
								// },
								{
									label : 'Canteen Services ',
									command: () => {
										navigate( selectedHospital == 1 ? '/pharmacy/cafeteria' : '/pharmacy/Cafeteria-girinagar')
									},
								},
								{
									label : 'Parking',
									command: () => {
										navigate(selectedHospital == 1 ? '/pharmacy/parking' : '/pharmacy/Parking-girinagar')
									},
								}
							)
							// command: () => {
							// 	navigate( selectedHospital == 1 ?  '/pharmacy/utilities' : '/pharmacy/utilities')
							// },
							// link:'/pharmacy/utilities'
						},

						// {
						// 	label: 'Tariff & Deposit',
						// 	command: () => {
						// 		navigate( selectedHospital == 1 ? '/pharmacy/tariff-deposits' : '/pharmacy/tariff-deposits-girinagar')
						// 	},
						// },

						// {
						// 	label: 'Cashless Service',
						// 	command: () => {
						// 		navigate('/pharmacy/cashless')
						// 	},	
						// },
					)
				},
				
			
				
				// {
				// 	label: 'Room & Bed Facility',
				// 	command: () => {
				// 		navigate( selectedHospital == 1 ? '/room-beds' : '/room-beds')
				// 	},
				// 	link:'/room-beds'
				// },
				{
					label : "Patient Rights and Responsibilities",
					link: '/about/patient-rights',
					command : () => {
						navigate( selectedHospital == 1 ?  '/about/patient-rights' : '/about/patient-rights-girinagar')
					}
				},
				{
					label: 'Pharmacy Information',
					command: () => {
						navigate( selectedHospital == 1 ? '/pharmacy/info' : '/pharmacy/pharmacy-information-girinagar')
					},
				},
				{
					label: 'NABH Certifications',
					command: () => {
						navigate(selectedHospital == 1 ? '/pharmacy/nabh' : '/pharmacy/nabh-girinagar')
					},
				},
			)
		},
		{
			label: 'Facilities',
			items: concat(
				{
					label: 'View All',
					command: () => {
						navigate('/facilities')
					},
				},
				getFacilityMenu ? getFacilityMenu : {}
			),
		},
		{
			label: 'Specialities',
			items: concat(
				{
					label: 'View All',
					command: () => {
						navigate('/specialties')
					},
				},
				getSpecMenu ? getSpecMenu : {}
			),
		},
		{
			label: 'Consultants',
			command: () => {
				navigate('/doctors')
			},
		},

		{
			label: 'Contact Us',
			command: () => {
				navigate('/contact-us')
			},
		},
	]

	const main_menu = [
		// {
		// 	label :'Resources',
		// 	link: '/'
		// },
		// {
		// 	label: 'Photo Gallery',
		// 	link: '/gallery',
		// 	command: () => {
		// 		navigate('/gallery')
		// 	},
		// },
		{
			label :'Health Check Packages',
			link : '/medical-packages',
			command : () => {
				navigate('/medical-packages')
			}
		},
		{
			label :'Careers',
			link : '/careers',
			command : () => {
				navigate('/careers')
			}
		},
		{
			label :'Newsletters',
			link : '/newsletters',
			command : () => {
				navigate('/newsletters')
			}
		},
		{
			label : 'Academics',
			link: '/academics',
			command : () => {
				navigate('/academics')
			}
		},
		{
			label : "Ethics Committee",
			link: '/about/ethics_committee',
			command : () => {
				navigate('/about/ethics_committee')
			}
		},
		// {
		// 	label : "Patient Instruction",
		// 	link: '/about/managing_trustees',
		// 	command : () => {
		// 		navigate('/about/patient_instruction_for_healthcheck')
		// 	}
		// },
		{
			label : "Telephone Extensions",
			link: '/about/telephone_extension_nos',
			command : () => {
				navigate('/about/telephone_extension_nos')
			}
		},
		
		// {
		// 	label : "Charitable Activities",
		// 	link: '/'
		// },
		// {
		// 	label : "HR Requirements",
		// 	link: '/careers'
		// },
		// {
		// 	label : "Publicity",
		// 	link: '/'
		// },
		// {
		// 	label : "Patient Feedback Facility",
		// 	link: '/'
		// },
		// {
		// 	label : "Careers and Jobs",
		// 	command: () => {
		// 		navigate('/careers')
		// 	},
		// 	link: '/careers'
		// },
		// {
		// 	label : "Make donation",
		// 	link: '/'
		// },
		{
			label: 'Blogs / Vlogs',
			link :'/blogs/1',
			command: () => {
				navigate('/blogs/1')
			},
		},
		{
			label: 'Feedback',
			link :'/feedbacks',
			command: () => {
				navigate('/feedbacks')
			},
		},
		// {
		// 	label : "Staff Login",
		// 	link: '/'
		// }
		// { text: 'Resources', link: '/' },
		// { text: 'Photo Gallery', link: '/' },
		// { text: 'Academics', link: '/' },
		// { text: 'College Of Nursing', link: '/' },
		// { text: 'Charitable Activities', link: '/' },
		// { text: 'HR Requirements', link: '/' },
		// { text: 'Publicity ', link: '/' },
		// { text: 'Patient Feedback Facility', link: '/' },
		// { text: 'Careers and Jobs', link: '/' },
		// { text: 'Make a Donation', link: '/' },
		// { text: 'Blogs / Vlogs', link: '/blogs' },
		// { text: 'Staff Login', link: '/' },
	]

	const menus = useRef(null);
    const toast = useRef(null);

	return (
		<div className='secondary-header main-width d__flex just-s'>
			{/* <ul className='d__flex header-menus'>
				{menu.map((res, index) => (
					<MenuItems key={index + 'dropdown1'} menuOption={res} />
				))}
			</ul> */}
			<Menubar model={menu} />

			<div className='d__flex gap-20'>
				<button
					className='d__flex gap-5 login-btn'
					onClick={() =>
						!isRegisteredUser
							? dispatch(setLoginModal())
							: navigate('/profile/card')
					}>
					<Icons
					className='log-ico'
						name='header'
						iconType='user'
						width='30'
						height='30'
						viewBox='0 0 30 30'
					/>

					{isRegisteredUser
						? 'Hi, ' + userProfile?.firstname || 'User'
						: ' Login - EMR'}
				</button>

				{/* <button className='menu-btn position-relative'>
					<Icons
						onClick={() => setVisible(!visible)}
						name='header'
						iconType='menu'
						width='25'
						height='20'
						viewBox='0 0 32 27'
					/>

					{true && (
						<Dropdown
							options={main_menu}
							custom_style={'main-menu'}
							onClose={() => setVisible(!visible)}
						/>
					)}
				</button> */}
				<div className='header-menu-custom'>
					<Button label="" icon={<Icons name='header' iconType='menu' width='25' height='20' className='hea-menu' viewBox='0 0 32 27'/>} onClick={(event) => menus.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
					<Menu model={main_menu} popup ref={menus} id="popup_menu" />
				</div>
			</div>

			<button className='side-menu-btn'>
				<Icons
					name='header'
					onClick={() => setSideMenu(!sideMenu)}
					iconType='menu'
					width='25'
					height='20'
					viewBox='0 0 32 27'
				/>
			</button>

			<Sidebar
				visible={sideMenu}
				position='left'
				onHide={() => setSideMenu(false)}>
					
					<ul className='sidebar-menu'>
						{
							main_menu.map((val,index) => 
							<li key={index+"sM"} className='sidebar-menu-item'>
								<Link to={val?.link} onClick={() => setSideMenu(false)}>
									{val?.label}
								</Link>
							</li>
							)
						}
					</ul>
				{/* <ul className=''>
					{menu.map((res, index) => (
						<MenuItems
							key={index + 'dropdown2'}
							menuOption={res}
							sideBar={true}
						/>
					))}
				</ul> */}
				{/* <ul className=''>
					{main_menu.map((res, index) => (
						<MenuItems
							key={index + 'dropdown3'}
							menuOption={res}
							sideBar={true}
						/>
					))}
				</ul> */}
			</Sidebar>

			<Dialog
				visible={enableLoginModal}
				onHide={() => dispatch(setLoginModal())}
				dismissableMask={true} className={'login-popup'}>
				<OtpPopup />
			</Dialog>

			{/* <Dialog
				visible={reduxValues.header.enableGuestLogin}
				style={{
					width: '428px',
					height: '257px',
					boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.15)',
					borderRadius: '0px 40px 40px 40px',
					overflow: 'hidden',
				}}
				onHide={() => dispatch(enableGuestLogin())}>
				<GuestAuth />
			</Dialog> */}
		</div>
	)
}

export default SecondaryHeader
export { SecondaryHeader }
