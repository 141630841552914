import React, { useState, useEffect, useRef } from 'react'
// import { Carousel } from 'primereact/carousel'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getCategoryDetail } from 'api/catalogService'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { enableAppointment as setEnableAppointment } from 'store/header'
import { Dialog } from 'primereact/dialog'
import { Appointments } from 'pages'
import Carousel from '../../../views/components/Carousel/Carousel'
// import carousel from '../../../assets/specialties/carousel.png'
// import specialty from '../../../assets/specialties/specialty.png'
import {
	DoctorBoxScroll,
	MiniInfoBar,
	PrevNext,
	Testimonials,
} from 'components'
import './SpecialtiesDetail.scss'
import Slider from "react-slick";
import { CustomizeHeading, DoctorBox, Icons } from 'components'
// import { Accordion, AccordionTab } from 'primereact/accordion';
import { Helmet } from 'react-helmet'
import { enableLocation } from 'store/header'
import { selectedHospital as setSelectedHospital } from 'store/doctors'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { enableModalVideo } from '../../../store/header'
import { SwiperSlide } from 'swiper/react'
// const templatee = product => {
// 	return (
// 		<div>
// 			<img src={product} alt='' />
// 			{/* <div>{product.image}</div> */}
// 		</div>
// 	)
// }

function SpecialtiesDetail() {
	const dispatch = useDispatch()
	const [detailPage, setDetailPage] = useState('')
	const { enableAppointment } = useSelector(state => state?.header)
	const [activeIndex,setActiveIndex] = useState(null)
	const [showMore,setShowMore] = useState(false)
	let { id } = useParams()
	const { selectedHospital } = useSelector(
		state => state.doctorsInfo
	)
	const navigate = useNavigate()

	// const products = [
	// 	{ image: carousel },
	// 	{ image: carousel },
	// 	{ image: carousel } /*  */,
	// ]

	useEffect(() => {
		dispatch(enableLocation())
		id && selectedHospital && getDetailData()
		return () => {
			dispatch(enableLocation())
		}
	}, [id,selectedHospital])

	const getDetailData = async () => {
		const response = await dispatch(getCategoryDetail({ speciality_id: id,hospital_id: selectedHospital  }))
		dispatch(setSelectedHospital(response?.details?.hospital_id))
		setDetailPage(response)
	}

	const sliderSettings =  {
		centerPadding: "20px",
		slidesToShow: 1,
		swipeToSlide : true,
		speed: 500,
		autoplay: true,
		dots :true,
		focusOnSelect: false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 1,
				infinite: false,
				dots: false,
				arrows : false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				// initialSlide: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : false
			  }
			}
		]
	};

	var settings = {
		className: "",
		dots: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		speed: 3000,
		autoplay: true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 4,
				// infinite: false,
				initialSlide: 0,
				dots: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 0,
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 0,
				arrows : false,
				speed: 3000,
			  }
			}
		]
		// adaptiveHeight: false
	  };

	const slider = useRef();

	const breakpoints = {
		"1720": {
		  slidesPerView: 4,
		},
		"1366": {
		  slidesPerView: 4,
		},
		"1025": {
		  slidesPerView: 4,
		},
		"768": {
		  slidesPerView: 2,
		},
		"0": {
		  slidesPerView: 1,
		},
	  };
	  
	return (
		<div className='specialties-detail  mar-b-135'>
			<Helmet>
				<title>BMJH - Specialities | {`${ detailPage?.details?.meta_title }`}</title>
				<meta property='og:keywords' content={`${ detailPage?.details?.meta_keywords }`} />
				<meta property="og:title" content={`${ detailPage?.details?.meta_title }`} />
				<meta property="og:description" content={`${ detailPage?.details?.meta_description }`}/>

				<meta name="twitter:title" content={`${ detailPage?.details?.meta_title }`} />
				<meta name="twitter:description" content={`${ detailPage?.details?.meta_description }`}/>
			</Helmet>

			<div className='container'>
				<div className={`image-carousel ${detailPage?.details?.banner1 || detailPage?.details?.banner2 || detailPage?.details?.banner3 ?'min-h-400':''}`}>
					{/* <Carousel
						value={detailPage?.details?.banner_images}
						numVisible={1}
						numScroll={1}
						itemTemplate={templatee}
					/> */}
					<Slider {...sliderSettings}>
						{/* {
							detailPage?.details?.banner_images?.map((res,index) => */}
							{detailPage?.details?.banner1 && <div className='slider-img' >
								<img src={detailPage?.details?.banner1} alt='' />
							</div>}
							{detailPage?.details?.banner2 && <div className='slider-img' >
								<img src={detailPage?.details?.banner2} alt='' />
							</div>}
							{detailPage?.details?.banner3 && <div className='slider-img' >
								<img src={detailPage?.details?.banner3} alt='' />
							</div>}
							{/* )
						} */}
					</Slider>
					{/* <img src={carousel} alt='' /> */}
					<div className='carousel-info'>
						<CustomizeHeading title={detailPage?.details?.name}/>
						{ detailPage?.details?.book_appointment == 1 && <><button onClick={() => navigate('/appointment/specialty',{ state : {specialityId : id} })} className='appointment-btn'>Book Appointment</button></>}
					</div>
				</div>

				<div className='speciality-detail'>
					<div className='min-300 '>
						<div className='specialty-image'>
							<img src={detailPage?.details?.image} alt='' />
						</div>
						{detailPage && <div dangerouslySetInnerHTML={{__html: detailPage?.details?.description_tags}} />}
					</div>

					<Accordion className="accordion-custom" activeIndex={0}>
						{
							detailPage?.details?.array_content?.map((v,i) => {
								if(v?.head){
								return (
										<AccordionTab key={i+'AD'} header={<React.Fragment><span>{v?.head}</span></React.Fragment>}>
										{v?.sub ? <div dangerouslySetInnerHTML={{__html: v?.sub}} /> : <>No Content!</>}
										</AccordionTab>
									)
								}
							})	
						}
					</Accordion>

				</div>

				
			</div>

			{/* <MiniInfoBar /> */}

			<div className='container'>
				{ detailPage?.our_consultants?.length != 0 &&
				<div className='doctor-carousel'>
					<div className='doctors'>
					<div className='d__flex just-s gap-15 arrows-prev-next m-d-flex'>
						<h3>Consult Our Experts</h3>
						{/* <div className='d__flex m-d-flex'>
							<button onClick={() => slider.current.slickPrev()}>
								<Icons name='common' iconType='arrow-prev' width='15px' strokeWidth='0' strokeColor='#ffff' viewBox='0 0 30 29'/>{' '}
							</button>
							<button onClick={() => slider.current.slickNext()}>
								<Icons name='common' iconType='arrow-next-white' width='15px' strokeWidth='0' strokeColor='#ffff' viewBox='0 0 30 29' />{' '}
							</button>
						</div> */}
					</div>
					<Carousel loop={false} breakpoints={breakpoints} autoplay={{delay: 10200,}}>
						{detailPage?.our_consultants?.map((res, index) => (
							<SwiperSlide key={index}>
								<DoctorBox doctor={res} />
							</SwiperSlide>
						))}
					</Carousel>
					{/* <Slider {...settings} ref={slider} {...{ infinite : detailPage?.our_consultants?.length > 4 ?true:false }} > */}
						{/* <DoctorBoxScroll scroll={true} activeID={'00'} list={detailPage?.our_consultants}/> */}
						
					{/* </Slider> */}
					</div>
				</div>
				}
				{/* <div className='testimonial'>
					<Testimonials title='Patient’s Stories' />
				</div> */}

				{
					(detailPage?.details?.image1|| detailPage?.details?.image2|| detailPage?.details?.image3|| detailPage?.details?.image4|| detailPage?.details?.image5|| detailPage?.details?.image6 )&&
					<div className='mt-20'>
						<h3 className='txt-center pb-10'>Our Work Environments</h3>

						{/* <Slider {...settings}> */}
						<div className='env-images'>
							{/* {detailPage?.details?.image1 &&  */}
							<div className='env-left'>
								<img src={detailPage?.details?.image1} onError={((e) => (e.target.src = require('../../../assets/default/env-first-last.png')))} alt='' />
							</div>
							{/* } */}
							<div className='env-main'>
								{/* {detailPage?.details?.image2 &&  */}
								<div >
									<img src={detailPage?.details?.image2} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
								</div>
								{/* } */}
								{/* {detailPage?.details?.image3 &&  */}
								<div className=''>
									<img src={detailPage?.details?.image3} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
								</div>
								{/* } */}
								{/* {detailPage?.details?.image4 &&  */}
								<div>
									<img src={detailPage?.details?.image4} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
								</div>
								{/* } */}
								{/* {detailPage?.details?.image5 &&  */}
								<div className=''>
									<img src={detailPage?.details?.image5} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
								</div>
								{/* } */}
							</div>
							{/* {detailPage?.details?.image6 &&  */}
							<div className='env-right'>
								<img src={detailPage?.details?.image6} onError={((e) => (e.target.src = require('../../../assets/default/env-first-last.png') ))} alt='' />
							</div>
							{/* } */}
						</div>
						{/* </Slider> */}
					</div>
				}

				{
					(detailPage?.details?.image7|| detailPage?.details?.image8|| detailPage?.details?.image9|| detailPage?.details?.image10 ) &&	
					<div className='env-images mt-5'>
							<div className='env-main gap-40 p-0'>
								{/* {detailPage?.details?.image2 &&  */}
								<div >
									<img src={detailPage?.details?.image7} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
								</div>
								{/* } */}
								{/* {detailPage?.details?.image3 &&  */}
								<div className=''>
									<img src={detailPage?.details?.image8} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
								</div>
								{/* } */}
								{/* {detailPage?.details?.image4 &&  */}
								{detailPage?.details?.image9 && detailPage?.details?.image9 != "" && <div>
									<img src={detailPage?.details?.image9}  alt='' />
									{/* onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} */}
								</div>}
								{/* } */}
								{/* {detailPage?.details?.image5 &&  */}
								{detailPage?.details?.image10 && detailPage?.details?.image10 != "" &&  <div className=''>
									<img src={detailPage?.details?.image10}  alt='' />
									{/* onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} */}
								</div>}
								{/* } */}
							</div>
					</div>
				}


				{
					(detailPage?.details?.env_video1 || detailPage?.details?.env_video2 || detailPage?.details?.env_video3 || detailPage?.details?.env_video4 || detailPage?.details?.env_video5 || detailPage?.details?.env_video6 ) &&
					<div className='mt-20'>
						<h3 className='txt-center pb-10'>Our Work Environments videos</h3>

						{/* <Slider {...settings}> */}
						<div className=''>
							{/* {detailPage?.details?.image1 &&  */}
							{/* <div className='env-left'>
								<img  src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video1+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-first-last.png')))} alt='' />
								<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
							</div> */}
							{/* } */}
							<div className='env-videos'>
								{/* {detailPage?.details?.image2 &&  */}
								{detailPage?.details?.env_video1 &&  <div className='video-card'>
									<img src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video1+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
									<div className={'play-icon'}  onClick={() => dispatch(enableModalVideo(detailPage?.details?.env_video1))}>
										<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
									</div>							
								</div>}
								{/* } */}
								{/* {detailPage?.details?.image3 &&  */}
								{detailPage?.details?.env_video2 &&  <div className='video-card'>
									<img src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video2+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
									<div className={'play-icon'} onClick={() => dispatch(enableModalVideo(detailPage?.details?.env_video2))}>
										<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
									</div>
								</div>}
								{/* } */}
								{/* {detailPage?.details?.image4 &&  */}
								{detailPage?.details?.env_video3 &&  <div className='video-card'>
									<img src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video3+'/maxresdefault.jpg'}  onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
									<div className={'play-icon'} onClick={() => dispatch(enableModalVideo(detailPage?.details?.env_video3))}>
										<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
									</div>
								</div>}
								{/* } */}
								{/* {detailPage?.details?.image5 &&  */}
								{detailPage?.details?.env_video4 &&  <div className='video-card'>
									<img src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video4+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
									<div className={'play-icon'} onClick={() => dispatch(enableModalVideo(detailPage?.details?.env_video4))}>
										<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
									</div>
								</div>}

								{detailPage?.details?.env_video5 && <div className='video-card'>
									<img src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video5+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
									<div className={'play-icon'}  onClick={() => dispatch(enableModalVideo(detailPage?.details?.env_video5))}>
										<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
									</div>
								</div>}

								{detailPage?.details?.env_video6 && <div className='video-card'>
									<img src={'https://i.ytimg.com/vi/'+detailPage?.details?.env_video6+'/maxresdefault.jpg'} onError={((e) => (e.target.src = require('../../../assets/default/env-center.png') ))} alt='' />
									<div className={'play-icon'} onClick={() => dispatch(enableModalVideo(detailPage?.details?.env_video6))}>
										<Icons name='common' iconType='play-button' strokeWidth='0' viewBox='0 0 30 29'/>
									</div>
								</div>}
								{/* } */}
							</div>
							{/* {detailPage?.details?.image6 &&  */}
							{/* <div className='env-right'>
								<img src={detailPage?.details?.env_video6} onError={((e) => (e.target.src = require('../../../assets/default/env-first-last.png') ))} alt='' />
							</div> */}
							{/* } */}
						</div>
						{/* </Slider> */}
					</div>
				}


				<div className='mt-20'>
					<PrevNext page='specialty' />
				</div>
			</div>

			<Dialog visible={!isEmpty(enableAppointment)}
				style={{
					width: '80%',
					height: '1000px',
					boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.15)',
					borderRadius: '0px 40px 40px 40px',
					overflow: 'hidden',
				}}
				onHide={() => dispatch(setEnableAppointment([]))} dismissableMask={true}>
				<Appointments isFromDoctor={true} isFromDoctorDetail={true} />
			</Dialog>

		</div>
	)
}

export default SpecialtiesDetail
